import React, {useEffect} from "react"
import Paper from "@material-ui/core/Paper"
import human from "../../img/human.svg";

export const BlackHumans = ({data}) => {
    useEffect(() => {
        const stepSize = 50
        setTimeout((function () {
            let fillers = document.getElementsByClassName("multiple-human-filler"),
                percentage = 0
            return function progress() {
                for (let filler of fillers) {
                    filler.style.height = percentage + "%"
                }
                percentage += 1
                if (percentage <= data.percent) {
                    setTimeout(progress, stepSize)
                }
            }
        }()), stepSize)
    })

    const countHumans = () => {
        const counter = Math.floor(100 / data.percent)

        const emptyFigure =
            <div className="multiple-human-container">
                <img src={human} alt="human"/>
            </div>

        const half = <div className="half-human">
            <div className="big-sign">
                <p>+</p>
            </div>
            <div className="multiple-human-container">
                <div className="half-human-filler"/>
                <img src={human} alt="human"/>
            </div>
            <div>
                <p>сотрудник<br/>на 0,5 ставки</p>
            </div>
        </div>

        const output = Array(counter).fill(emptyFigure)

        if (3 < (100 % data.percent) < 7) {
            output.push(half)
        }

        return output
    }

    return (
        <div className="report-diagram">
            <Paper className="report-diagram-paper">
                <div className="report-diagram-container">
                    <div className="report-diagram-multiple-human-container">
                        <div className="multiple-human-current">
                            <p>Текущая производительность<br/>труда в смену</p>
                        </div>
                        <div className="multiple-human-opt">
                            <p>Оптимальная производительность<br/>труда в смену</p>
                        </div>
                        <div className="multiple-human-broken">
                            <div className="multiple-human-container">
                                <div className="multiple-human-filler"/>
                                <img src={human} alt="human"/>
                            </div>
                            <div className="multiple-human-container">
                                <div className="multiple-human-filler"/>
                                <img src={human} alt="human"/>
                            </div>
                            <div className="multiple-human-container">
                                <div className="multiple-human-filler"/>
                                <img src={human} alt="human"/>
                            </div>
                            <div className="multiple-human-container">
                                <div className="multiple-human-filler"/>
                                <img src={human} alt="human"/>
                            </div>
                            <div className="multiple-human-container">
                                <div className="multiple-human-filler"/>
                                <img src={human} alt="human"/>
                            </div>
                        </div>
                        <div className="multiple-human-plus big-sign">
                            <p>=</p>
                        </div>
                        {/*<div className="multiple-human-works">*/}
                        {/*    <>*/}
                        {/*        {countHumans()}*/}
                        {/*    </>*/}
                        {/*</div>*/}
                        {/*<div className="report-diagram-human">*/}
                        {/*    <div id="filler" className="report-diagram-human-filler"/>*/}
                        {/*    <img src={human} alt="human"/>*/}
                        {/*    <p>Полезное<br/>время<br/>{data.percent}%</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Paper>
        </div>
    )
}