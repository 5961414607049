import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"
import React from "react"
import problems from "../img/problems.png"

const getTextRationale = (measurements) => {
  if (measurements === 1) {
    return (
      <div>
        <b>
          Проблемы первого круга (первичный ресурс для повышения производительности труда), которые могут быть решены
          после проведения 1 замера:
        </b>
        <ul>
          <li>Очевидные простои и их причины</li>
          <li>Часть нарушений в технологическом процессе</li>
          <li>Часть нарушений со стороны сотрудников по использованию рабочего времени</li>
        </ul>
      </div>
    )
  }
  if (measurements === 2) {
    return (
      <div>
        <b>
          Проблемы второго круга, которые могут быть решены после проведения 2 замера:
        </b>
        <ul>
          <li>Периодические повторяющиеся проблемы с производительностью труда</li>
          <li>Первичные закономерности в появлении простоев</li>
          <li>Часть нарушений в технологическом процессе</li>
          <li>Возможно создание первичных нормативов</li>
          <li>Часть нарушений со стороны сотрудников по использованию рабочего времени</li>
        </ul>
      </div>
    )
  }
  if (3 <= measurements && measurements <= 5) {
    return (
      <div>
        <b>
          Проблемы третьего круга, которые могут быть решены после проведения 3-5 замеров:
        </b>
        <ul>
          <li>Большая часть нарушений в технологическом процессе</li>
          <li>Большая часть нарушений со стороны сотрудников по использованию рабочего времени</li>
          <li>Выявление пиковых и не пиковых периодов в загруженности</li>
          <li>Возможно создание нормативов на часто встречающиеся операции или блоки операций</li>
          <li>Выявление всех часто встречающихся причин простоев</li>
        </ul>
      </div>
    )
  }
  if (6 <= measurements && measurements <= 8) {
    return (
      <div>
        <b>
          Проблемы четвертого круга, которые могут быть решены после проведения 6-8 замеров:
        </b>
        <ul>
          <li>Выявлены все нарушения в технологическом процессе</li>
          <li>Выявлены все нарушения со стороны сотрудников по использованию рабочего времени</li>
          <li>Выявление закономерностей в пиковых и не пиковых периодах в загруженности</li>
        </ul>
      </div>
    )
  }
  return (
    <div>
      <b>
        Проблемы пятого круга, которые могут быть решены после проведения 9-10 замеров:
      </b>
      <ul>
        <li>Выявлены все причины простоев</li>
        <li>Выявлены стратегии поведения персонала в ситуациях, выходящих за рамки нормального</li>
        <li>Возможно создание нормативов на все операции и подоперации</li>
        <li>Выявлены все возможные ресурсы для повышения производительности труда</li>
      </ul>
    </div>
  )
}

export const CalculatorRationale = ({ measurements }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-rationale calculator-form-group">
        <img src={problems} alt="Problems"/>
        {getTextRationale(measurements)}
      </div>
    </CalculatorPaperWrapper>
  )
}