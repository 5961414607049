export const resolveNaN = (oldValue?: number, newValue?: number) => {
    if (newValue === undefined) {
        return oldValue
    }

    if (isNaN(newValue)) {
        return undefined
    }

    return newValue
}