import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import './ciblock-menu.css'

export default class CiblockMenu extends Component {

    constructor(props) {
        super(props)
        this.initProject = props.initProject

        this.state = {
            projects: [],
            currProject: null,
            newProject: '',
        }
    }

    componentDidMount() {
        this.getProjects()
    }

    getProjects = () => {
        axios.get(`/api/v1/projects/list`).then(res => {
            this.setState({
                projects: res.data
            });
        })

        axios.get(`/api/v1/projects/last/meta`).then(res => {
            this.setState({
                currProject: res.data
            });
        })
    }

    addProject = () => {
        axios.post(`/api/v1/projects/create?name=${this.state.newProject}`)
            .then(() => {
                this.getProjects()
                this.initProject()
            }).then(() => this.setState({
                newProject: ''
            })
        )
    }

    deleteProject = () => {
        if (this.state.currProject) {
            if (this.state.projects.length > 1) {
                let delete_id = this.state.currProject.id
                let new_id = this.state.projects.find((project, i) => project.id !== delete_id).id
                console.log(delete_id, new_id)
                axios.post(`/api/v1/projects/delete?delete_id=${delete_id}&new_id=${new_id}`)
                    .then(() => {
                        this.getProjects()
                        this.initProject()
                    })
            }
        }
    }

    onProjectSelected = (project) => {
        document.getElementById('output_url').hidden = true
        this.setState({currProject: project})
        axios.post(`/api/v1/projects/last/set?project_id=${project.id}`)
            .then(() => {
                this.initProject()
            })
    }

    getProjectNames = () => {
        const drops = [];

        this.state.projects
            .forEach((project, i) => {
                drops.push(
                    <Dropdown.Item key={i} onClick={() => {
                        this.onProjectSelected(project)
                    }}>{project.name}</Dropdown.Item>
                )
            });
        drops.push()
        return (drops)
    };


    waitDwg = (work_item_id) => {
        axios.get(`/api/v1/dwg/status?work_item_id=${work_item_id}`).then(res => {
            if (res.data !== "success") {
                setTimeout(this.waitDwg, 1000, work_item_id)
            } else {
                axios.get(`/api/v1/dwg/output_url?work_item_id=${work_item_id}`).then(res => {
                    document.getElementById('output_url').hidden = false
                    document.getElementById('spinner').hidden = true
                    document.getElementById('output_url').href = res.data
                })
            }
        })
    }

    buildDwg = () => {
        document.getElementById('output_url').hidden = true
        document.getElementById('spinner').hidden = false

        axios.post(`/api/v1/dwg/build`)
            .then(res => {
                this.state.loading = true
                this.waitDwg(res.data)
            })
    }

    saveAsGolden = () => {
        axios.post(`/api/v1/projects/last/golden`)
    }

    render() {
        let table_style = {fontSize: "14px", marginTop: '20px', marginLeft: '20px', marginBottom: 0, textAlign: 'left'}
        let row_style = {marginTop: '50px'}
        let dropdown_style = {
            width: "160px",
            fontSize: "14px",
            color: 'black',
            backgroundColor: '#B3B3B3',
            lightingColor: 'blue',
            borderColor: 'black',
            borderWidth: 1
        }
        let button_style = {
            fontSize: "14px",
            color: 'black',
            backgroundColor: '#B3B3B3',
            lightingColor: 'blue',
            borderColor: 'black',
            borderWidth: 1
        }
        let dwg_button_style = {
            width: "200px",
            fontSize: "14px",
            color: 'black',
            backgroundColor: '#B3B3B3',
            lightingColor: 'blue',
            borderColor: 'black',
            borderWidth: 1
        }

        return (
            <div style={table_style}>
                <div style={row_style}>
                    <div>
                        Выберете проект:
                    </div>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={dropdown_style}>
                            {(this.state.currProject?.name || '-')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={ButtonGroup}>
                            {this.getProjectNames()}
                        </Dropdown.Menu>
                    </Dropdown>{'   '}
                    <Button variant="secondary" style={button_style} onClick={this.deleteProject}> - </Button>
                </div>
                <div style={row_style}>
                    <div>
                        Создайте новый проект:
                    </div>
                    <TextField
                        style={{width: 160}}
                        value={this.state.newProject}
                        onChange={e => this.setState({
                            newProject: e.target.value
                        })}
                        type="text"
                        helperText="Название проекта"
                        multiline={true}
                    />{'   '}
                    <Button variant="secondary" style={button_style} onClick={this.addProject}> + </Button>
                </div>
                <div style={row_style}>
                    <Button variant="secondary" style={dwg_button_style} onClick={this.buildDwg}> Подготовить
                        dwg </Button>
                    <div style={{marginTop: '10px'}}>
                        <Spinner id={'spinner'}
                                 animation="border"
                                 hidden={true}
                                 variant="secondary"
                                 style={{marginLeft: '80px'}}
                        />
                        <a id="output_url" download={'output.dwg'} hidden={true}
                           style={{marginLeft: '55px', marginTop: '30px'}}>output.dwg</a>
                    </div>
                </div>
                <div style={row_style}>
                    <Button variant="secondary" style={dwg_button_style} onClick={this.saveAsGolden}>
                        Сохранить как эталон </Button>
                </div>
            </div>
        );
    }
}