import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import './video-markup.css';
import AddOperation from "../add-operation";
import Operations from "../operations";
import DatePicker from "react-datepicker/es";

export default class VideoMarkup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            operations: [],
            addOperation: false,
            time: 0,
            video: null,
            canvas: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    id = 0;

    onOperationAdd = (frames, label) => {
        this.setState(() => {
            const operation = this.createOperation(frames, label);
            return {operations: [...this.state.operations, operation]}
        });
        this.setState({addOperation: false});
        document.querySelector(".to-hide").style.display = "block";
        document.querySelector("#video-canvas").style.display = "none";
    };

    createOperation(frames, label, date) {
        return {
            id: ++this.id,
            label: label,
            date: date,
            frames: frames
        }
    }

    onClickAdd = () => {
        this.setState({addOperation: true});
        const canvas = document.querySelector("#video-canvas");
        const ctx = canvas.getContext("2d");
        const video = document.querySelector("#video-player");

        document.querySelector(".to-hide").style.display = "none";
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.style.display = "block";

        setTimeout(() => {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        }, 100);
        canvas.style.margin = "auto";
        this.setState({video: video});
        this.setState({canvas: canvas});
        this.setState({time: video.currentTime})
    };

    onClickAnalysis = () => {
        alert("Not implemented!!!");
    };

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    getFormatedTime = (inputTime) => {
        {
            const time = Math.floor(inputTime);
            const hours = Math.floor(time / 3600);
            const minutes = Math.floor((time % 3600) / 60);
            const seconds = (time % 3600) % 60;
            return `${hours}:${minutes}:${seconds}`
        }
    };

    render() {
        const url = URL.createObjectURL(this.props.file);

        return (
            <div className="video-markup">
                <div className="title">
                    <h6>{this.props.file.name}</h6>
                </div>
                <div className="video-markup-canvas">
                    <canvas id="video-canvas" style={{display: 'none'}}></canvas>
                </div>
                <div className="to-hide">
                    <div className="video-player-container">
                        <video width="30%" controls id="video-player">
                            <source src={url} type="video/mp4"/>
                        </video>
                    </div>
                    <div className="date-container">
                        <div className="date-header">
                            <h6>Дата видео</h6>
                        </div>
                        <div className="date-picker">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                            /></div>
                    </div>
                    <div className="add">
                        <Operations items={this.state.operations}/>
                        <div className="buttons">
                            <Button className="add-button" variant="danger" onClick={this.onClickAdd}>Добавить
                                операцию</Button>
                            <Button className="analysis-button" variant="danger"
                                    onClick={this.onClickAnalysis}>Анализ</Button>
                        </div>
                    </div>
                </div>
                {this.state.addOperation &&
                <AddOperation onOperationAdd={this.onOperationAdd} video={this.state.video} canvas={this.state.canvas}/>}
            </div>);
    }
};
