import React, {Component} from "react"
import {Switch, Route} from "react-router-dom"
import "./app.css"
import Favicon from "react-favicon"
import AppNavbar from "../app-navbar"
import NewProject from "../new-project"
import Markup from "../markup"
import Landing from "../landing"
import Video from "../video"
import Statement from "../statement"
import axios from "axios"
import Login from "../login"
import {connect} from "react-redux"
import MultiVideo from "../multi-video"
import sal from "sal.js"
import Summary from "../summary"
import Edit from "../edit"
import Interactive from "../interactive"
import {createMuiTheme} from "@material-ui/core/styles"
import {ThemeProvider} from "@material-ui/styles"
import Report from "../report"
import Calculator from "../calculator"
import Models from "../models"
import Map from "../ciblock-map"
import ModelsStandLocations from "../models-stand-locations"
import ModelsStands from "../models-stands"
import Model from "../model"
import {Projects} from "../projects/projects"
import {LandingNews} from "../landing-news/landing-news";
import {LandingServices} from "../landing-services/landing-services";
import {Registration} from "../registration/registration";
import {Hr} from "../hr/hr";
import {Products} from "../products/products";
import {Ai} from "../ai/ai";
import {Evan} from "../evan/evan";

axios.defaults.baseURL = ""
axios.defaults.withCredentials = true
axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    return Promise.resolve()
    if (error.response.status === 403) {
        console.error('Forbidden');
        console.error(error);
        // window.location.replace(`/login?source=${window.location.pathname}`)
        return Promise.reject(error)
    }
    alert(`Ошибка! Сервер говорит: ${JSON.stringify(error.response.data)}`)
    return Promise.reject(error)
})

const THEME = createMuiTheme({
    typography: {
        "fontFamily": "\"Montserrat\", sans-serif",
    },
})

class App extends Component {

    componentDidMount() {
        sal()
    }

    render() {
        return (
            <div className="labracv">
                <ThemeProvider theme={THEME}>
                    <Favicon url={require("../img/favicon.ico")}/>
                    <AppNavbar/>
                    <Switch>
                        <Route path="/" exact component={Landing}/>
                        <Route path="/news" exact component={LandingNews}/>
                        <Route path="/services" exact component={LandingServices}/>
                        <Route path="/project" exact component={NewProject}/>
                        <Route path="/login" exact component={Login}/>
                        <Route path="/markup/:structureId" exact component={Markup}/>
                        <Route path="/statement/:structureId/:employeeId/:fromTime/:toTime" component={Statement}/>
                        <Route path="/video/:videoId/:getTime?" component={Video}/>
                        <Route path="/multi-video" component={MultiVideo}/>
                        <Route path="/summary" component={Summary}/>
                        <Route path="/edit/:structureId/:timeFrom/:timeTo" component={Edit}/>
                        <Route path="/demo/:key" component={Interactive}/>
                        <Route path="/interactive/:structureId/:from/:to" component={Interactive}/>
                        <Route path="/report/:structureId/:fromTime/:toTime" component={Report}/>
                        <Route path="/calculator" component={Calculator}/>
                        <Route path="/models/:videoId" component={Models}/>
                        <Route path="/models-stand-locations/:videoId" component={ModelsStandLocations}/>
                        <Route path="/models-stands/:structureId" component={ModelsStands}/>
                        <Route path="/model/:modelId" component={Model}/>
                        <Route path="/projects/:structureId?" component={Projects}/>
                        <Route path="/ciblock/map" component={Map}/>
                        <Route path="/registration" component={Registration}/>
                        <Route path="/products" component={Products}/>
                        <Route path="/hr" component={Hr}/>
                        <Route path="/ai" component={Ai}/>
                        <Route path="/evan" component={Evan}/>
                    </Switch>
                </ThemeProvider>
            </div>
        )
    };
}

const mapStateToProps = store => { //todo obsolete => remove
    return {
        user: store.user,
        page: store.page,
    }
}

export default connect(mapStateToProps)(App)
