import React, {Component} from 'react';
import './time-lapse-input.css';
import VideoMarkup from "../video-markup";

export default class TimeLapseInput extends Component {
    state = {
        display: "input",
        file: null,
    };

    changeDisplay = (value) => {
        this.setState({
            display: value
        });
    };

    onChange = (event) => {
        let videoFile = event.target.files[0];

        if (videoFile.name.split('.').pop() !== "mp4") {
            alert('Error : Only MP4 format allowed');
            return;
        }

        if (videoFile) {
            this.changeDisplay("video");

            this.props.onFileLoading('timelapse');
            document.getElementById("file-to-upload").style.display = 'none';
        }

        this.setState({
            file: videoFile
        });
    };

    render() {
        return (
            <div className="time-lapse-input">
                <input type="file" id="file-to-upload" accept="video/mp4" onChange={this.onChange}/>
                {this.state.display === "video" &&
                <VideoMarkup file={this.state.file} changeDisplay={this.changeDisplay}/>}
            </div>
        );
    };
}