import React, {Component} from 'react';
import './file-input.css';
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";

export default class FileInput extends Component {

    state = {
        percentage: 0
    };

    onClick = () => {
        this.props.onFileLoading('file');
        document.querySelector(".analysis-button").style.display = "none";
        document.querySelector(".dropzone-input").style.display = "block";
        this.onDrop()
    };

    onDrop = () => {
        setTimeout(() => {
            this.setState({percentage: 37});
        }, 1000);
        setTimeout(() => {
            this.setState({percentage: 58});
        }, 3000);
        setTimeout(() => {
            this.setState({percentage: 96});
        }, 5000);
        setTimeout(() => {
            this.setState({percentage: 100});
        }, 7000);
    };

    render() {
        return (
            <div>
                <Button className="analysis-button" onClick={this.onClick}>Анализ</Button>
                <div className="dropzone-input" style={{display: "none"}}>
                    <ProgressBar id="progress-bar" now={this.state.percentage}/>
                    <h6>Идет анализ...</h6>
                    {/*<Dropzone onDrop={this.onDrop}>*/}
                    {/*    {({getRootProps, getInputProps}) => (*/}
                    {/*        <section>*/}
                    {/*            <div {...getRootProps()}>*/}
                    {/*                <input {...getInputProps()} />*/}
                    {/*                <p>Перенесите файлы сюда или нажмите</p>*/}
                    {/*            </div>*/}
                    {/*        </section>*/}
                    {/*    )}*/}
                    {/*</Dropzone>*/}
                </div>
            </div>
        );
    };
}