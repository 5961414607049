import {
  ADD_OPERATION,
  EDIT_OPERATION,
  LOAD_OPERATIONS,
  Operation,
  OperationsActionTypes, OperationTypesCollection,
  REMOVE_OPERATION,
} from "./types"

export function LoadOperationsAction(operations: Operation[], types: OperationTypesCollection): OperationsActionTypes {
  return {
    type: LOAD_OPERATIONS,
    operations: operations,
    operationTypes: types
  }
}

export function AddOperationAction(): OperationsActionTypes {
  return {
    type: ADD_OPERATION
  }
}

export function RemoveOperationAction(index: number): OperationsActionTypes {
  return {
    type: REMOVE_OPERATION,
    index: index
  }
}

export function EditOperationAction(index: number, newType?: string, newName?: string, newReferencePoint?: string): OperationsActionTypes {
  return {
    type: EDIT_OPERATION,
    index: index,
    newType: newType,
    newName: newName,
    newReferencePoint: newReferencePoint
  }
}