import React, { Component } from "react"
import axios from "axios"
import EditOperation from "../edit-operation"
import { Button } from "react-bootstrap"
import "./edit.css"
import { parseISOString } from "../utils/utils"
import { PagesWrapper } from "../utils/pages-wrapper"

export default class Edit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      structureId: this.props.match.params.structureId,
      from: this.props.match.params.timeFrom,
      to: this.props.match.params.timeTo,
      availableOperations: [],
      operationTypes: {},
      workplaces: {},
      employees: {},
      operations: [],
      preventClose: false,
      deletedOperationMarkupIds: [],
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      if (this.state.preventClose) {
        ev.preventDefault()
      }
    })
  }

  componentDidMount() {
    const operations = axios.get(`/api/v1/available_operations?structure_id=${this.state.structureId}`).then(res => {
      const operationTypes = {}
      res.data.operations.forEach((value) => {
        operationTypes[value.id] = value.name
      })
      this.setState({ operationTypes })
    })
    const workplaces = axios.get(`/api/v1/workplaces?structure_id=${this.state.structureId}`).then(res => {
      const workplaces = {}
      res.data.forEach((value) => {
        workplaces[value.id] = value.name
      })
      this.setState({ workplaces: workplaces })
    })
    const employees = axios.get(`/api/v1/employees?structure_id=${this.state.structureId}`).then(res => {
      const employees = {}
      res.data.forEach((value) => {
        employees[value.id] = value.name
      })
      this.setState({ employees: employees })
    })
    Promise.all([operations, workplaces, employees]).then(_ =>
      axios.get(`/api/v1/operation_markups?structure_id=${this.state.structureId}&from_time=${this.state.from}&to_time=${this.state.to}`).then(res => {
        const markups = res.data.operation_markups
        this.setState({
          operations: markups,
        })
      }),
    )
  }

  onPlusButtonClick = () => {
    const operation = {
      operation_id: null,
      start_time: (new Date()).toISOString(),
      end_time: (new Date()).toISOString(),
      comment: null,
      employee_id: null,
      workplace_id: null,
    }
    if (this.state.workplaces.length === 1) {
      operation.workplace_id = this.state.workplaces[0].id
    }
    if (this.state.employees.length === 1) {
      operation.employee_id = this.state.employees[0].id
    }
    const operations = [...this.state.operations, operation]
    this.setState({ operations: operations, preventClose: true })
  }

  onSaveButtonClick = () => {
    return this.saveImpl()
      .then(() => {
        alert("Успешно сохранено!")
        this.setState({ preventClose: false })
      })
      .catch((e) => alert(`Данные введены некорректно`))
  }

  saveImpl = () => {
    return axios.post(`/api/v1/operation_markups?structure_id=${this.state.structureId}&from_time=${this.state.from}&to_time=${this.state.to}`, {
      operation_markups: this.state.operations,
      operation_markups_to_delete: this.state.deletedOperationMarkupIds,
    })
      .then(() => this.setState({ showAlert: false }))
  }

  updateOperation = (id, index, typeId, workplaceId, employeeId, time_from, time_to, comment) => {
    const updatedOperation = {
      id,
      operation_id: parseInt(typeId),
      workplace_id: parseInt(workplaceId),
      employee_id: parseInt(employeeId),
      start_time: new Date(time_from).toISOString(),
      end_time: new Date(time_to).toISOString(),
      comment,
    }
    const operations = this.state.operations
    operations[index] = updatedOperation
    this.setState({ operations: operations }, () => {
      console.log(this.state.operations)
    })
  }

  onOperationDelete = (id, index) => {
    const operations = this.state.operations
    operations.splice(index, 1)
    for (let i = 0; i < operations.length; i++) {
      operations[i].index = i
    }
    const deletedOperationMarkupIds = this.state.deletedOperationMarkupIds
    if (id) {
      deletedOperationMarkupIds.push(id)
    }
    this.setState({ deletedOperationMarkupIds }, () => {
      this.setOperations(operations)
    })
  }

  setOperations = operations => {
    this.setState({ operations: [] }, () => this.setState({ operations: operations }, () => this.forceUpdate()))
  }

  render() {
    return (
      <PagesWrapper>
        <div className="edit">
          <div className="edit-header">
            <h1>Редактирование операций
              с {parseISOString(this.state.from)} по {parseISOString(this.state.to)}</h1>
          </div>
          <div className="edit-buttons">
            <div>
              <Button className="video-button" variant="primary" onClick={this.onPlusButtonClick}>+</Button>
            </div>
            <div>
              <Button className="video-button" variant="primary"
                      onClick={this.onSaveButtonClick}>Сохранить</Button>
            </div>
          </div>
          <div className="edit-markups">
            {this.state.operations.map((operation, i) => {
              return (
                <EditOperation id={operation.id}
                               key={i}
                               index={i}
                               types={this.state.operationTypes}
                               workplaces={this.state.workplaces}
                               selectedWorkplaceId={operation.workplace_id}
                               employees={this.state.employees}
                               selectedEmployeeId={operation.employee_id}
                               currentOperationKey={operation.operation_id}
                               updateOperation={this.updateOperation}
                               timeFrom={Date.parse(operation.start_time)}
                               timeTo={Date.parse(operation.end_time)}
                               remove={this.onOperationDelete}
                               comment={operation.comment === null ? "" : operation.comment}
                />
              )
            }).reverse()}
          </div>
        </div>
      </PagesWrapper>
    )
  }
}