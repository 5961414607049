import React, { Component } from "react"
import QRCode from "qrcode.react"
import axios from "axios"
import Table from "react-bootstrap/Table"
import "./interactive.css"
import { formatDateString, formatTime } from "../utils/utils"
import { Button } from "react-bootstrap"
import { PagesWrapper } from "../utils/pages-wrapper"

export default class Interactive extends Component {

  constructor(props) {
    super(props)

    this.state = {
      key: this.props.match.params.key,
      frv: null,
      structureId: this.props.match.params.structureId,
      fromTime: this.props.match.params.from,
      toTime: this.props.match.params.to,
      videoId: null,
      videoTime: null,
      qrLink: null,
      videoHeight: 0,
    }
  }


  componentDidMount() {
    if (!this.state.key) {
      this.updateFrv()
      return
    }
    axios.get(`/api/v1/resolve_hash/${this.state.key}`)
      .then(res => {
        this.setState({
          structureId: res.data.structure_id,
          fromTime: res.data.from_time,
          toTime: res.data.to_time,
        })
      })
      .then(() => this.updateFrv())
  }

  updateFrv = () => {
    return axios.get(`/api/v2/frv?structure_id=${this.state.structureId}&from_time=${this.state.fromTime}&to_time=${this.state.toTime}`).then(res => {
      this.setState({ frv: res.data })
      for (const op of res.data.items) {
        if (op.video_segments) {
          this.changeVideo(op.video_segments[0])
          return
        }
      }
    })
  }

  setSpeed = (speed) => {
    document.getElementById("video-player-interactive").playbackRate = speed
  }

  changeVideo = (video_timestamp) => {
    const t = video_timestamp.video_segment.start
    this.setState({
      videoId: video_timestamp.video_id,
      videoTime: t,
    }, () => {
      document.getElementById("video-player-interactive").currentTime = t
    })
  }

  onShareButton = () => {
    axios.post(`/api/v1/generate_hash`, {
      structure_id: this.state.structureId,
      from_time: this.state.fromTime,
      to_time: this.state.toTime,
    }).then(res => {
      this.copyLink(res.data.hash)
      this.setState({ qrLink: `https://labrahub.com/demo/${res.data.hash}` })
    })
  }

  copyLink = (hash) => {
    navigator.clipboard.writeText(`https://labrahub.com/demo/${hash}`)
      .then(() => {
        alert("Ссылка скопирована!")
      })
      .catch(err => {
        console.log("Не получилось скопировать ссылку", err)
      })
  }

  render() {
    return (
      <PagesWrapper>
        <div className="interactive-report">
          {
            this.state.frv ?
              <div className="interactive-report-content">
                <div className="interactive-report-video-container">
                  {this.state.videoId ? <video
                    id="video-player-interactive"
                    controls
                    key={this.state.videoId}
                    onCanPlay={() => {
                      const videoHeight = document.getElementsByClassName("interactive-report-video-container")[0].clientHeight
                      console.log(videoHeight)
                      this.setState({ videoHeight })
                    }}
                  >
                    <source
                      src={`${axios.defaults.baseURL}/api/v1/video?video_id=${this.state.videoId}`}
                      type="video/mp4"
                    />
                  </video> : null}
                  <div className="interactive-report-video-buttons">
                    {/*<ButtonGroup aria-label="Playback speed" id="interactive-buttons">*/}
                    {/*    <Button variant="secondary" onClick={() => {*/}
                    {/*        this.setSpeed(1)*/}
                    {/*    }}>x1</Button>*/}
                    {/*    <Button variant="secondary" onClick={() => {*/}
                    {/*        this.setSpeed(2)*/}
                    {/*    }}>x2</Button>*/}
                    {/*    <Button variant="secondary" onClick={() => {*/}
                    {/*        this.setSpeed(4)*/}
                    {/*    }}>x4</Button>*/}
                    {/*    <Button variant="secondary" onClick={() => {*/}
                    {/*        this.setSpeed(8)*/}
                    {/*    }}>x8</Button>*/}
                    {/*    <Button variant="secondary" onClick={() => {*/}
                    {/*        this.setSpeed(16)*/}
                    {/*    }}>x16</Button>*/}
                    {/*</ButtonGroup>*/}
                  </div>
                </div>
                <div className="interactive-report-frv"
                     style={{ maxHeight: `calc(100vh - ${this.state.videoHeight + 76}px)` }}>
                  <h2>Фотография рабочего дня для места
                    "{this.state.frv?.structure.name || "?"}"
                    с {this.state.frv ? formatDateString(this.state.frv.requested_slot.fromm) : "?"} по {this.state.frv ? formatDateString(this.state.frv.requested_slot.to) : "?"}</h2>
                  {!this.state.key ?
                    <div><Button className="video-button" variant="secondary"
                                 onClick={this.onShareButton}><span
                      role="img" aria-label="">📤</span></Button></div>
                    : null
                  }
                  {
                    this.state.qrLink ? <div><QRCode value={this.state.qrLink}/></div> : null
                  }
                  <hr/>
                  <Table hover bordered striped size="sm" id="interactive-report-frv-table">
                    <thead>
                    <tr>
                      <th>№</th>
                      <th>Операция</th>
                      <th>Тип</th>
                      <th>Начало</th>
                      <th>Окончание</th>
                      <th>Длительность</th>
                      <th>Начало на видео</th>
                      <th>Конец на видео</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(this.state.frv?.items || []).map((operation, index) => {
                      const firstVideo = operation.video_segments[0]
                      return <tr key={index}>
                        <td id={`report-table-cell${index}`}>{index}</td>
                        <td>{firstVideo ?
                          <button
                            onClick={() => this.changeVideo(firstVideo, index)}>
                            {operation.operation_name}
                          </button> : operation.operation_name
                        }</td>
                        <td>{operation.operation_type}</td>
                        <td>{formatDateString(operation.time_slot.fromm)}</td>
                        <td>{formatDateString(operation.time_slot.to)}</td>
                        <td>{formatTime(operation.duration)}</td>
                        <td>{firstVideo ? formatTime(firstVideo.video_segment.start) : "-"}</td>
                        <td>{firstVideo ? formatTime(firstVideo.video_segment.end) : "-"}</td>
                      </tr>
                    })}
                    </tbody>
                  </Table>
                </div>
              </div>
              : null
          }
        </div>
      </PagesWrapper>
    )
  }
}