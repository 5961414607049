import React, { Component } from "react"
import Button from "react-bootstrap/Button"
import { withRouter } from "react-router-dom"
import DatePicker from "react-datepicker"
import axios from "axios"
import { formatDate } from "../utils/utils"
import Table from "react-bootstrap/Table"
import NumberFormat from "react-number-format"
import { PagesWrapper } from "../utils/pages-wrapper"


class Summary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      assessorSummary: [],
      assessorDetailedSummary: [],
      budget: 100000,
      hourCost: null,
    }
  }

  render() {
    return (
      <PagesWrapper>
        <div>
          С
          <DatePicker
            selected={this.state.fromDate}
            dateFormat="dd-MM-yyyy HH:mm"
            onChange={fromDate => this.setState({ fromDate })}
            locale="ru"
            showTimeSelect
            timeIntervals={60}
            timeCaption="Время"
            timeFormat="HH:mm"
            includeDates={this.state.reportDates}
          />
          По
          <DatePicker
            selected={this.state.toDate}
            dateFormat="dd-MM-yyyy HH:mm"
            onChange={toDate => this.setState({ toDate })}
            locale="ru"
            showTimeSelect
            timeIntervals={60}
            timeCaption="Время"
            timeFormat="HH:mm"
            includeDates={this.state.reportDates}
          />
          <div>
            Общий бюджет
            <NumberFormat value={this.state.budget} thousandSeparator={true} prefix={"₽"} allowNegative={false}
                          decimalScale={0}
                          onValueChange={(values) => {
                            const { floatValue } = values
                            this.setState({ budget: floatValue || null })
                          }}/>
          </div>
          <div>
            Час разметки
            <NumberFormat value={this.state.hourCost} thousandSeparator={true} prefix={"₽"} allowNegative={false}
                          decimalScale={0}
                          onValueChange={(values) => {
                            const { floatValue } = values
                            this.setState({ hourCost: floatValue || null })
                          }}/>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              const from = formatDate(this.state.fromDate)
              const to = formatDate(this.state.toDate)
              var url = `/api/v1/summary?from_time=${from}&to_time=${to}`
              if (this.state.budget) {
                url += `&budget=${this.state.budget}`
              }
              if (this.state.hourCost) {
                url += `&hour_cost=${this.state.hourCost}`
              }
              axios.get(url).then(res => {
                this.setState({
                  assessorSummary: res.data.assessor_summary,
                  assessorDetailedSummary: res.data.assessor_detailed_summary,
                  budget: res.data.budget,
                  hourCost: res.data.hour_cost,
                })
              })
            }}>
            Рассчитать
          </Button>
          <div>
            Работа асессоров
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <td>Имя</td>
                <td>Размеченные часы</td>
                <td>ЗП</td>
              </tr>
              </thead>
              <tbody>
              {this.state.assessorSummary.map((row, i) => {
                return <tr key={i}>
                  <td>{row.assessor_name}</td>
                  <td>{row.markup_hours.toFixed(2)}</td>
                  <td>{row.salary.toFixed(2)}₽</td>
                </tr>
              })}
              </tbody>
            </Table>
          </div>
          <div>
            Детализированная работа асессоров
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <td>Имя</td>
                <td>Дата</td>
                <td>Рабочее место</td>
                <td>Размеченные часы</td>
                <td>Стоимость</td>
              </tr>
              </thead>
              <tbody>
              {this.state.assessorDetailedSummary.map((row, i) => {
                return <tr key={i}>
                  <td>{row.assessor_name}</td>
                  <td>{row.date}</td>
                  <td>{row.workplace_name}</td>
                  <td>{row.markup_hours.toFixed(2)}</td>
                  <td>{row.salary.toFixed(2)}₽</td>
                </tr>
              })}
              </tbody>
            </Table>
          </div>
        </div>
      </PagesWrapper>
    )
  };
}

export default withRouter(Summary)