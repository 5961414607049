import React from "react"
import ReactEcharts from "echarts-for-react"
import { colorize } from "./chart-util"

export const DoubleDiagram = ({ data }) => {
  const getOption = () => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: 10,
        data: data.legend,
      },
      series: [
        {
          name: data.label,
          type: "pie",
          label: {
            show: false,
          },
          selectedMode: "single",
          radius: [0, "30%"],
          data: colorize(data.inner),
        },
        {
          name: data.label,
          type: "pie",
          radius: ["40%", "55%"],
          label: {
            formatter: "{b}：{d}% ",
            backgroundColor: "#eee",
            borderColor: "#aaa",
            borderWidth: 1,
            borderRadius: 4,
            shadowBlur: 3,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
            shadowColor: "#999",
            padding: [0, 7],
          },
          data: colorize(data.outer),
        },
      ],
    }
  }

  return (
    <div className="report-chart double-diagram">
      <ReactEcharts
        option={getOption()}
        style={{ height: "800px" }}
        opts={{ renderer: "svg" }} // use svg to render the chart.
      />
    </div>
  )
}