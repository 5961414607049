import { Table, TableBody, TableContainer } from "@material-ui/core"
import TableHead from "@material-ui/core/TableHead"
import React from "react"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import { Collapser } from "./collapser"

export function formatDate(date) {
  return new Date(date).toISOString()
}

export function formatZero(time) {
  const len = 2
  return (new Array(len + 1).join("0") + time).slice(-len)
}

export function formatTime(time) {
  return `${formatZero(Math.floor(time / 3600))}:${formatZero(Math.floor((time % 3600) / 60))}:${formatZero(time % 60)}`
}

export function parseISOString(s) {
  return s.split(/\D+/).join("-").slice(0, -5)
}

export function parseSecondsFromMinutes(str) {
  if (!str) {
    return null
  }
  return parseInt(str) * 60
}

export function toMinutesString(seconds) {
  return seconds !== undefined ? (seconds / 60).toString() : null
}

export function formatDateString(s) {
  return formatDateAndTimeLocale(new Date(s))
}

export function formatDateAndTimeLocale(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes().toString()
  let seconds = date.getSeconds().toString()
  if (minutes.length < 2) {
    minutes = "0" + minutes
  }
  if (seconds.length < 2) {
    seconds = "0" + seconds
  }
  const strTime = hours + ":" + minutes + ":" + seconds
  return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + "  " + strTime
}

const getCells = (row) => {
  const cells = row.cells.map((cell) => {
    if (cell.collapsed) {
      return <TableCell align="center" colSpan={cell.colspan} rowSpan={cell.rowspan}><Collapser
        data={cell.value}/></TableCell>
    }
    return <TableCell align="center" colSpan={cell.colspan} rowSpan={cell.rowspan}>{cell.value}</TableCell>
  })
  return <TableRow>{cells}</TableRow>
}

export function getTable(table) {
  return (
    table ?
      <TableContainer className="report-table-container">
        <Table>
          <TableHead>
            {table.header.map((row) => getCells(row))}
          </TableHead>
          <TableBody>
            {table.rows.map((row) => getCells(row))}
          </TableBody>
        </Table>
      </TableContainer>
      : null
  )
}