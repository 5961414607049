import React from "react"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import DeleteIcon from "@material-ui/icons/Delete"
import FormControl from "@material-ui/core/FormControl"
import { StructuresState } from "../../store/projects/structures/types"
import { OperationState } from "../../store/projects/operations/types"
import { StructureDataState } from "../../store/projects/structure-data/types"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { AddOperationAction, EditOperationAction, RemoveOperationAction } from "../../store/projects/operations/actions"


interface RootState {
  structures: StructuresState
  operations: OperationState
  structureData: StructureDataState
}

export const ProjectDataOperations: React.FC = () => {
  const activeId = useSelector((state: RootState) => state.structures.active)
  const operations = useSelector((state: RootState) => state.operations.operations, shallowEqual)
  const operationTypes = useSelector((state: RootState) => state.operations.operationTypes)
  const operationsToDelete = useSelector((state: RootState) => state.operations.toDelete)
  const dispatch = useDispatch()

  if (!operations) {
    return null
  }

  const save = () => {
    for (const op of operations) {
      if (!op.name) {
        alert("Не хватает имени у одной из операций!")
        return
      }
    }

    axios.post(`/api/v1/operations`, {
      structure_id: activeId,
      changed_operations: operations,
      operations_to_delete: operationsToDelete,
    }).then(() => alert("Успешно сохранено")) //todo Здесь ходим заново в ручку!
  }

  console.log(operations)
  return (
    <div className="project-data-operations">
      <p>Редактирование операций</p>
      <div className="project-data-operations-add-button">
        <Fab color="primary"
             variant="extended"
             onClick={() => dispatch(AddOperationAction())}
        >
          <AddIcon/>
          Добавить
        </Fab>
      </div>
      <div className="project-data-operations-data">
        {operations.map((operation, i) => {
            return (
              <div className="project-data-operations-data-element">
                <div className="project-data-operations-data-element-form">
                  <FormControl fullWidth>
                    <TextField
                      label="Имя"
                      value={operation.name}
                      onChange={(event) => {
                        const { value } = event.target
                        dispatch(EditOperationAction(i, undefined, value, undefined))
                      }
                      }
                    />
                  </FormControl>
                </div>
                <div className="project-data-operations-data-element-form">
                  <FormControl fullWidth>
                    <Select
                      value={operation.type}
                      onChange={(event) => {
                        dispatch(EditOperationAction(i, event.target.value as string, undefined, undefined))
                      }}
                      style={{ minWidth: 190 }}
                    >
                      {
                        Object.keys(operationTypes).map((id) => {
                            return <MenuItem value={id}>{operationTypes[id]}</MenuItem>
                          },
                        )
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="project-data-operations-data-element-form">
                  <FormControl fullWidth>
                    <TextField
                      label="Точка отсчета"
                      value={operation.referencePoint}
                      onChange={(event) => {
                        const { value } = event.target
                        dispatch(EditOperationAction(i, undefined, undefined, value))
                      }}
                    />
                  </FormControl>
                </div>
                <div className="project-data-operations-data-element-form">
                  <Button variant="contained"
                          color="secondary"
                          onClick={() => {
                            dispatch(RemoveOperationAction(i))
                          }}>
                    <DeleteIcon/>
                  </Button>
                </div>
              </div>
            )
          },
        )}
      </div>
      <div className="project-data-operations-save-button">
        <Button variant="contained"
                color="primary"
                onClick={save}>
          Сохранить
        </Button>
      </div>
    </div>
  )
}