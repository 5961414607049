import React, {Component} from 'react';
import NewWord from "../landing-content/new-word";
import Sure from "../landing-content/sure";
import WhatWeGot from "../landing-content/what-we-got";
import Reviews from "../landing-content/reviews";
import {Services} from "../landing-content/services/services";
import {LandingContainer} from "../landing-container/landing-container";

export default class Landing extends Component {

    render() {
        return (
            <LandingContainer>
                <NewWord/>
                <Sure/>
                <Services/>
                <WhatWeGot/>
                <Reviews/>
            </LandingContainer>
        );
    };
}