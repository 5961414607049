import React, { Component } from "react"
import "./markup.css"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import MarkupContent from "../markup-content"
import { PagesWrapper } from "../utils/pages-wrapper"


export default class Markup extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         organization: props.match.params.organization,
  //         option: props.match.params.option,
  //         offset: props.match.params.offset,
  //         limit: props.match.params.limit,
  //         pattern: props.match.params.pattern,
  //     };
  // }

  render() {
    return (
      <PagesWrapper>
        <div className="markup">
          <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
            <Tab eventKey="all" title="All">
              <MarkupContent show="all" queryParams={this.props.match.params}/>
            </Tab>
            <Tab eventKey="finished" title="Finished">
              <MarkupContent show="finished" queryParams={this.props.match.params}/>
            </Tab>
            <Tab eventKey="unfinished" title="Unfinished">
              <MarkupContent show="unfinished" queryParams={this.props.match.params}/>
            </Tab>
          </Tabs>
        </div>
      </PagesWrapper>
    )
  };
}