import React, {useState} from "react"

export const Collapser = ({data}) => {
    const [closed, setClosed] = useState(true)

    return (
        <a onClick={() => {
            setClosed(!closed)
        }}>{closed ? `▼ ${data.split(' ')[0]}` : `▲ ${data}`}</a>
    )
}