import React from "react";
import Image from "react-image-webp";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export const NewsCard = ({picture, header, text, hrefText, href, date}) => {
    return <div className='news-card'>
        <div className='img-container'>
            <center>
                <a href={href}>
                    <img className="news-card-picture"
                         src={picture}
                         alt="LABRA"
                    />
                </a>
            </center>
        </div>
        <a href={href}>
            {header}
        </a>
        {text}
        {date ? `Дата: ${date}` : undefined}
        <Link href={href}>{hrefText}</Link>
    </div>
}