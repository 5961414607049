import React, {useEffect} from 'react';
import './app-navbar.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../img/logo.png";
import logo_webp from "../img/logo.webp";
import LoginButton from "../login-button";
import Image from "react-image-webp";
import {useDispatch, useSelector} from "react-redux";
import {UserLoginAction} from "../../store/login/actions";
import axios from "axios";
import {genericHashLink} from "react-router-hash-link";

const BootstrapHashLink = genericHashLink(Nav.Link);

export const AppNavbar = () => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.login)

    useEffect(() => {
        axios.get(`/api/v1/whoami`).then(res => {
            if (res && res.data && !res.data.error) {
                dispatch(UserLoginAction(res.data.first_name, res.data.last_name))
            }
        })
    }, [user.loggedIn])

    return (
        <div className="app-navbar" id={"nav-abs"}>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="/">
                    <Image
                        id="logo"
                        src={logo}
                        webp={logo_webp}
                        alt="LABRA"
                    />
                </Navbar.Brand>
                <Nav.Link style={{
                    color: "#ff8400",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                }} href="https://tracker.labrahub.ru">
                    LABRA-трекер задач переехал
                </Nav.Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link style={{
                            color: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                        }} href="/#/products">
                            Продукты
                        </Nav.Link>
                        {/*<Nav.Link style={{*/}
                        {/*    color: "#ff005c",*/}
                        {/*    fontFamily: "Montserrat, sans-serif",*/}
                        {/*    fontWeight: "bolder",*/}
                        {/*}} href="/#/calculator">*/}
                        {/*    Калькулятор повышения производительности труда*/}
                        {/*</Nav.Link>*/}
                        <Nav.Link style={{
                            color: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                        }} href="/#/services">Наши услуги</Nav.Link>
                        <Nav.Link style={{
                            color: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                        }} href="/#/news">Новости</Nav.Link>
                        <BootstrapHashLink style={{
                            color: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                        }} to="#form" href="/#/#form">Контакты</BootstrapHashLink>
                        <BootstrapHashLink style={{
                            color: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                        }} href="https://ai.labrahub.ru">Войти</BootstrapHashLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
