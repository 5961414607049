import React from "react";

export const EffectivePlatform = () => {
    return <div id="effective-platform" className="landing-section">
        <div id="effective-platform" className="landing-section-content">
            <h3>Платформа, которая поможет вашим сотрудникам работать эффективнее</h3>
            <p>Наш умный алгоритм соберет необходимые данные с ваших камер наблюдения и будет отслеживать эффективность
            работы ваших сотрудников</p>
            {/*Необходим только доступ к видеозаписям ваших камер наблюдения*/}
            {/*С помощью умного алгоритма вы сможете самостоятельно обучить систему отслеживать и анализировать необходимые*/}
            {/*вам подразделения и формировать отчетность, чтобы оптимизировать работу ваших сотрудников уже сегодня*/}
        </div>
    </div>
}