import React from "react";
import Image from "react-image-webp";
import shishkina from "../img/services/lena.png";

export const WhoWeAre = () => {
    return <div id="who-we-are" className="landing-section">
        <div id="who-we-are-content" className="landing-section-content">
            <div id="who-we-are-top">
                <div id="who-we-are-top-text">
                    <b>Кто мы?<br/>
                        Мы стартап, но работаем по принципам крупных компаний? Люди из разных отраслей: HR-консультанты,
                        рекрутеры,
                        специалисты по работе с документооборотом, менеджеры по персоналу, разработчики, программисты,
                        data-сайентисты объединились совместно с разработанным нами алгоритмом и нейросетью под чутким
                        руководством
                        HR-исследователя и публициста, чтобы сделать работу персонала эффективнее, а ваши затраты -
                        ниже.
                    </b>
                </div>
                <div id="who-we-are-top-card">
                    <img
                        className="what-we-can-card-picture"
                        src={shishkina}
                        alt="LABRA"
                    />
                    <b>Елена Шишкина</b>
                    <br/>
                    <b>CEO</b>
                </div>
            </div>
            <div id="who-we-are-bot">
                <ul>
                    <li>Индивидуальный подход к каждой компании - мы разрабатываем гибкие и комплексные решения для
                        каждого
                        клиента
                    </li>
                    <li>Конфиденциальность - мы не храним данные клиентов по окончанию действия договора оказания услуг,
                        а
                        все
                        используемые материалы храним на территории РФ
                    </li>
                    <li>Внимание к деталям: мы сможем настроить ваши <b>HR</b>-процессы таким образом, что ближайшие несколько
                        лет
                        вам
                        потребуется только корректировать процессы под свои нужды
                    </li>
                    <li>Долгосрочный результат: наши разработки продолжают приносить свою пользу и оказывать
                        положительный
                        эффект
                        после непосредственного сотрудничества с нами
                    </li>
                </ul>
            </div>
        </div>
    </div>
}