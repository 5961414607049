import React, {useEffect} from "react"
import Paper from "@material-ui/core/Paper"
import human from "../img/human.svg"
import {getTable} from "../utils/utils";

export const ReportHuman = ({data}) => {
    useEffect(() => {
        const stepSize = 50
        setTimeout((function () {
            let filler = document.getElementById("filler"),
                percentage = 0
            return function progress() {
                filler.style.height = percentage + "%"
                percentage += 1
                if (percentage <= data.percent) {
                    setTimeout(progress, stepSize)
                }
            }
        }()), stepSize)
    })

    return (
        <div className="report-diagram">
            <Paper className="report-diagram-paper">
                <div className="report-diagram-container">
                    <div className="report-diagram-human-container">
                        <div className="report-diagram-human">
                            <div id="filler" className="report-diagram-human-filler"/>
                            <img src={human} alt="human"/>
                            <p>Полезное<br/>время<br/>{data.percent}%</p>
                        </div>
                        <div className="report-diagram-human-table">
                            {getTable(data.table)}
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    )
}