import React from "react"
import ReactEcharts from "echarts-for-react"
import { colorize } from "./chart-util"

export const PieChart = ({ data }) => {
  const getOption = () => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: 10,
        data: data.legend,
      },
      series: [
        {
          name: data.label,
          type: "pie",
          radius: ["50%", "70%"],
          label: {
            formatter: "{b}：{d}%",
            position: "outer",
            alignTo: "none",
            bleedMargin: 5,
          },
          emphasis: {
            label: {
              position: "outer",
              alignTo: "labelLine",
              bleedMargin: 5,
            },
          },
          data: colorize(data.segments)
        },
      ],
    }
  }

  return (
    <div className="report-chart piechart">
      <ReactEcharts
        option={getOption()}
        style={{ height: "800px" }}
        opts={{ renderer: "svg" }} // use svg to render the chart.
      />
    </div>
  )
}
