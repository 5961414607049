import { ParametersState, ProjectParametersActionTypes } from "./types"
import { Reducer } from "redux"
import {resolveNaN} from "../../store-utils";

const initialState: ParametersState = {
  employeeSalary: undefined,
  regularBreakDuration: undefined,
  restInIdle: undefined,
  timeSeriesStabilityThreshold: undefined,
  employeeCount: undefined,
  fluctReduction: undefined
}

export const projectParametersReducer: Reducer<ParametersState, ProjectParametersActionTypes> = (state = initialState, action: ProjectParametersActionTypes) => {
  switch (action.type) {
    case "LOAD_PARAMETERS":
      return {
        employeeSalary: action.employeeSalary,
        regularBreakDuration: action.regularBreakDuration,
        restInIdle: action.restInIdle,
        timeSeriesStabilityThreshold: action.timeSeriesStabilityThreshold,
        employeeCount: action.employeeCount,
        fluctReduction: action.fluctReduction
      }
    case "EDIT_PARAMETERS":
      return {
        employeeSalary: resolveNaN(state.employeeSalary, action.newEmployeeSalary),
        regularBreakDuration: resolveNaN(state.regularBreakDuration, action.newRegularBreakDuration),
        restInIdle: action.newRestInIdle !== undefined ? action.newRestInIdle : state.restInIdle,
        timeSeriesStabilityThreshold: resolveNaN(state.timeSeriesStabilityThreshold, action.newTimeSeriesStabilityThreshold),
        employeeCount: resolveNaN(state.employeeCount, action.newEmployeeCount),
        fluctReduction: resolveNaN(state.fluctReduction, action.newFluctReduction)
      }
    default:
      return state
  }
}