export enum TimeSelect {
  from,
  to
}

export interface Employee {
  id: number
  name: string
}

export interface StructureDataState {
  fromTime: Date
  toTime: Date
  employees: Employee[]
  selectedEmployee: number
  reportDates: Date[]
}

export const UPDATE_STRUCTURE = "UPDATE_STRUCTURE"
export const CHANGE_TIME = "CHANGE_TIME"
export const SELECT_EMPLOYEE = "SELECT_EMPLOYEE"

interface UpdateStructureAction {
  type: typeof UPDATE_STRUCTURE
  reportDates: Date[],
  employees: Employee[]
}

interface ChangeTimeAction {
  type: typeof CHANGE_TIME
  timeSelect: TimeSelect
  newTime: Date
}

interface SelectEmployeeAction {
  type: typeof SELECT_EMPLOYEE
  employeeId: number
}

export type StructureDataActionTypes = UpdateStructureAction | ChangeTimeAction | SelectEmployeeAction