import { Reducer } from "redux"
import { StructureDataActionTypes, StructureDataState, TimeSelect } from "./types"

const initialState: StructureDataState = {
  employees: [],
  fromTime: new Date(),
  reportDates: [],
  selectedEmployee: 0,
  toTime: new Date(),
}

export const structureDataReducer: Reducer<StructureDataState, StructureDataActionTypes> = (state = initialState, action: StructureDataActionTypes) => {
  switch (action.type) {
    case "UPDATE_STRUCTURE":
      return {
        ...state,
        reportDates: action.reportDates,
        employees: action.employees,
        selectedEmployee: action.employees.length > 0 ? action.employees[0].id : 0
      }
    case "CHANGE_TIME":
      return action.timeSelect === TimeSelect.from ?
        {
          ...state,
          fromTime: action.newTime,
        } :
        {
          ...state,
          toTime: action.newTime,
        }
    case "SELECT_EMPLOYEE":
      return {
        ...state,
        selectedEmployee: action.employeeId
      }
    default:
      return state
  }
}
