import React from "react"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorPublicOutput = ({
                                         measurementsNum,
                                         overallMeasurements,
                                         finalCost,
                                         savePerMonth,
                                         savePerYear,
                                         finalTerms,
                                         needCameras,
                                         cameras,
                                       }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-public-output calculator-form-group">
        <p>Количество замеров по одному сотруднику - {measurementsNum}</p>
        <p>Общее количество замеров - {overallMeasurements}</p>
        <p>Итоговая стоимость услуги - {finalCost}₽</p>
        <p>Итоговая экономия средств в месяц составит - {savePerMonth}₽</p>
        <p>Итоговая экономия средств в год составит - {savePerYear}₽</p>
        {/*<CalculatorProblems/>*/}
        <p>Итоговые сроки проведения работ - {finalTerms} дней</p>
        {needCameras ? <p>Установка камер наблюдения - {cameras}₽</p> : null}
      </div>
    </CalculatorPaperWrapper>
  )
}