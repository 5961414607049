import React, { Component } from "react"
import Paper from "@material-ui/core/Paper"
import "./calculator.css"
import { CalculatorRequiredMeasurements } from "./calculator-required-measurements"
import axios from "axios"
import { CalculatorPublicInput } from "./calculator-public-input"
import { CalculatorPublicOutput } from "./calculator-public-output"
import { CalculatorPrivateInput } from "./calculator-private-input"
import { CalculatorPrivateOutput } from "./calculator-private-output"
import { CalculatorCameras } from "./calculator-cameras"
import { CalculatorTable } from "./calculator-table"
import { CalculatorDays } from "./calculator-days"
import { CalculatorLicenseInput } from "./calculator-license-input"
import { CalculatorRationale } from "./calculator-rationale"
import { CalculatorGraph } from "./calculator-graph"
import { PagesWrapper } from "../utils/pages-wrapper"

const productionTypes = ["Массовое", "Крупносерийное", "Серийное", "Мелкосерийное"]

export default class Calculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentProductionType: "",
      averageTime: false,
      stabilityCoef: null,
      requiredMeasurements: null,
      employeesNum: null,
      shiftsPerDay: null,
      businessDayNum: null,
      hoursPerShift: null,
      averageSalary: null,
      insuranceAndPension: true,
      visible: true,
      unseen: 0,
      showPrivate: false,
      carWorkTime: null,
      showDatabaseSettings: false
    }

    this.getOutput = this.getOutput.bind(this)
  }

  componentDidMount() {
    axios.get(`/api/v2/get_calculator_defaults`).then(res => {
      this.setState({
        showPrivate: true,
        manual_labor_coef: res.data.manual_labor_coef,
        hour_analytics_price: res.data.hour_analytics_price,
        hour_markup_price: res.data.hour_markup_price,
        hour_support_price: res.data.hour_support_price,
        hour_tech_price: res.data.hour_tech_price,
        specialist_num: res.data.specialist_num,
        rewind_speed: res.data.rewind_speed,
        markup_work_hours: res.data.markup_work_hours,
        opt_resource: res.data.opt_resource,
        insurance: res.data.insurance,
      })
    }).catch(error => {
      console.log(error)
    })
    axios.get(`/api/v2/get_calculator_gear_defaults`).then(res => {
      this.setState({
        showPrivate: true,
        showDatabaseSettings: res.data.can_edit,
        owned_cameras: res.data.owned_cameras,
        owned_cables: res.data.owned_cables,
        owned_ext: res.data.owned_ext,
        owned_fastener: res.data.owned_fastener,
        owned_hdd: res.data.owned_hdd,
        owned_pc: res.data.owned_pc,
        owned_box: res.data.owned_box,
        camera_price: res.data.camera_price,
        cable_price: res.data.cable_price,
        ext_price: res.data.ext_price,
        fastener_price: res.data.fastener_price,
        hdd_price: res.data.hdd_price,
        pc_price: res.data.pc_price,
        box_price: res.data.box_price,
        owned_switch: res.data.owned_switch,
        switch_price: res.data.switch_price,
      })
    }).catch(error => {
      console.log(error)
    })
    axios.get(`/api/v2/get_calculator_license`).then(res => {
      this.setState({
        showPrivate: true,
        carWorkTime: res.data.car_work_time,
        car_price: res.data.car_price,
        conv: res.data.conv,
        markup: res.data.markup,
        service: res.data.service,
      })
    }).catch(error => {
      console.log(error)
    })
  }

  updateRequiredMeasurements = () => {
    if (this.state.currentProductionType !== "") {
      axios.get(`/api/v2/required_measurements?production_type=${this.state.currentProductionType}&less_10_sec=${this.state.averageTime}`).then(res => {
        this.setState({
          stabilityCoef: res.data.stability_coef,
          requiredMeasurements: res.data.measurements_req,
        })
      })
    }
  }

  handleProductionTypeChange = (event) => {
    this.setState({ currentProductionType: event.target.value }, () => {
      this.updateRequiredMeasurements()
    })
  }

  handleAverageTimeChange = (event) => {
    this.setState({ averageTime: event.target.checked }, () => {
      this.updateRequiredMeasurements()
    })
  }

  getOutput = () => {
    if (!this.state.employeesNum || !this.state.shiftsPerDay || !this.state.businessDayNum || !this.state.hoursPerShift || !this.state.averageSalary) {
      return
    }
    if (!this.state.visible && !this.state.unseen) {
      return
    }
    const input = {
      employees_num: this.state.employeesNum,
      shifts_per_day: this.state.shiftsPerDay,
      business_day_num: this.state.businessDayNum,
      hours_per_shift: this.state.hoursPerShift,
      average_salary: this.state.averageSalary,
      insurance_and_pension: this.state.insuranceAndPension,
      visible: this.state.visible,
      unseen: this.state.unseen,
    }
    axios.get(`/api/v2/get_calculator_public_output`, {
      params: input,
    }).then(res => {
      this.setState({
        measurements_num: res.data.measurements_num,
        overall_measurements: res.data.overall_measurements,
        final_cost: res.data.final_cost,
        save_per_month: res.data.save_per_month,
        save_per_year: res.data.save_per_year,
        final_terms: res.data.final_terms,
        cameras: res.data.cameras,
      })
    })
    axios.get(`/api/v2/get_calculator_private_output`, {
      params: input,
    }).then(res => {
      console.log(res.data)
      this.setState({
        showPrivate: true,
        manual_measurements_hours: res.data.manual_measurements_hours,
        box_work_price: res.data.box_work_price,
        accountant_price: res.data.accountant_price,
        overhead: res.data.overhead,
        transport_price: res.data.transport_price,
        marketing_price: res.data.marketing_price,
        future_price: res.data.future_price,
        taxes: res.data.taxes,
        table_cars: res.data.table_cars,
        table_economy: res.data.table_economy,
      })
    }).catch(error => {
      console.log(error)
    })
    axios.get(`/api/v2/get_calculator_gear_table`, {
      params: input,
    }).then(res => {
      this.setState({
        showPrivate: true,
        table: res.data,
      })
    }).catch(error => {
      console.log(error)
    })
    axios.get(`/api/v2/get_calculator_days`, {
      params: input,
    }).then(res => {
      this.setState({
        showPrivate: true,
        markups_days: res.data.markups_days,
        analytics_days: res.data.analytics_days,
        camera_install_days: res.data.camera_install_days,
        final_terms: res.data.final_terms,
      })
    }).catch(error => {
      console.log(error)
    })
    axios.get(`/api/v2/get_calculator_measurement_graph`, {
      params: input,
    }).then(res => {
      this.setState({
        showPrivate: true,
        measurement_economy: res.data,
      })
    }).catch(error => {
      console.log(error)
    })
  }

  handleEmployeesNumChange = (event) => {
    this.setState({ employeesNum: event.target.value }, () => {
      this.getOutput()
    })
  }

  handleShiftsPerDayChange = (event) => {
    this.setState({ shiftsPerDay: event.target.value }, () => {
      this.getOutput()
    })
  }

  handleBusinessDayNumChange = (event) => {
    this.setState({ businessDayNum: event.target.value }, () => {
      this.getOutput()
    })
  }

  HandleHoursPerShiftChange = (event) => {
    this.setState({ hoursPerShift: event.target.value }, () => {
      this.getOutput()
    })
  }

  handleAverageSalaryChange = (event) => {
    this.setState({ averageSalary: event.target.value }, () => {
      this.getOutput()
    })
  }

  handleInsuranceAndPensionChange = (event) => {
    this.setState({ insuranceAndPension: event.target.checked }, () => {
      this.getOutput()
    })
  }

  handleVisibleChange = (event) => {
    this.setState({ visible: event.target.checked }, () => {
      this.getOutput()
    })
  }

  handleUnseenChange = (event) => {
    this.setState({ unseen: event.target.value }, () => {
      this.getOutput()
    })
  }

  handleUpdateDefaults = () => {
    const defaults = []
    axios.get(`/api/v2/get_calculator_defaults`).then(res => {
      for (let key in res.data) {
        if (res.data.hasOwnProperty(key) &&
          this.state.hasOwnProperty(key) &&
          res.data[key] !== this.state[key]
        ) {
          defaults.push({ name: key, new_value: this.state[key] })
        }
      }
    }).then(() => {
        if (defaults.length > 0) {
          axios.post("/api/v2/update_calculator_defaults", defaults).then(res => {
            console.log(res.data)
          })
        }
      },
    )
    const gear_defaults = []
    axios.get(`/api/v2/get_calculator_gear_defaults`).then(res => {
      for (let key in res.data) {
        if (res.data.hasOwnProperty(key) &&
          this.state.hasOwnProperty(key) &&
          res.data[key] !== this.state[key]
        ) {
          gear_defaults.push({ name: key, new_value: this.state[key] })
        }
      }
    }).then(() => {
        if (gear_defaults.length > 0) {
          axios.post("/api/v2/update_calculator_defaults", gear_defaults).then(res => {
            console.log(res.data)
          })
        }
      },
    )
    const calculator_license = []
    axios.get(`/api/v2/get_calculator_license`).then(res => {
      for (let key in res.data) {
        if (res.data.hasOwnProperty(key) &&
          this.state.hasOwnProperty(key) &&
          res.data[key] !== this.state[key]
        ) {
          calculator_license.push({ name: key, new_value: this.state[key] })
        }
      }
    }).then(() => {
        if (calculator_license.length > 0) {
          axios.post("/api/v2/update_calculator_defaults", calculator_license).then(res => {
            console.log(res.data)
          })
        }
      },
    )
  }

  handleManualLaborCoefChange = (event) => {
    this.setState({ manual_labor_coef: event.target.value })
  }

  handleHourAnalyticsPriceChange = (event) => {
    this.setState({ hour_analytics_price: event.target.value })
  }

  handleHourMarkupPriceChange = (event) => {
    this.setState({ hour_markup_price: event.target.value })
  }

  handleHourSupportPriceChange = (event) => {
    this.setState({ hour_support_price: event.target.value })
  }

  handleHourTechPriceChange = (event) => {
    this.setState({ hour_tech_price: event.target.value })
  }

  handleSpecialistNumChange = (event) => {
    this.setState({ specialist_num: event.target.value })
  }

  handleRewindSpeedChange = (event) => {
    this.setState({ rewind_speed: event.target.value })
  }

  handleMarkupWorkHoursChange = (event) => {
    this.setState({ markup_work_hours: event.target.value })
  }

  handleOptTesourceChange = (event) => {
    this.setState({ opt_resource: event.target.value })
  }

  handleInsuranceChange = (event) => {
    this.setState({ insurance: event.target.value })
  }

  handleOwnedCamerasChange = (event) => {
    this.setState({ owned_cameras: event.target.value })
  }

  handleOwnedCablesChange = (event) => {
    this.setState({ owned_cables: event.target.value })
  }

  handleOwnedExtChange = (event) => {
    this.setState({ owned_ext: event.target.value })
  }

  handleOwnedFastenerChange = (event) => {
    this.setState({ owned_fastener: event.target.value })
  }

  handleOwnedHddChange = (event) => {
    this.setState({ owned_hdd: event.target.value })
  }

  handleOwnedPcChange = (event) => {
    this.setState({ owned_pc: event.target.value })
  }

  handleOwnedBoxChange = (event) => {
    this.setState({ owned_box: event.target.value })
  }

  handleCameraPriceChange = (event) => {
    this.setState({ camera_price: event.target.value })
  }

  handleCablePriceChange = (event) => {
    this.setState({ cable_price: event.target.value })
  }

  handleExtPriceChange = (event) => {
    this.setState({ ext_price: event.target.value })
  }

  handleFastenerPriceChange = (event) => {
    this.setState({ fastener_price: event.target.value })
  }

  handleHddPriceChange = (event) => {
    this.setState({ hdd_price: event.target.value })
  }

  handlePcPriceChange = (event) => {
    this.setState({ pc_price: event.target.value })
  }

  handleBoxPriceChange = (event) => {
    this.setState({ box_price: event.target.value })
  }

  handleOwnedSwitchChange = (event) => {
    this.setState({ owned_switch: event.target.value })
  }

  handleSwitchPriceChange = (event) => {
    this.setState({ switch_price: event.target.value })
  }

  handleCarPriceChange = (event) => {
    this.setState({ car_price: event.target.value },
      () => this.handleUpdateDefaults())
  }

  handleConvChange = (event) => {
    this.setState({ conv: event.target.value },
      () => this.handleUpdateDefaults())
  }

  handleMarkupChange = (event) => {
    this.setState({ markup: event.target.value },
      () => this.handleUpdateDefaults())
  }

  handleServiceChange = (event) => {
    this.setState({ service: event.target.value },
      () => this.handleUpdateDefaults())
  }

  render() {
    return (
      <PagesWrapper>
        <div className="calculator">
          <h1>Калькулятор повышения производительности труда</h1>
          <div className="calculator-blocks">
            <div className="calculator-blocks-left">
              <CalculatorRequiredMeasurements productionTypes={productionTypes}
                                              currentProductionType={this.state.currentProductionType}
                                              averageTime={this.state.averageTime}
                                              handleProductionTypeChange={this.handleProductionTypeChange}
                                              handleAverageTimeChange={this.handleAverageTimeChange}
                                              stabilityCoef={this.state.stabilityCoef}
                                              requiredMeasurements={this.state.requiredMeasurements}
              />
              <CalculatorPublicInput employeesNum={this.state.employeesNum}
                                     handleEmployeesNumChange={this.handleEmployeesNumChange}
                                     shiftsPerDay={this.state.shiftsPerDay}
                                     handleShiftsPerDayChange={this.handleShiftsPerDayChange}
                                     businessDayNum={this.state.businessDayNum}
                                     handleBusinessDayNumChange={this.handleBusinessDayNumChange}
                                     hoursPerShift={this.state.hoursPerShift}
                                     HandleHoursPerShiftChange={this.HandleHoursPerShiftChange}
                                     averageSalary={this.state.averageSalary}
                                     handleAverageSalaryChange={this.handleAverageSalaryChange}
                                     insuranceAndPension={this.state.insuranceAndPension}
                                     handleInsuranceAndPensionChange={this.handleInsuranceAndPensionChange}
                                     visible={this.state.visible}
                                     handleVisibleChange={this.handleVisibleChange}
                                     unseen={this.state.unseen}
                                     handleUnseenChange={this.handleUnseenChange}
              />
              {this.state.showDatabaseSettings ?
                [<CalculatorPrivateInput manualLaborCoef={this.state.manual_labor_coef}
                                         handleManualLaborCoefChange={this.handleManualLaborCoefChange}
                                         hourAnalyticsPrice={this.state.hour_analytics_price}
                                         handleHourAnalyticsPriceChange={this.handleHourAnalyticsPriceChange}
                                         hourMarkupPrice={this.state.hour_markup_price}
                                         handleHourMarkupPriceChange={this.handleHourMarkupPriceChange}
                                         hourSupportPrice={this.state.hour_support_price}
                                         handleHourSupportPriceChange={this.handleHourSupportPriceChange}
                                         hourTechPrice={this.state.hour_tech_price}
                                         handleHourTechPriceChange={this.handleHourTechPriceChange}
                                         specialistNum={this.state.specialist_num}
                                         handleSpecialistNumChange={this.handleSpecialistNumChange}
                                         rewindSpeed={this.state.rewind_speed}
                                         handleRewindSpeedChange={this.handleRewindSpeedChange}
                                         markupWorkHours={this.state.markup_work_hours}
                                         handleMarkupWorkHoursChange={this.handleMarkupWorkHoursChange}
                                         optTesource={this.state.opt_resource}
                                         handleOptTesourceChange={this.handleOptTesourceChange}
                                         insurance={this.state.insurance}
                                         handleInsuranceChange={this.handleInsuranceChange}
                                         handleUpdateDefaults={this.handleUpdateDefaults}
                />,
                  <CalculatorCameras ownedCameras={this.state.owned_cameras}
                                     ownedCables={this.state.owned_cables}
                                     ownedExt={this.state.owned_ext}
                                     ownedFastener={this.state.owned_fastener}
                                     ownedHdd={this.state.owned_hdd}
                                     ownedPc={this.state.owned_pc}
                                     ownedBox={this.state.owned_box}
                                     cameraPrice={this.state.camera_price}
                                     cablePrice={this.state.cable_price}
                                     extPrice={this.state.ext_price}
                                     fastenerPrice={this.state.fastener_price}
                                     hddPrice={this.state.hdd_price}
                                     pcPrice={this.state.pc_price}
                                     boxPrice={this.state.box_price}
                                     ownedSwitch={this.state.owned_switch}
                                     switchPrice={this.state.switch_price}
                                     handleOwnedCamerasChange={this.handleOwnedCamerasChange}
                                     handleOwnedCablesChange={this.handleOwnedCablesChange}
                                     handleOwnedExtChange={this.handleOwnedExtChange}
                                     handleOwnedFastenerChange={this.handleOwnedFastenerChange}
                                     handleOwnedHddChange={this.handleOwnedHddChange}
                                     handleOwnedPcChange={this.handleOwnedPcChange}
                                     handleOwnedBoxChange={this.handleOwnedBoxChange}
                                     handleCameraPriceChange={this.handleCameraPriceChange}
                                     handleCablePriceChange={this.handleCablePriceChange}
                                     handleExtPriceChange={this.handleExtPriceChange}
                                     handleFastenerPriceChange={this.handleFastenerPriceChange}
                                     handleHddPriceChange={this.handleHddPriceChange}
                                     handlePcPriceChange={this.handlePcPriceChange}
                                     handleBoxPriceChange={this.handleBoxPriceChange}
                                     handleOwnedSwitchChange={this.handleOwnedSwitchChange}
                                     handleSwitchPriceChange={this.handleSwitchPriceChange}
                                     handleUpdateGear={this.handleUpdateDefaults}
                  />,
                  <CalculatorLicenseInput carPrice={this.state.car_price}
                                          handleCarPriceChange={this.handleCarPriceChange}
                                          conv={this.state.conv}
                                          handleConvChange={this.handleConvChange}
                                          markup={this.state.markup}
                                          handleMarkupChange={this.handleMarkupChange}
                                          service={this.state.service}
                                          handleServiceChange={this.handleServiceChange}
                                          carWorkTime={this.state.carWorkTime}
                  />,
                ]
                : null
              }
              {this.state.table_economy ?
                <CalculatorTable table={this.state.table_economy}/>
                : null}
            </div>
            <div className="calculator-blocks-right">
              <CalculatorPublicOutput measurementsNum={this.state.measurements_num}
                                      overallMeasurements={this.state.overall_measurements}
                                      finalCost={this.state.final_cost}
                                      savePerMonth={this.state.save_per_month}
                                      savePerYear={this.state.save_per_year}
                                      finalTerms={this.state.final_terms}
                                      needCameras={!this.state.visible}
                                      cameras={this.state.cameras}
              />
              {this.state.showDatabaseSettings ?
                <CalculatorPrivateOutput manualMeasurementsHours={this.state.manual_measurements_hours}
                                         boxWorkPrice={this.state.box_work_price}
                                         accountantPrice={this.state.accountant_price}
                                         overhead={this.state.overhead}
                                         transportPrice={this.state.transport_price}
                                         marketingPrice={this.state.marketing_price}
                                         futurePrice={this.state.future_price}
                                         taxes={this.state.taxes}
                /> : null}
              {!this.state.visible ?
                <CalculatorTable table={this.state.table}/>
                : null}
              {this.state.showPrivate ?
                <CalculatorDays cameraInstallDays={this.state.camera_install_days}
                                markupDays={this.state.markups_days}
                                analyticsDays={this.state.analytics_days}
                                finalTerms={this.state.final_terms}
                />
                : null
              }
              {
                this.state.measurements_num ?
                  <CalculatorRationale measurements={this.state.measurements_num}/>
                  : null
              }
              {
                this.state.showPrivate && this.state.measurements_num ?
                  <CalculatorGraph measurementEconomy={this.state.measurement_economy}/>
                  : null
              }
              {this.state.table_cars ?
                <CalculatorTable table={this.state.table_cars}/>
                : null}
            </div>
          </div>
        </div>
      </PagesWrapper>
    )
  }
}