import React from "react"
import ReactEcharts from "echarts-for-react"

export const BarChart = ({ data }) => {
  const getOption = () => {
    return {
      color: ["#003366", "#006699", "#4cabce", "#e5323e"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {},
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: data.groups,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: data.series,
    }
  }

  return (
    <div className="report-chart bar-chart">
      <ReactEcharts
        option={getOption()}
        style={{ height: "800px" }}
        opts={{ renderer: "svg" }} // use svg to render the chart.
      />
    </div>
  )
}
