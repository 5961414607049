import React from "react"
import { FormGroup } from "@material-ui/core"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorDays = ({
                                 cameraInstallDays,
                                 markupDays,
                                 analyticsDays,
                                 finalTerms,
                               }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-days calculator-form-group">
        <p>Монтаж камер (дни) - {cameraInstallDays}</p>
        <p>Разметка видео (дни) - {markupDays}</p>
        <p>Аналитика и составление рекомендаций (дни) - {analyticsDays}</p>
        <p>Итоговые сроки проведения работ - {finalTerms}</p>
      </div>
    </CalculatorPaperWrapper>
  )
}