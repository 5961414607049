import React, {Component} from 'react';
import './pareto.css';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({

    chart: {
        style: {
            fontFamily: 'Montserrat'
        }
    },
});

export default class Pareto extends Component {

    render() {
        const operations = new Set();
        const t_reg = [];
        const t_freq_fact = [];
        const fluct_perc = [];
        this.props.measurements.rows.forEach((op) => {
            if (op.operation_type === "op") {
                operations.add(op.operation.name);
                t_reg.push(op.t_reg);
                t_freq_fact.push(op.t_freq_fact);
                fluct_perc.push((op.fluct_perc * 100));
            }
        });

        const paretoOptions = {
            chart: {
                type: 'column',
                width: 800,
                height: 800
            },
            title: {
                text: 'Диаграмма 3.1.<br/> Диаграмма Парето по операциям оперативного<br/> (основного времени) в порядке убывания<br/> длительности колебаний',
                style: {
                    fontSize: "20px",
                    fontWeight: "bold"
                },
            },
            xAxis: {
                categories: Array.from(operations)
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 't, сек'
                }
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        this.series.name + ': ' + this.y + '<br/>' +
                        'Total: ' + this.point.stackTotal;
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [{
                name: 'Колебания',
                data: t_freq_fact,
                stack: 'left'
            }, {
                name: 't оптимальное',
                data: t_reg,
                stack: 'right'
            }]
        };

        const paretoPercentageOptions = {
            chart: {
                type: 'column',
                width: 800,
                height: 800
            },
            title: {
                text: 'Диаграмма 3.2.<br/> Диаграмма Парето по операциям оперативного<br/> (основного времени) в порядке убывания<br/> в процентах в сравнении с допустимым<br/> уровнем колебания',
                style: {
                    fontSize: "20px",
                    fontWeight: "bold"
                }
            },
            xAxis: {
                categories: Array.from(operations)
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: '%'
                },
                plotLines: [{
                    value: 15,
                    label: {text: 'Доп. колеб. = 15%'},
                    width: 2,
                    zIndex: 4,
                }],
            },
            series: [{
                name: 't оптимальное',
                data: fluct_perc
            }]
        };

        return (
            <div className="pareto">
                <div className="pdf-split page">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={paretoOptions}
                    />
                </div>
                <div className="pdf-split page">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={paretoPercentageOptions}
                    />
                </div>
            </div>
        );
    };
}