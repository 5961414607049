import React, { useState } from "react"
import { getTable } from "../utils/utils"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"

export const ReportTable = ({ table }) => {
  const [expanded, setExpanded] = useState("panel")

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div className="report-table">
      <Accordion expanded={expanded === "panel"} onChange={handleChange("panel")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {table.label}
        </AccordionSummary>
        <AccordionDetails>
          {getTable(table)}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}