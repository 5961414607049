import React, {Component} from 'react';
import Form from "react-bootstrap/Form";
import './add-operation.css';
import "react-datepicker/dist/react-datepicker.css";
import NewFrame from "../new-frame";
import Button from "react-bootstrap/Button";
import ExistingFrames from "../existing-frames";

export default class AddOperation extends Component {
    currentActionFlag = false;

    state = {
        operationName: null,
        frames: []
    };

    onNewFrame = (label, action, x1, y1, x2, y2) => {
        this.setState((state) => {
            const frame = {label: label, action: action, x1: x1, y1: y1, x2: x2, y2: y2};
            return {frames: [...state.frames, frame]};
        });
    };

    onClick = () => {
        this.props.onOperationAdd(this.state.frames, this.state.operationName)
    };

    handleChange = (event) => {
        this.setState({operationName: event.target.value});
    };

    render() {
        return (
            <div className="add-operation">
                <div className="input-operation-field">
                    <div className="operation-name">
                        <Form.Control id="operation-name" type="text" placeholder="Название операции"
                                      onChange={this.handleChange}/>
                    </div>
                    <div className="ok-button">
                        <Button variant="danger" onClick={this.onClick}>Ok</Button>
                    </div>
                </div>
                <div className="existing-frames-container">
                    <ExistingFrames frames={this.state.frames}/>
                </div>
                <div className="frame-container">
                    <NewFrame onNewFrame={this.onNewFrame} canvas={this.props.canvas} video={this.props.video}/>
                </div>
            </div>
        );
    };
}