import React from "react"
import Paper from "@material-ui/core/Paper"

export const ReportDiagram = ({ chartGetter, data }) => {
  return (
    <div className="report-diagram">
      <h4>{data.label}</h4>
      <Paper className="report-diagram-paper">
        <div className="report-diagram-container">
          {chartGetter(data)}
        </div>
      </Paper>
    </div>
  )
}