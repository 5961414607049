import React, {Component} from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import TextField from "@material-ui/core/TextField";
import {removeButton} from "../utils/buttons";
import { PagesWrapper } from "../utils/pages-wrapper"
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default class ModelsStands extends Component {

    constructor(props) {
        super(props)
        this.state = {
            structureId: this.props.match.params.structureId,
            stands: [],
            absolute_locations: [],
            standTypes: ['Стенд', 'Базис'],
            selectedType: null,
            selectedName: '',
            x: null,
            y: null
        }
    }

    componentDidMount() {
        this.downloadStands()
        this.downloadAbsoluteLocations()
    }

    downloadAbsoluteLocations = () => {
        return axios.get(`/api/v1/absolute_locations?structure_id=${this.state.structureId}`).then(res => {
            this.setState({ absolute_locations: res.data })
        })
    }

    downloadStands = () => {
        return axios.get(`/api/v1/all_stands?structure_id=${this.state.structureId}`).then(res => {
            this.setState({ stands: res.data })
        })
    }

    clearFields = () => {
         this.setState({
             selectedType: null,
             selectedStand: null,
             x: null,
             y: null,
             selectedName: ''
         })
    }

    addStand = () => {
        if (!this.state.selectedType) {
            alert('Надо выбрать стенд или базис')
            return
        }
        if (this.state.selectedName === '') {
            alert('Надо ввести имя')
            return
        }
        if ((this.state.selectedType === 'Базис') && (!this.state.x || !this.state.y)) {
            alert('Надо получить кадр и выбрать хотя бы одну точку')
            return
        }

        axios.post(`/api/v1/stands`, {
            'organization_id': this.state.structureId,
            'changed_stands': [{
                name: this.state.selectedName
            }],
            'stands_to_delete': []
        }).then(this.downloadStands)
            .then(res => {
                if (this.state.selectedType === 'Базис') {
                    const stand = this.state.stands.find(s => s.name === this.state.selectedName)
                    if (!stand) {
                        return
                    }
                    axios.post(`/api/v1/absolute_location`, {
                        stand_id: stand.id,
                        x: this.state.x,
                        y: this.state.y
                    }).then(this.downloadAbsoluteLocations)
                }
            }).then(this.clearFields)
    }

    removeStand = (i) => {
        const stand = this.state.stands[i]
        if (!stand) return;
        const absolute_location = this.state.absolute_locations.find(location => location.stand_id === stand.id)
        if (absolute_location) {
            axios.delete(`/api/v1/absolute_location/remove/${absolute_location.id}`)
                .then(this.downloadAbsoluteLocations)
                .then(res => axios.post(`/api/v1/stands`, {
                            'organization_id': this.state.structureId,
                            'changed_stands': [],
                            'stands_to_delete': [stand.id]
                        }).then(this.downloadStands)
                    )
        } else {
            axios.post(`/api/v1/stands`, {
                            'organization_id': this.state.structureId,
                            'changed_stands': [],
                            'stands_to_delete': [stand.id]
            }).then(this.downloadStands)
        }
    }

    onLocationTypeSelected(locationType) {
        this.setState({selectedType: locationType})
    }

    getLocationTypes = () => {
        const drops = [];

        this.state.standTypes
            .forEach((locationType, i) => {
                drops.push(
                    <Dropdown.Item key={i} onClick={() => {
                        this.onLocationTypeSelected(locationType)
                    }}>{locationType}</Dropdown.Item>
                )
            });
        return (drops)
    }

    render() {
        return (
            <PagesWrapper>
                <h2>Области на видео</h2>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Имя локации</th>
                        <th>x</th>
                        <th>y</th>
                        <th>удолить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.stands.map((stand, i) => {
                        return (
                            <tr key={i}>
                                <td>{stand.name || '-'}</td>
                                <td>{this.state.absolute_locations.find(absolute_location => stand.id === absolute_location.stand_id)?.x}</td>
                                <td>{this.state.absolute_locations.find(absolute_location => stand.id === absolute_location.stand_id)?.y}</td>
                                <td>{removeButton(() => {
                                    this.removeStand(i)
                                })}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <div className="choose-stands">
                    <>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {(this.state.selectedType || 'Тип')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                    {this.getLocationTypes()}
                            </Dropdown.Menu>
                        </Dropdown>{'       '}
                    </>
                    <TextField label="Имя"
                               textAlign={'center'}
                               value={this.state.selectedName}
                               onChange={e => this.setState({ selectedName: e.target.value })}
                               type="text"
                    />
                    <TextField label="x"
                               textAlign={'center'}
                               hidden={this.state.selectedType !== 'Базис'}
                               value={this.state.x || ''}
                               onChange={e => this.setState({ x: e.target.value })}
                               type="number"
                    />
                    <TextField label="y"
                               textAlign={'center'}
                               hidden={this.state.selectedType !== 'Базис'}
                               value={this.state.y || ''}
                               onChange={e => this.setState({ y: e.target.value })}
                               type="number"
                    />
                </div>
                <Button
                    variant="primary"
                    onClick={this.addStand}>
                    Добавить
                </Button>
            </PagesWrapper>
        )
    }
}