import React, { Component } from "react"
import "./video.css"
import { Button } from "react-bootstrap"
import VideoOperation from "../video-operation"
import axios from "axios"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Alert from "react-bootstrap/Alert"
import FastRewindIcon from "@material-ui/icons/FastRewind"
import FastForwardIcon from "@material-ui/icons/FastForward"
import FormControl from "react-bootstrap/FormControl"
import Refresh from "@material-ui/icons/Refresh"
import Table from "react-bootstrap/Table"
import { formatTime } from "../utils/utils"
import { PagesWrapper } from "../utils/pages-wrapper"

export default class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videoId: props.match.params.videoId,
      currentSecond: props.match.params.getTime || 0,
      operations: [],
      deletedOperationMarkupIds: [],
      operationTypes: {},
      videoStampTypes: {},
      workplaces: [],
      employees: [],
      otherVideos: [],
      filename: null,
      comment: "",
      activities: [],
      preventClose: false,
      currentIds: [],
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      if (this.state.preventClose) {
        ev.preventDefault()
      }
    })
  }

  componentDidMount() {
    this.setupBeforeUnloadListener()
    const videoId = this.state.videoId
    axios.get(`/api/v1/available_operations?video_id=${videoId}`).then(res => {
      const stateUpdate = {
        operationTypes: [],
        videoStampTypes: [],
      }
      res.data.operations.forEach((value) => {
        stateUpdate.operationTypes[value.id] = value.name
      })
      res.data.video_stamp_types.forEach((value) => {
        stateUpdate.videoStampTypes[value.type] = value.name
      })
      this.setState(stateUpdate, () => {
        axios.get(`/api/v1/video_markups/${videoId}`)
          .then(this.parseOperationsAndSet)
      })
    })
    axios.get(`/api/v1/video_meta/${videoId}`).then(res => {
      this.setState({
        filename: res.data.filename,
        comment: res.data.comment,
      })
    })
    axios.get(`/api/v1/workplaces?video_id=${videoId}`).then(res => {
      this.setState({ workplaces: res.data })
    })
    axios.get(`/api/v1/employees?video_id=${videoId}`).then(res => {
      this.setState({ employees: res.data })
    })
    if (this.state.currentSecond) {
      this.getVideo().currentTime = this.state.currentSecond
    }
    this.updateOtherVideos()
    setInterval(this.updateCurrentSecond, 1000)
    this.updateLastActivities()
    this.getVideo().onpause = () => {
      this.updateOtherVideos()
    }
    this.getVideo().addEventListener("loadedmetadata", () => {
      const duration = Math.floor(this.getVideo().duration)
      axios.post(`/api/v1/set_duration?video_id=${videoId}&duration=${duration}`)
    })
  }

  parseOperationsAndSet = res => {
    let operations = []
    for (let i = 0; i < res.data.video_markups.length; i++) {
      const operation = res.data.video_markups[i]
      operations.push({
        id: operation.id,
        operation_id: operation.operation_id,
        workplaceId: operation.workplace_id,
        employeeId: operation.employee_id,
        index: operations.length,
        comment: operation.comment,
        times: [operation.start_time, operation.end_time],
      })
    }
    for (let i = 0; i < res.data.video_stamps.length; i++) {
      const stamp = res.data.video_stamps[i]
      operations.push({
        videoStampType: stamp.type.type,
        index: operations.length,
        times: [stamp.time],
        comment: stamp.comment,
        employeeId: stamp.employee_id,
      })
    }
    operations = operations.sort((o1, o2) => o1.times[0] - o2.times[0])
    console.log(operations)
    this.setOperations(operations)
  }

  setOperations = operations => {  //todo Ну что это за фигня? Надо это говно переписать что-ли...
    this.setState({ operations: [] }, () => this.setState({ operations: operations }, () => this.forceUpdate()))
  }

  onPlusButtonClick = () => {
    const operation = { operation_id: -1, index: this.state.operations.length, times: [0, 0] }
    if (this.state.workplaces.length === 1) {
      operation.workplaceId = this.state.workplaces[0].id
    }
    if (this.state.employees.length === 1) {
      operation.employeeId = this.state.employees[0].id
    }
    const operations = [...this.state.operations, operation]
    this.setState({ operations: operations, preventClose: true })
  }

  onStampButtonClick = () => {
    const stamp = { videoStampType: "note", index: this.state.operations.length, times: [this.getTime()] }
    const operations = [...this.state.operations, stamp]
    this.setState({ operations: operations })
  }

  updateOperation = (id, index, typeId, workplaceId, employeeId, times, comment) => {
    const updatedOperation = {
      id,
      index: index,
      workplaceId,
      employeeId,
      times: times,
      comment: comment,
    }
    if (times.length === 2) {
      updatedOperation.operation_id = typeId
    } else {
      updatedOperation.videoStampType = typeId
    }
    const operations = this.state.operations
    operations[index] = updatedOperation
    this.setState({ operations: operations }, () => {
      console.log(this.state.operations)
    })
  }

  getTime = () => {
    return Math.round(document.getElementById("video-player").currentTime)
  }

  prepareOperationsToUpload = () => {
    const videoMarkups = []
    const videoStamps = []
    for (const operation of this.state.operations) {
      if (operation.videoStampType) {
        videoStamps.push({
          type: operation.videoStampType,
          time: operation.times[0],
          comment: operation.comment,
          employee_id: operation.employeeId,
        })
        continue
      }
      const startTime = operation.times[0]
      const finishTime = operation.times[1]
      if (startTime >= finishTime || operation.operation_id === "-1" || !operation.workplaceId) {
        this.setState({ showAlert: true })
        return null
      }
      videoMarkups.push({
        id: operation.id,
        operation_id: operation.operation_id,
        workplace_id: operation.workplaceId,
        employee_id: operation.employeeId,
        start_time: startTime,
        end_time: finishTime,
        comment: operation.comment,
      })
    }
    return {
      video_markups: videoMarkups,
      operation_markups_to_delete: this.state.deletedOperationMarkupIds,
      video_stamps: videoStamps,
    }
  }

  autofill = () => {
    const operations = this.prepareOperationsToUpload()
    if (operations === null) {
      return
    }
    axios.post(`/api/v1/video_markups/${this.state.videoId}`, operations)
      .then(this.parseOperationsAndSet)
  }

  save = () => {
    return this.saveImpl()
      .then(() => {
        alert("Успешно сохранено!")
        this.setState({ preventClose: false })
      })
      .catch((e) => alert(`Данные введены некорректно`))
  }

  saveImpl = () => {
    const operations = this.prepareOperationsToUpload()
    if (operations === null) {
      return Promise.reject(new Error("Невалидная разметка!"))
    }
    return axios.put(`/api/v1/video_markups/${this.state.videoId}`, operations)
      .then(this.parseOperationsAndSet)
      .then(() => axios.post(`/api/v1/video_meta/${this.state.videoId}`, { comment: this.state.comment }))
      .then(() => this.setState({ showAlert: false }))
  }

  remove = (id, index) => {
    const operations = this.state.operations
    operations.splice(index, 1)
    for (let i = 0; i < operations.length; i++) {
      operations[i].index = i
    }
    const deletedOperationMarkupIds = this.state.deletedOperationMarkupIds
    if (id) {
      deletedOperationMarkupIds.push(id)
    }
    this.setState({ deletedOperationMarkupIds }, () => {
      this.setOperations(operations)
    })
  }

  cancel = () => {
    this.props.history.goBack()
  }

  finish = () => {
    this.save()
      .then(() => axios.post(`/api/v1/task/${this.state.videoId}/finish`))
      .then(this.cancel)
      .catch(e => alert(`Ошибка: ${JSON.stringify(e)}`))
  }

  setSpeed = (speed) => {
    this.getVideo().playbackRate = speed
  }

  updateOtherVideos = () => {
    const currentSecond = this.getTime()
    return axios.get(`/api/v1/other_videos?video_id=${this.state.videoId}&current_second=${currentSecond}`).then(res => {
      this.setState({
        otherVideos: res.data,
      })
      return Promise.resolve(res.data)
    })
  }

  rewind = (forward) => {
    const video = this.getVideo()
    video.currentTime = forward ? video.currentTime + 5 : video.currentTime - 5
  }

  getVideo = () => {
    return document.querySelector("video")
  }

  changeVideo = (nextVideo) => {
    return this.saveImpl()
      .then(this.updateOtherVideos)
      .then((freshVideoTimestamps) => {
        const sameVideo = freshVideoTimestamps.filter((vt) => vt.video_id === nextVideo.video_id)
        if (!sameVideo) {
          return Promise.reject(new Error("Этого видео уже нет на текущей секунде! Обновите список других видео"))
        }
        const t = sameVideo[0].current_second
        this.props.history.push(`/video/${nextVideo.video_id}/${t}`)
        window.location.reload() // TODO: kraevskiy
        return Promise.resolve()
      })
      .catch(e => alert(`Ошибка: ${e.message}`))
  }

  updateLastActivities = () => {
    return axios.get(`/api/v1/activities?video_id=${this.state.videoId}&offset=0&limit=5`).then(res => {
      this.setState({
        activities: res.data,
      })
      return Promise.resolve(res.data)
    })
  }

  updateCurrentSecond = () => {
    this.setState({ currentSecond: Math.floor(this.getVideo().currentTime) })
  }

  onShareButton = () => {
    navigator.clipboard.writeText(`labrahub.com/video/${this.state.videoId}/${this.state.currentSecond}`)
      .then(() => {
        alert("Ссылка скопирована!")
      })
      .catch(err => {
        console.log("Не получилось скопировать ссылку", err)
      })
  }

  goToOperation = (id) => {
    document.getElementById(`operation-${id}`).scrollIntoView()
  }

  getCurrentOperationList = () => {
    const currentTime = this.state.currentSecond
    const currentOperations = []

    this.state.operations.forEach((op, index) => {
      const workplace = this.state.workplaces.find(x => x.id === op.workplaceId)
      const employee = this.state.employees.find(x => x.id === op.employeeId)
      if (op.times[0] <= currentTime && currentTime < op.times[1] && workplace && employee) {
        op.index = index
        currentOperations.push(op)
      }
    })
    return currentOperations
  }

  getCurrentOperationIds = () => {
    return this.getCurrentOperationList().map((operation) => {
      return operation.id
    })
  }

  getCurrentOperations = () => {
    return this.getCurrentOperationList().map((op, index) => {
      const workplace = this.state.workplaces.find(x => x.id === op.workplaceId)
      const employee = this.state.employees.find(x => x.id === op.employeeId)
      return (
        <Button variant="link" onClick={() => this.goToOperation(op.index)}>
          {this.state.operationTypes[op.operation_id]} - {workplace.name} - {employee.name} - {formatTime(op.times[0])} - {formatTime(op.times[1])}
        </Button>)
    })
  }

  saveButtons = (location) => {
    return <div className="video-button-save" id={location}>
      <div>
        <Button variant="secondary" onClick={this.cancel}>Отменить</Button>
      </div>
      <div>
        <Button variant="info" onClick={this.autofill}>Заполнить промежутки</Button>
      </div>
      <div>
        <Button variant="primary" href={`/#/models/${this.state.videoId}`}>Настроить модель для разметки</Button>
      </div>
      <div>
        <Button variant="primary" href={`/#/models-stand-locations/${this.state.videoId}`}>Разметить стенды</Button>
      </div>
      <div>
        <Button variant="info" href={`/api/v1/video_markups/${this.state.videoId}?format=tsv`}>Скачать ФРВ
          (.tsv)</Button>
      </div>
      <div>
        <Button variant="primary" onClick={this.save}>Сохранить</Button>
      </div>
      <div>
        <Button variant="success" onClick={this.finish}>Завершить</Button>
      </div>
    </div>
  }

  render() {
    return (
      <PagesWrapper>
        <div className="video">
          <div className="video-filename">
            <h3>{this.state.filename} !</h3>
          </div>
          <div id="video-player-container">
            <video
              id="video-player"
              controls
              height="500"
            >
              <source
                src={`${axios.defaults.baseURL}/api/v1/video?video_id=${this.state.videoId}`}
                type="video/mp4"/>
            </video>
            <div>
              <p>Другие камеры: <Button variant="contained" size="sm"
                                        onClick={this.updateOtherVideos}><Refresh/></Button>
              </p>
              <div>
                {this.state.otherVideos.map((video, i) => {
                  return <Button variant="link" onClick={() => this.changeVideo(video)}>
                    {video.workplace_names.join(", ")} - {video.video_filename}
                  </Button>
                })}
              </div>
            </div>
            <div>
              <p>Последняя активность:</p>
              <Table hover bordered striped size="sm">
                <tbody>
                {this.state.activities.map((activity, index) => {
                  return <tr key={index}>
                    <td>{activity.happened_at}</td>
                    <td>{activity.author_name}</td>
                    <td>{activity.comment}</td>
                  </tr>
                })}
                </tbody>
              </Table>
            </div>
            <div id='current-operation'>
              <p>Текущие операции:</p>
              <div>
                {this.getCurrentOperations()}
              </div>
            </div>
          </div>
          {this.saveButtons("top")}
          <div className="video-player-parameters">
            <div>
              <ButtonGroup aria-label="Playback speed">
                <Button variant="secondary" onClick={() => {
                  this.setSpeed(1)
                }}>x1</Button>
                <Button variant="secondary" onClick={() => {
                  this.setSpeed(2)
                }}>x2</Button>
                <Button variant="secondary" onClick={() => {
                  this.setSpeed(4)
                }}>x4</Button>
                <Button variant="secondary" onClick={() => {
                  this.setSpeed(8)
                }}>x8</Button>
                <Button variant="secondary" onClick={() => {
                  this.setSpeed(16)
                }}>x16</Button>
              </ButtonGroup>
            </div>
            <div>
              <ButtonGroup>
                <Button variant="secondary" onClick={() => {
                  this.rewind(false)
                }}><FastRewindIcon/>5</Button>
                <Button variant="secondary" onClick={() => {
                  this.rewind(true)
                }}>5<FastForwardIcon/></Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="video-options">
            {this.state.operations.map((operation, i) => {
              let operations = this.state.operationTypes
              let dropdownColor = "success"
              let names = ["Начало", "Конец"]
              let currentOperationKey = operation.operation_id
              if (operation.videoStampType) {
                operations = this.state.videoStampTypes
                currentOperationKey = operation.videoStampType
                dropdownColor = "danger"
                names = ["Время"]
              }
              const workplaces = {}
              this.state.workplaces.forEach((w) => {
                workplaces[w.id] = w
              })
              const employees = {}
              this.state.employees.forEach((e) => {
                employees[e.id] = e
              })
              return (
                <VideoOperation id={operation.id}
                                key={i}
                                index={i}
                                types={operations}
                                workplaces={workplaces}
                                selectedWorkplaceId={operation.workplaceId}
                                employees={employees}
                                selectedEmployeeId={operation.employeeId}
                                currentOperationKey={currentOperationKey}
                                getTime={this.getTime}
                                updateOperation={this.updateOperation}
                                dropdownColor={dropdownColor}
                                times={operation.times}
                                names={names}
                                comment={operation.comment === null ? "" : operation.comment}
                                remove={this.remove}
                                current={operation.id in this.getCurrentOperationIds()}
                />
              )
            }).reverse()}
          </div>
          <div className="video-buttons">
            <Button className="video-button" variant="primary" onClick={this.onPlusButtonClick}>+</Button>
            <Button className="video-button" variant="secondary" onClick={this.onStampButtonClick}><span
              role="img" aria-label="">🚩</span></Button>
            <Button className="video-button" variant="secondary" onClick={this.onShareButton}><span
              role="img" aria-label="">📤</span></Button>
            <FormControl
              className="video-button"
              aria-label="Small"
              onChange={e => this.setState({ comment: e.target.value })}
              disabled={false}
              value={this.state.comment}
              placeholder="Комментарий..."/>
          </div>
          {this.state.showAlert === true && <div className="alert"><Alert variant="primary">
            Время начала операции должно быть меньше времени завершения и все операции, рабочие места должны
            быть выбраны!
          </Alert></div>}
          {this.saveButtons("bot")}
        </div>
      </PagesWrapper>
    )
  };
}