import React, {Component} from 'react';
import Autosuggest from 'react-autosuggest';
import './video-operation-suggestion.css';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => <span>{suggestion.name}</span>;

export default class VideoOperationSuggestion extends Component {
    constructor(props) {
        super(props);

        const data = [];
        Object.keys(this.props.types)
            .sort((k1, k2) => this.props.types[k1].localeCompare(this.props.types[k2]))
            .forEach((operationKey, i) => {
                data.push(
                    {
                        name: this.props.types[operationKey],
                        id: operationKey
                    }
                )
            });

        this.state = {
            value: this.props.types[this.props.current] || '',
            data,
            suggestions: []
        };
    }

    getSuggestions = (value) => {
        const escapedValue = escapeRegexCharacters(value.trim());

        const regex = new RegExp('^' + escapedValue, 'i');

        return this.state.data.filter(s => regex.test(s.name));
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
        this.props.onSelect(suggestion.id);
    };

    render() {
        const {value, suggestions} = this.state;

        const inputProps = {
            className: "form-control",
            placeholder: "Выберите операцию",
            value,
            onChange: this.onChange
        };

        return (
            <Autosuggest
                id={`operation-suggest-${this.props.index}`}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                alwaysRenderSuggestions={true}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    };
}