import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"
import React from "react"
import { FormGroup } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"

export const CalculatorLicenseInput = ({
                                         carPrice,
                                         handleCarPriceChange,
                                         conv,
                                         handleConvChange,
                                         markup,
                                         handleMarkupChange,
                                         service,
                                         handleServiceChange,
                                         carWorkTime,
                                       }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-paper-wrapper calculator-form-group">
        <FormGroup>
          <TextField label="Стоимость работы 1 тачки в месяц" value={carPrice} onChange={handleCarPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Конвертация (часы) за 1 смену" value={conv} onChange={handleConvChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Разметка сконвертированного (часы) за 1 смену" value={markup}
                     onChange={handleMarkupChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Обслуживание нами тачки" value={service} onChange={handleServiceChange}
                     InputLabelProps={{ shrink: true }}/>
        </FormGroup>
        <p>Время работы тачки - {carWorkTime}</p>
      </div>
    </CalculatorPaperWrapper>
  )
}