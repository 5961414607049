import React, {Component} from 'react';
import './xls-report.css';
import DatePicker, {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {forwardRef} from "react";

registerLocale('ru', ru);

const Input = forwardRef(({onChange, placeholder, value, isSecure, id, onClick}, ref) => (
    <FormControl
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={id}
        onClick={onClick}
        ref={ref}
    />
));

export default class XlsReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cameraButton: "Камера",
            cameras: [1, 2, 3, 4],
            selectedCamera: null,
            startDate: new Date(),
            selectedDate: null
        };
    }

    getReport = () => {
        if (this.state.selectedCamera == null || this.state.selectedDate == null) {
            alert("Нужно выбрать дату и камеру")
        } else {
            window.open(`/api/v1/dump?structure_id=7&camera_key=${this.state.selectedCamera}&date=${this.state.selectedDate}&format=tsv`)
        }
    };

    dropCameras = () => {
        return this.state.cameras.map((camera, i) => {
            return (
                <Dropdown.Item
                    key={i}
                    onClick={() => {
                        this.selectCamera(camera)
                    }}>{camera}</Dropdown.Item>
            )
        })
    };

    selectCamera = (id) => {
        this.setState({
            cameraButton: id.toString(),
            selectedCamera: id
        })
    };

    handleDateChange = date => {
        this.setState({
            startDate: date,
            selectedDate: this.getFormattedDate(date)
        }, () => {
        })
    };

    getFormattedDate = date => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${year}-${month}-${day}`
    };

    render() {
        return (
            <div>
                <Form inline>
                    <Form.Label column="2">
                        Отчёт
                    </Form.Label>
                    <DatePicker
                        onChange={this.handleDateChange}
                        customInput={<Input/>}
                        locale="ru"
                        placeholderText={this.state.startDate.toLocaleDateString('ru-RU')}
                    />
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                            {this.state.cameraButton}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.dropCameras()}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="outline-primary" onClick={this.getReport}>Получить</Button>
                </Form>
            </div>
        );
    };
}