export interface Structure {
  id: number
  name: string
  parentId?: number
}

export interface StructuresState {
  structures: Structure[],
  active?: number
}

export const SET_STRUCTURES = "SET_STRUCTURES"
export const CHANGE_ACTIVE_STRUCTURE = "CHANGE_ACTIVE_STRUCTURE"

interface SetStructuresAction {
  type: typeof SET_STRUCTURES,
  structures: Structure[]
}

interface ChangeActiveStructureAction {
  type: typeof CHANGE_ACTIVE_STRUCTURE,
  active?: number
}

export type StructuresActionTypes = SetStructuresAction | ChangeActiveStructureAction
