import {Reducer} from "redux";
import {LoginActionTypes, UserState} from "./types";

const initialState: UserState = {
    firstName: undefined,
    lastName: undefined,
    loggedIn: false
}

export const loginReducer: Reducer<UserState, LoginActionTypes> = (state = initialState, action: LoginActionTypes) => {
    switch (action.type) {
        case "USER_LOGIN":
            return {
                loggedIn: true,
                firstName: action.firstName,
                lastName: action.lastName
            }
        case "USER_EXIT":
            return {
                loggedIn: false
            }
        default:
            return state
    }
}