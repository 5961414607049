import React, {Component} from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import TextField from "@material-ui/core/TextField";
import {removeButton} from "../utils/buttons";
import {PagesWrapper} from "../utils/pages-wrapper"
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default class ModelsStandLocations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            videoId: this.props.match.params.videoId,
            videoMeta: null,
            stands: [],
            standLocations: [],
            selectedStand: null,
            movables: [],
            movableLocations: [],
            selectedMovable: undefined,
            x1: undefined,
            y1: undefined,
            x2: undefined,
            y2: undefined,
            useCheckpointTime: false,
            mouseDown: false,
        }
    }

    componentDidMount() {
        axios.get(`/api/v1/video_meta/${this.state.videoId}`).then(res => {
            this.setState({videoMeta: res.data})
        })
        axios.get(`/api/v1/all_stands?video_id=${this.state.videoId}`).then(res => {
            this.setState({stands: res.data})
        })
        axios.get(`/api/v1/movables?video_id=${this.state.videoId}`).then(res => {
            this.setState({movables: res.data})
        })
        this.downloadMovableLocation()
        this.downloadStandLocations()
        const canvas = this.refs.canvas
        const ctx = canvas.getContext('2d');
        const getLastMouse = (e) => {
            const boundingRect = canvas.getBoundingClientRect();
            return {
                x: parseInt(e.clientX - boundingRect.left),
                y: parseInt(e.clientY - boundingRect.top),
            }
        }
        canvas.addEventListener('mousedown', (e) => {
            const {x, y} = getLastMouse(e);
            this.setState({ x1: x, y1: y, mouseDown: true })
        });
        canvas.addEventListener('mouseup', (e) => {
            const {x, y} = getLastMouse(e);
            this.setState({ x2: x, y2: y, mouseDown: false })
        })
        canvas.addEventListener('mousemove', (e) => {
            var {x, y} = getLastMouse(e);
            x = Math.max(this.state.x1 || x, x)
            y = Math.max(this.state.y1 || y, y)
            if (this.state.mouseDown) {
                this.drawSnapshot()
                ctx.beginPath();
                ctx.rect(this.state.x1, this.state.y1, x - this.state.x1, y - this.state.y1);
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 1;
                ctx.stroke();
            }
        });
    }

    downloadMovableLocation = () => {
        axios.get(`/api/v1/movable_locations?video_id=${this.state.videoId}`).then(res => {
            this.setState({movableLocations: res.data})
        })
    }

    downloadStandLocations = () => {
        return axios.get(`/api/v1/stand_locations?video_id=${this.state.videoId}`).then(res => {
            this.setState({standLocations: res.data})
        })
    }

    drawSnapshot = () => {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext('2d');
        const video = this.refs.videoPlayer;
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        ctx.drawImage(video, 0, 0)
        for (const location of this.state.standLocations) {
            if (location.checkpoint_time != null) {
                if (Math.abs(new Date(location.checkpoint_time) - this.getCheckpointTime()) > 2000) {
                    continue
                }
            }
            ctx.rect(location.x, location.y, 1, 1);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 8;
            ctx.stroke();
            ctx.font = "20px Arial";
            const name = this.state.stands.find(stand => stand.id === location.stand_id)?.name
            ctx.fillText(name, location.x, location.y);
        }
        axios.get(`/api/v1/humans?video_id=${this.state.videoId}&second=${this.getVideoSecond()}`).then((res) => {
            for (const location of res.data) {
                const p = location.location
                ctx.fillStyle = "rgb(0,0,200)";
                ctx.fillRect(p.x - 3, p.y - 3, 6, 6);
            }
        })
    }

    addLocation = () => {
        if (!this.state.selectedStand) {
            alert('Надо выбрать стенд или базис')
            return
        }
        if (!this.state.x1 || !this.state.y1) {
            alert('Надо получить кадр и выбрать хотя бы одну точку')
            return
        }
        axios.post(`/api/v1/stand_location`, {
            stand_id: this.state.selectedStand.id,
            camera_id: this.state.videoMeta.camera_id,
            x: Math.ceil((this.state.x1 + this.state.x2) / 2),
            y: Math.ceil((this.state.y1 + this.state.y2) / 2),
            checkpoint_time: this.state.useCheckpointTime ? this.getCheckpointTime() : undefined,
        }).then(this.downloadStandLocations).then(this.drawSnapshot)
        this.clearFields()
    }

    addMovable = () => {
        if (!this.state.selectedMovable) {
            alert('Надо выбрать объект')
            return
        }
        if (!this.state.x1 || !this.state.y1 || !this.state.x2 || !this.state.y2) {
            alert('Надо получить кадр и выбрать прямоугольник')
            return
        }
        axios.post(`/api/v1/movable_location`, {
            movable_id: this.state.selectedMovable.id,
            camera_id: this.state.videoMeta.camera_id,
            x1: this.state.x1,
            y1: this.state.y1,
            x2: this.state.x2,
            y2: this.state.y2,
            checkpoint_time: this.getCheckpointTime(),
        }).then(this.downloadMovableLocation).then(this.drawSnapshot)
        this.clearFields()
    }


    getVideoSecond() {
        return document.getElementById("video-player").currentTime
    }

    getCheckpointTime() {
        const checkpointTime = new Date(this.state.videoMeta.start_time);
        checkpointTime.setSeconds(checkpointTime.getSeconds() + this.getVideoSecond());
        return checkpointTime
    }

    clearFields() {
        this.state.selectedLocationType = null
        this.state.selectedStand = null
        this.state.x1 = null
        this.state.y1 = null
        this.state.x2 = null
        this.state.y2 = null
    }

    removeLocation(i) {
        const standLocationId = this.state.standLocations[i]?.id
        if (!standLocationId) {
            alert('Этой локации нет в списке!')
            return
        }
        axios.delete(`/api/v1/stand_location/remove/${standLocationId}`).then(this.downloadStandLocations).then(this.drawSnapshot)
    }

    onLocationNameSelected(stand) {
        this.setState({selectedStand: stand})
    }

    setSpeed = (speed) => {
        document.getElementById("video-player").playbackRate = speed
    }

    getLocationNames = () => {
        const drops = [];

        this.state.stands
            .forEach((stand, i) => {
                drops.push(
                    <Dropdown.Item key={i} onClick={() => {
                        this.onLocationNameSelected(stand)
                    }}>{stand.name}</Dropdown.Item>
                )
            });
        return (drops)
    };


    getMovableNames = () => {
        return this.state.movables.map((movable, i) => {
            return (
                <Dropdown.Item key={i} onClick={() => {
                    this.setState({selectedMovable: movable})
                }}>{movable.name}</Dropdown.Item>
            )
        });
    };

    render() {
        return (
            <PagesWrapper>
                <h2>Области на видео</h2>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Имя локации</th>
                        <th>x</th>
                        <th>y</th>
                        <th>Момент, когда объект был в точке</th>
                        <th>удолить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.standLocations.map((standLocation, i) => {
                        return (
                            <tr key={i}>
                                <td>{this.state.stands.find(stand => stand.id === standLocation.stand_id)?.name || '-'}</td>
                                <td>{standLocation.x}</td>
                                <td>{standLocation.y}</td>
                                <td>{standLocation.checkpoint_time}</td>
                                <td>{removeButton(() => {
                                    this.removeLocation(i)
                                })}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Имя движимого</th>
                        <th>x1</th>
                        <th>y1</th>
                        <th>x2</th>
                        <th>y2</th>
                        <th>Момент, когда объект был в точке</th>
                        <th>удолить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.movableLocations.map((movableLocation, i) => {
                        return (
                            <tr key={i}>
                                <td>{this.state.movables.find(movable => movable.id === movableLocation.movable_id)?.name || '-'}</td>
                                <td>{movableLocation.x1}</td>
                                <td>{movableLocation.y1}</td>
                                <td>{movableLocation.x2}</td>
                                <td>{movableLocation.y2}</td>
                                <td>{movableLocation.checkpoint_time}</td>
                                <td>{removeButton(() => {
                                    this.removeLocation(i)
                                })}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <div className="choose-stands">
                    <>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {(this.state.selectedStand?.name || 'Имя локации')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.getLocationNames()}
                            </Dropdown.Menu>
                        </Dropdown>{'       '}
                    </>
                    <TextField label="x1"
                               textAlign={'center'}
                               value={this.state.x1 || ''}
                               disabled="true"
                               type="number"
                    />
                    <TextField label="y1"
                               textAlign={'center'}
                               value={this.state.y1 || ''}
                               disabled="true"
                               type="number"
                    />
                    Находится тут только в этот момент: <input
                    name="Checkpoint"
                    type="checkbox"
                    checked={this.state.useCheckpointTime}
                    onChange={e => this.setState({useCheckpointTime: e.target.checked})}/>
                    <Button
                        variant="primary"
                        onClick={this.addLocation}>
                        Добавить локацию
                    </Button>
                </div>

                <div className="choose-stands">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {(this.state.selectedMovable?.name || 'Имя объекта')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.getMovableNames()}
                        </Dropdown.Menu>
                    </Dropdown>{'       '}
                    <TextField label="x2"
                               textAlign={'center'}
                               value={this.state.x2 || ''}
                               disabled="true"
                               type="number"
                    />
                    <TextField label="y2"
                               textAlign={'center'}
                               value={this.state.y2 || ''}
                               disabled="true"
                               type="number"
                    />
                    <Button
                        variant="primary"
                        onClick={this.addMovable}>
                        Добавить движимый объект
                    </Button>
                </div>
                <video
                    id="video-player"
                    ref="videoPlayer"
                    controls
                    height="200"
                >
                    <source
                        src={`${axios.defaults.baseURL}/api/v1/video?video_id=${this.state.videoId}`}
                        type="video/mp4"/>
                </video>

                <Button
                    variant="primary"
                    onClick={this.drawSnapshot}>
                    Текущий кадр
                </Button>
                <div>
                    <ButtonGroup aria-label="Playback speed">
                        <Button variant="secondary" onClick={() => {
                            this.setSpeed(1)
                        }}>x1</Button>
                        <Button variant="secondary" onClick={() => {
                            this.setSpeed(2)
                        }}>x2</Button>
                        <Button variant="secondary" onClick={() => {
                            this.setSpeed(4)
                        }}>x4</Button>
                        <Button variant="secondary" onClick={() => {
                            this.setSpeed(8)
                        }}>x8</Button>
                        <Button variant="secondary" onClick={() => {
                            this.setSpeed(16)
                        }}>x16</Button>
                    </ButtonGroup>
                </div>
                <div id="canvas-container">
                    <canvas ref="canvas"/>
                </div>
            </PagesWrapper>
        )
    }
}