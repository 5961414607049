import React, { useEffect } from "react"
import { ProjectDataButtons } from "./project-data-buttons"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { StructuresState } from "../../store/projects/structures/types"
import { OperationState } from "../../store/projects/operations/types"
import {
  ChangeTimeAction,
  UpdateStructureAction,
} from "../../store/projects/structure-data/actions"
import { StructureDataState, TimeSelect } from "../../store/projects/structure-data/types"
import { LoadOperationsAction } from "../../store/projects/operations/actions"
import { ProjectDataOperations } from "./project-data-operations"
import { ProjectStepsParameters } from "./project-steps-parameters"
import { Shift } from "../../store/projects/project-shifts/types"
import { LoadShiftsAction } from "../../store/projects/project-shifts/actions"
import { LoadParametersAction } from "../../store/projects/project-parameters/actions"

interface RootState {
  structures: StructuresState
  operations: OperationState
  structureData: StructureDataState
}

export const ProjectsData: React.FC = () => {
  const activeId = useSelector((state: RootState) => state.structures.active)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const datesRes = await axios.get(`/api/v1/report/dates?structure_id=${activeId}`)
      const employeesRes = await axios.get(`/api/v1/employees?structure_id=${activeId}`)
      const reportDates = datesRes.data.map((date: string) => new Date(date))
      const employees = employeesRes.data
      dispatch(UpdateStructureAction(reportDates, employees))
      if (reportDates.length > 0) {
        dispatch(ChangeTimeAction(TimeSelect.from, reportDates[0]))
        dispatch(ChangeTimeAction(TimeSelect.to, reportDates[0]))
      }
    }
    fetchData()
  }, [dispatch, activeId])

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`/api/v1/operations?structure_id=${activeId}`)

      dispatch(LoadOperationsAction(result.data.operations.map((operation: any) => {
        return {
          id : operation.id,
          name: operation.name,
          type: operation.type,
          referencePoint: operation.reference_point,
        }
      }), result.data.operation_types))
    }
    fetchData()
  }, [dispatch, activeId])

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`/api/v1/structure/${activeId}`)
      const structureData = result.data
      console.log(result.data)
      const savedShifts = structureData.shifts.map((shift: any, i: number) => {
        return {
          index: i,
          name: shift.name,
          timeStart: shift.start_time,
          timeFinish: shift.end_time,
          gaps: shift.regular_break_duration_min,
        } as Shift
      })
      dispatch(LoadShiftsAction(savedShifts))
      dispatch(LoadParametersAction(
        structureData.rest_in_idle,
        structureData.time_series_stability_threshold,
        structureData.regular_break_duration,
        structureData.employee_salary,
        structureData.employee_count,
        structureData.flucts_reduction
      ))
    }
    fetchData()
  }, [dispatch, activeId])

  return (
    <div className="projects-data">
      <ProjectDataButtons/>
      <ProjectStepsParameters/>
      <ProjectDataOperations/>
    </div>
  )
}