import React, {Component} from 'react';
import './sure.css';

export default class Sure extends Component {
    render() {
        return (
            <div id="sure" className="landing-section">
                <div id="sure-content" className="landing-section-content">
                    <div id="sure-content-title">
                        <h3>Ваши сотрудники могут работать эффективнее, а<br/> ваши затраты могут стать ниже</h3>
                    </div>
                    <div id="sure-mission">
                        <h1>Наша миссия</h1>
                    </div>
                    <div id="sure-content-subtitle">
                        <h6>Что, если у вас появится инструмент, который постоянно анализирует работу сотрудников<br/>компании
                            и
                            позволяет найти оптимальные приемы осуществления операций и сократить<br/>неэффективное
                            использование
                            временных ресурсов? </h6>
                    </div>
                </div>
            </div>
        );
    };
}