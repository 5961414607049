export class StateStorage {

    CAPACITY = 10
    cur_ptr = 0
    min_ptr = 0
    max_ptr = 0

    constructor() {
        this.storage = [this.CAPACITY]
    }

    clear() {
        this.cur_ptr = 0
        this.min_ptr = 0
        this.max_ptr = 0
    }

    index = (ptr) => {
      return ((ptr % this.CAPACITY) + this.CAPACITY) % this.CAPACITY;
    }

    redo = () => {
        console.log(`redo cur=${this.cur_ptr} min=${this.min_ptr} max=${this.max_ptr}`)
        if (this.cur_ptr !== this.max_ptr) {
            this.cur_ptr = this.index(this.cur_ptr + 1)
            console.log(`after redo cur=${this.cur_ptr} min=${this.min_ptr} max=${this.max_ptr}`)
            return this.get()
        }
        return undefined
    }

    undo = () => {
        console.log(`undo cur=${this.cur_ptr} min=${this.min_ptr} max=${this.max_ptr}`)
        if (this.cur_ptr !== this.index(this.min_ptr + 1)) {
            this.cur_ptr = this.index(this.cur_ptr - 1)
            console.log(`after cur=${this.cur_ptr} min=${this.min_ptr} max=${this.max_ptr}`)
            return this.get()
        }
        return undefined
    }

    get = () => {
        console.log(`get ptr=${this.index(this.cur_ptr - 1)}`)
        return this.storage[this.index(this.cur_ptr - 1)]
    }

    set = (state) => {
        console.log(`set cur=${this.cur_ptr} min=${this.min_ptr} max=${this.max_ptr}`)

        this.storage[this.cur_ptr] = state
        this.max_ptr = this.cur_ptr = this.index(this.cur_ptr + 1)

        if (this.min_ptr === this.max_ptr) {
            this.min_ptr = this.index(this.min_ptr + 1)
        }
        console.log(`after set cur=${this.cur_ptr} min=${this.min_ptr} max=${this.max_ptr}`)
    }
}