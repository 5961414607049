import React from "react"
import ReactEcharts from "echarts-for-react"

export const Pareto = ({ data }) => {
  console.log(data)

  const getOption = () => {
    return {
      color: ["#003366", "#006699", "#4cabce", "#e5323e"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: data.bars.map(bar => bar.name),
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: data.legends,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: data.bars,
    }
  }

  return (
    <div className="report-chart pareto">
      <div className="report-chart double-diagram">
        <ReactEcharts
          option={getOption()}
          style={{ height: "800px" }}
          opts={{ renderer: "svg" }} // use svg to render the chart.
        />
      </div>
    </div>
  )
}