const initialState = {
    currentPage: 1,
    searchPattern: ""
};

export function markupReducer(state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_MARKUP_PAGE':
            return {...state, currentPage: action.payload};
        case 'CHANGE_MARKUP_PATTERN':
            return {...state, searchPattern: action.payload};
        default:
            return state
    }
}