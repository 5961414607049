import React, {Component} from 'react'

import axios from 'axios'
import $ from "jquery";

import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import './ciblock-map.css'

import 'leaflet-draw';
import "leaflet-draw/dist/leaflet.draw.css"
import 'leaflet-draw/dist/leaflet.draw-src.css';

import 'leaflet.measurecontrol/leaflet.measurecontrol';
import 'leaflet.measurecontrol/docs/leaflet.measurecontrol.css';

import 'leaflet-spin'

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import {PagesWrapper} from "../utils/pages-wrapper";
import {StateStorage} from "./state-storage";
import CiblockMenu from "./ciblock-menu";

import 'leaflet-path-transform';

export default class CiBlockMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            map: null,
            drawnItems: null,
            infoItems: null,
            storage: new StateStorage(),
            loadMode: false,
            removalMode: false,
            createMode: false,
            project: null,
            menu: false,
        }
    }

    componentDidMount() {
        this.initMap()
    }

    initMap = () => {
        let map = L.map('leafMapDiv', {measureControl: true}).setView([59.9311, 30.3609], 12);

        let Stamen_Toner = L.tileLayer('http://a.tile.openstreetmap.org/{z}/{x}/{y}.{ext}', {
            attribution: 'CiBlock map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> — CiBlock map data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            subdomains: 'abcd',
            minZoom: 0,
            maxZoom: 100,
            ext: 'png',
            crs: L.CRS.EPSG4326
        });
        Stamen_Toner.addTo(map);

        map.pm.setLang('ru')
        map.pm.setGlobalOptions({
            snappable: true,
            snapDistance: 15
        });
        map.pm.addControls({
            position: 'topleft',
            drawCircle: false,
            drawMarker: false,
            drawRectangle: false,
        });

        map.pm.disableGlobalEditMode()

        let drawnItems = new L.FeatureGroup()
        let infoItems = new L.FeatureGroup()

        map.addLayer(drawnItems)
        map.addLayer(infoItems)

        map.pm.Toolbar.changeActionsOfControl('Polygon', [
            {text: "По координатам", onClick: () => this.createAreaLayer('coords')},
            {text: "По кадастровому номеру", onClick: () => this.createAreaLayer('code')}
        ])

        map.pm.Toolbar.copyDrawControl('Polygon', {
            name: 'Zone',
            block: 'draw',
            title: 'Добавить зону',
            className: "leaflet-pm-icon-house",
            actions: [{text: "По координатам", onClick: this.createZoneLayer}]
        });

        map.pm.Toolbar.createCustomControl({
            name: "DeleteAll",
            block: "edit",
            title: "Удалить всё",
            className: "leaflet-pm-icon-bin",
            actions: [{text: "Удалить всё", onClick: this.clearAll}],
        })

        map.pm.Toolbar.createCustomControl({
            name: "Generate",
            block: "draw",
            title: "Сгенерировать дороги",
            className: "leaflet-pm-icon-generate",
            actions: [{text: "Сетка", onClick: () => this.runAlgo('road_gen_grid')},
                {text: "Области", onClick: () => this.runAlgo('road_gen_voronoi')},
                {text: "Дерево", onClick: () => this.runAlgo('road_subdivide')},
                {text: "Зоны", onClick: () => this.runAlgo('block_min_circle_cover')}]
        })

        map.pm.Toolbar.createCustomControl({
            name: "Optimise",
            block: "draw",
            title: "Оптимизировать",
            className: "leaflet-pm-icon-optimise",
            actions: [{text: "Оптимизировать тип дорог", onClick: () => this.runAlgo('road_greed_types')},
                {text: "Убрать дороги", onClick: () => this.runAlgo('road_greed_remove')}],
        })

        map.pm.Toolbar.createCustomControl({
            name: "Undo",
            block: "edit",
            title: "Назад",
            className: "leaflet-pm-icon-undo",
            onClick: this.undoState,
        })

        map.pm.Toolbar.createCustomControl({
            name: "Redo",
            block: "edit",
            title: "Вперед",
            className: "leaflet-pm-icon-redo",
            onClick: this.redoState,
        })

        map.pm.Toolbar.createCustomControl({
            name: "Ciblock menu",
            block: "custom",
            title: "Меню",
            className: "leaflet-pm-icon-menu",
            onClick: this.showMenu,
        })

        map.pm.Toolbar.setBlockPosition('custom', 'topright');

        map.on('pm:create', e => {
            console.log('create', e)
            e.layer.feature = e.layer.feature || {}
            e.layer.feature.properties = e.layer.feature.properties || {}
            e.layer.feature.type = e.layer.feature.type || "Feature"

            switch (e.shape) {
                case "Line":
                    e.layer.feature.properties = {type: 'road'}
                    break
                case "Polygon":
                    e.layer.feature.properties = {type: 'area'}
                    break
                case "Zone":
                    e.layer.feature.properties = {type: 'zone'}
                    break
                case "CircleMarker":
                    e.layer.feature.properties = {type: 'object'}
                    console.log('marker', e.layer._latlng)
                    this.showMenuPopup(e.layer, e.layer._latlng)
                    return
            }
            this.showMenuPopup(e.layer, e.layer.getCenter())
        });

        map.on('pm:globalremovalmodetoggled', e => {
            this.setState({removalMode: e.enabled})
        });

        map.on('pm:remove', (e) => {
            drawnItems.removeLayer(e.layer)
            this.saveProject()
        });

        $(document).keydown((e) => {
            if (e.which === 90 && e.ctrlKey && e.shiftKey) {
                this.redoState()
            }
            if (e.which === 90 && e.ctrlKey) {
                this.undoState()
            }
        });

        let leftInfoControl = this.addControl(map, 'Информация о проекте', 'bottomleft');
        let rightControl = this.addControl(map, 'Наведите на тексторую метку для более полной информации', 'bottomright');

        this.setState({
            map: map,
            drawnItems: drawnItems, infoItems: infoItems,
            leftControl: leftInfoControl, rightControl: rightControl
        }, () => {
            this.initProject()
        });
    }

    initProject = () => {
        this.state.map.spin(false)
        this.state.map.spin(true)
        axios.get(`/api/v1/projects/last/get`).then(res => {
                console.log(res.data)
                this.updateProject(res.data, true)
            }
        ).then(() => {
            console.log(this.state.project)
            if (!this.state.project.is_processing) {
                this.state.map.spin(false)
            }
        })
    }

    addControl = (map, default_text, position) => {
        let control = L.control({position: position})
        control.onAdd = function () {
            this._div = L.DomUtil.create('div', 'info');
            this.update();
            return this._div;
        };
        control.update = function (text) {
            this._div.innerHTML = text ? text : default_text;
        }
        control.addTo(map);
        return control
    }

    showMenu = () => {
        this.setState({menu: !this.state.menu})
        if (this.state.menu) {
            document.getElementById("menu").style.width = "240px";
            document.getElementById("map").style.marginRight = "240px";
        } else {
            document.getElementById("menu").style.width = "0";
            document.getElementById("map").style.marginRight = "0";
        }
    }

    runAlgo(algoTag) {
        console.log(`run algo ${algoTag}`)

        this.state.map.spin(true);
        axios.post(`/api/v1/projects/last/algo?algo_tag=${algoTag}`)
    }

    saveProject = () => {
        console.log(`save project`)

        this.state.map.spin(true);
        axios.post(`/api/v1/projects/last/save`, this.state.drawnItems.toGeoJSON()).then(res => {
                this.updateProject(res.data)
            }
        ).then(this.state.map.spin(false))
    }

    updateProject = (project, full = false) => {
        this.setState({
            project: project
        }, () => {
            console.log(`update project:`, this.state.project)
            this.state.storage.set(this.state.project)
            if (full) {
                this.updateView()
                this.updateDrawnItems()
            }
            this.updateInfoItems()
            this.updateGeneralInfo()
        });
    }

    undoState = () => {
        this.applyState(this.state.storage.undo())
    }

    redoState = () => {
        this.applyState(this.state.storage.redo())
    }

    applyState = (state) => {
        if (state) {
            console.log('apply state')
            axios.post(`/api/v1/projects/last/save`, state.drawing_feature_collection).then(() => {
                    this.updateProject(state, true)
                }
            )
        }
    }

    clearAll = () => {
        axios.post(`/api/v1/projects/last/clear`).then(() => this.getDraft())
    }

    showPopup = (data, pos, layer) => {
        console.log('show popup', data.meta, pos, layer)

        let popup = L.popup({minWidth: data.meta.width})
            .setLatLng(pos)
            .setContent(data.html_menu)
            .openOn(this.state.map);

        if (layer) {
            popup.on("remove", () => {
                if (!this.state.drawnItems.hasLayer(layer)) {
                    console.log('remove layer')
                    layer.remove()
                }
            });
        }
    }

    closePopup = (data, layer) => {
        this.setLayerParams(layer, this.parseProperties(layer, data.meta))

        if (!this.state.drawnItems.hasLayer(layer)) {
            console.log('add new layer')
            this.addDrawingLayer(layer)
        }

        this.state.map.closePopup()
        this.saveProject()
    }

    createAreaLayer = (type) => {
        console.log("create area by coords")

        axios.post(`/api/v1/gis/html_menu`, {type: type}).then(res => {
            this.showPopup(res.data, this.state.map.getCenter())
            console.log(res.data)
            document.getElementById("save-area-button").onclick = () => {
                let params = document.getElementById("params").value;
                params = params.trim().split('\n').map(p => p.split('\t'))

                axios.get(`/api/v1/gis/${type}?params=${params}`).then(res => {
                    console.log(res.data)
                    L.geoJSON(res.data).eachLayer((layer) => {
                        console.log(layer)
                        this.showMenuPopup(layer, this.state.map.getCenter())
                    });
                })
            }
        })
    }

    showMenuPopup = (layer, position) => {
        if (this.state.removalMode) return
        console.log(`show menu popup`)

        axios.post(`/api/v1/html_menu`, layer.feature.properties).then(res => {
            this.showPopup(res.data, position, layer)

            document.getElementById("save-button").addEventListener("click", () => {
                this.closePopup(res.data, layer)
            })
        })
    }

    parseProperties = (layer, meta) => {
        let type = layer.feature.properties.type
        console.log(`parse properties for layer ${type}`)

        switch (type) {
            case "area":
                return this.parseAreaPopupProperties(meta)
            case "road":
                return this.parseRoadPopupProperties(meta)
            case "zone":
                return this.parseZonePopupProperties(meta)
            case "object":
                return this.parseObjectPopupProperties(meta)
        }
    }

    parseAreaPopupProperties = (meta) => {
        let type_id = parseInt(document.getElementById("type_id").value)
        let style = meta.styles[type_id]
        return {
            type: 'area',
            type_id: type_id,
            style: style
        }
    }

    parseRoadPopupProperties = (meta) => {
        let type_id = parseInt(document.getElementById("type_id").value)
        let style = meta.styles[type_id]
        return {
            type: 'road',
            type_id: type_id,
            sides_count: parseInt(document.getElementById("sides_count").value),
            is_fixed: document.getElementById("is_fixed").checked,
            style: style
        }
    }

    parseZonePopupProperties = (meta) => {
        let type_id = parseInt(document.getElementById("type_id").value)
        let style = meta.styles[type_id]
        return {
            type: 'zone',
            type_id: type_id,
            capacity: parseInt(document.getElementById("capacity").value),
            is_fixed: document.getElementById("is_fixed").checked,
            style: style
        }
    }

    parseObjectPopupProperties = (meta) => {
        let type_id = parseInt(document.getElementById("type_id").value)
        let style = meta.styles[type_id]
        return {
            type: 'object',
            type_id: type_id,
            is_fixed: document.getElementById("is_fixed").checked,
            style: style
        }
    }
    setLayerParams = (layer, properties) => {
        console.log(`set params`, properties)

        layer.feature.properties = properties
        layer.setStyle(properties.style);
    }

    setLayerListeners = (layer) => {
        layer.on('click', e => this.showMenuPopup(layer, e.latlng));
        layer.on('pm:edit', () => this.saveProject());
    }

    addDrawingLayer = (layer) => {
        console.log('add drawing layer:', layer)
        this.setLayerParams(layer, layer.feature.properties)
        this.setLayerListeners(layer)
        this.state.drawnItems.addLayer(layer);
    }

    addInfoLayer = (layer) => {
        console.log('add text layer:', layer)
        let feature = layer.feature
        if (feature.properties.type === 'info') {
            const textLabel = L.marker(feature.geometry.coordinates, {
                icon: L.divIcon({className: 'text-labels', html: feature.properties.html_short}),
            });
            textLabel.on({
                mouseover: () => this.state.rightControl.update(feature.properties.html_full),
                mouseout: () => this.state.rightControl.update(),
            });
            this.state.infoItems.addLayer(textLabel);
            textLabel.editing.enable();
        }
    }

    addBlockLayer = (layer) => {
        console.log('add block layer:', layer)
        this.setLayerParams(layer, layer.feature.properties)
        this.state.infoItems.addLayer(layer);
    }

    updateDrawnItems = () => {
        console.log("set drawing items:", this.state.project.drawn_feature_collection)
        this.state.drawnItems.clearLayers();

        L.geoJSON(this.state.project.drawn_feature_collection, {
            pointToLayer: function (feature, latlng) {
                console.log(L.circleMarker(latlng))
                return L.circleMarker(latlng);
            }
        }).eachLayer((layer) => {
            this.addDrawingLayer(layer)
        });
    }

    updateInfoItems = () => {
        console.log("set info items:", this.state.project.info_feature_collection)
        this.state.infoItems.clearLayers();

        L.geoJSON(this.state.project.info_feature_collection, {
            snapIgnore: true,
            editIgnore: true,
            dragIgnore: true
        }).eachLayer((layer) => {
            if (layer.feature.properties.type === 'info') {
                this.addInfoLayer(layer)
            }
            if (layer.feature.properties.type === 'block') {
                this.addBlockLayer(layer)
            }
        });
    }

    updateGeneralInfo = () => {
        console.log("set general info:", this.state.project.general_feature_collection)

        L.geoJSON(this.state.project.general_feature_collection).eachLayer((layer) => {
            if (layer.feature.properties.type === 'draft') {
                this.state.leftControl.update(layer.feature.properties.html)
            }
        });
    }

    updateView = () => {
        console.log("set general info:", this.state.project.general_feature_collection)

        L.geoJSON(this.state.project.general_feature_collection).eachLayer((layer) => {
            if (layer.feature.properties.type === 'draft') {
                this.state.map.setView(layer.feature.geometry.coordinates, 12)
            }
        });
    }

    render() {
        return (
            <PagesWrapper>
                <div id='map' style={{width: 'auto-fill', height: '90%'}}>
                    <div id="leafMapDiv" style={{width: '100%', height: '100%'}}>leafNet</div>
                </div>
                <div id='menu' className='sidebar'>
                    <CiblockMenu initProject={this.initProject}/>
                </div>
            </PagesWrapper>
        )
    }
}