import React from "react"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

export const ReportParagraph = ({ data }) => {
  return (
    <div className="report-paragraph">
    <Paper className="report-paragraph-paper">
      <div className="report-paragraph-container">
        <Grid item xs={12} md={6}>
          <List>
            {data.sentences.map((sentence, index) => <ListItem>
              <ListItemText
                primary={sentence}
                key={index}
              />
            </ListItem>)}
          </List>
        </Grid>
      </div>
    </Paper>
  </div>)
}