import { EDIT_PARAMETERS, LOAD_PARAMETERS, ProjectParametersActionTypes } from "./types"

export function LoadParametersAction(
  restInIdle?: boolean,
  timeSeriesStabilityThreshold?: number,
  regularBreakDuration?: number,
  employeeSalary?: number,
  employeeCount?: number,
  fluctReduction?: number
): ProjectParametersActionTypes {
  return {
    type: LOAD_PARAMETERS,
    restInIdle: restInIdle,
    timeSeriesStabilityThreshold: timeSeriesStabilityThreshold,
    regularBreakDuration: regularBreakDuration,
    employeeSalary: employeeSalary,
    employeeCount: employeeCount,
    fluctReduction: fluctReduction,
  }
}

export function EditParametersAction(
  newRestInIdle?: boolean,
  newTimeSeriesStabilityThreshold?: number,
  newRegularBreakDuration?: number | null,
  newEmployeeSalary?: number,
  newEmployeeCount?: number,
  newFluctReduction?: number
): ProjectParametersActionTypes {
  const newRegularBreakDurationNotNull = newRegularBreakDuration !== null ? newRegularBreakDuration : NaN
  return {
    type: EDIT_PARAMETERS,
    newRestInIdle: newRestInIdle,
    newTimeSeriesStabilityThreshold: newTimeSeriesStabilityThreshold,
    newRegularBreakDuration: newRegularBreakDurationNotNull,
    newEmployeeSalary: newEmployeeSalary,
    newEmployeeCount: newEmployeeCount,
    newFluctReduction: newFluctReduction
  }
}