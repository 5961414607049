import React, { Component } from "react"
import "./new-project.css"
import TimeLapseInput from "../time-lapse-input"
import YandexDiscInput from "../yandex-disc-input"
import FileInput from "../file-input"
import { PagesWrapper } from "../utils/pages-wrapper"

export default class NewProject extends Component {

  state = {
    showTimelapse: true,
    showYandex: true,
    showFile: true,
  }

  onFileLoading = (option) => {
    switch (option) {
      case "timelapse":
        this.setState({ showYandex: false, showFile: false })
        break
      case "yandex":
        this.setState({ showTimelapse: false, showFile: false })
        break
      case "file":
        this.setState({ showYandex: false, showTimelapse: false })
        break
      default:
        alert("Такого способа ввода данных нет!")
    }
  }


  render() {
    return (
      <PagesWrapper>
        <div className='new-project'>
          {this.state.showTimelapse && <TimeLapseInput onFileLoading={this.onFileLoading}/>}
          {this.state.showYandex && <YandexDiscInput onFileLoading={this.onFileLoading}/>}
          {this.state.showFile && <FileInput onFileLoading={this.onFileLoading}/>}
        </div>
      </PagesWrapper>
    )
  };
}