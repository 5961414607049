import {Reducer} from "redux";
import {ProjectShiftsActionTypes, Shift, ShiftState} from "./types";

const initialState: ShiftState = {
    shifts: []
}

export const projectShiftsReducer: Reducer<ShiftState, ProjectShiftsActionTypes> = (state = initialState, action: ProjectShiftsActionTypes) => {
    switch (action.type) {
        case "LOAD_SHIFTS_DATA":
            return {
                shifts: action.shifts
            };
        case "REMOVE_SHIFT_DATA":
            const updatedShiftsRemove = JSON.parse(JSON.stringify(state.shifts))
            updatedShiftsRemove.splice(action.index, 1)
            return {
                shifts: updatedShiftsRemove
            };
        case "EDIT_SHIFTS_DATA":
            const shift: Shift = {
                index: state.shifts[action.id].index,
                gaps: action.newGaps !== undefined ? action.newGaps || 0 : state.shifts[action.id].gaps,
                name: action.newName !== undefined ? action.newName : state.shifts[action.id].name,
                timeFinish: action.newTimeFinish ? action.newTimeFinish : state.shifts[action.id].timeFinish,
                timeStart: action.newTimeStart ? action.newTimeStart : state.shifts[action.id].timeStart
            }
            const updatedShiftsUpdate = JSON.parse(JSON.stringify(state.shifts))
            updatedShiftsUpdate[action.id] = shift
            return {
                shifts: updatedShiftsUpdate
            };
        case "ADD_SHIFT_DATA":
            const shiftAdd: Shift = {
                index: -1,
                gaps: 0,
                name: "",
                timeFinish: "00:00:00",
                timeStart: "00:00:00"
            }
            const updatedShiftsAdd = JSON.parse(JSON.stringify(state.shifts))
            updatedShiftsAdd.push(shiftAdd)
            console.log(updatedShiftsAdd)
            return {
                shifts: updatedShiftsAdd
            }
        default:
            return state
    }
}