import React from "react"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"
import { getTable } from "../utils/utils"

export const CalculatorTable = ({table}) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-table calculator-form-group" id="calculator-table">
        {getTable(table)}
      </div>
    </CalculatorPaperWrapper>
  )
}