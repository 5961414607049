const initialState = {
    shifts: []
};

export function reportGeneratorReducer(state = initialState, action) {
    switch (action.type) {
        case 'EDIT_SHIFTS':
            return {...state, shifts: action.payload};
        default:
            return state
    }
}