import React, { Component } from "react"
import "./multi-video.css"
import Slider from "@material-ui/core/Slider"
import { PagesWrapper } from "../utils/pages-wrapper"
import axios from "axios";

export default class MultiVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: [833, 833, 833, 833], // props.match.params.videos todo just for prototype
      videoPlayers: [],
      minTime: new Date(2019, 12, 16, 12, 49),
      maxTime: new Date(2019, 12, 16, 12, 59),
      currentTime: 0,
    }
  }

  componentDidMount() {
    let time = 0

    const videoPlayers = this.state.videos.map((video, index) => {
      const videoStartTime = this.getVideoDate(index)

      if (videoStartTime > time) {
        time = this.getVideoDate(index)
      }

      return (
        <div className="video-player-container" key={index}>
          <video
            id={`video-player-${index}`}
            controls
            width="600"
          >
            <source
              src={`${axios.defaults.baseURL}/api/v1/video?video_id=${video}`}
              type="video/mp4"/>
          </video>
        </div>
      )
    })
    this.setState({ videoPlayers, minTime: time }, () => {
      for (let i = 0; i < videoPlayers.length; i++) {
        const video = document.getElementById(`video-player-${i}`)
        video.currentTime = (time.getTime() - this.getVideoDate(i).getTime()) / 1000
        video.addEventListener("playing", () => {
          for (let i = 0; i < videoPlayers.length; i++) {
            const video = document.getElementById(`video-player-${i}`)
            video.play()
          }
        })
        video.addEventListener("pause", () => {
          for (let i = 0; i < videoPlayers.length; i++) {
            const video = document.getElementById(`video-player-${i}`)
            video.pause()
          }
        })
      }
    })
  }

  getVideoDate = (index) => {
    return [ // todo prototype 2019-12-16 12:49:00.000000
      new Date(2019, 12, 16, 12, 49),
      new Date(2019, 12, 16, 12, 50),
      new Date(2019, 12, 16, 12, 51),
      new Date(2019, 12, 16, 12, 52)][index]
  }

  handleSlide = (event, newValue) => {
    this.setState({ currentTime: newValue }, () => {
      for (let i = 0; i < this.state.videoPlayers.length; i++) {
        const video = document.getElementById(`video-player-${i}`)
        video.currentTime = (this.state.minTime.getTime() - this.getVideoDate(i).getTime()) / 1000 + newValue
      }
    })
  }

  render() {
    return (
      <PagesWrapper>
        <div className="marking-page" id="multi-video">
          <div className="marking-page-element" id="video-players">
            {this.state.videoPlayers}
          </div>
          <div className="marking-page-element" id="controls">
            <Slider
              value={this.state.currentTime}
              onChange={this.handleSlide}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={(this.state.maxTime.getTime() - this.state.minTime.getTime()) / 1000}
            />
          </div>
        </div>
      </PagesWrapper>
    )
  };
}