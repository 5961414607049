import React from 'react';
import './landing-container.css';
import Form from "../landing-content/form";

export const LandingContainer = (props) => {

    return (
        <div id="landing">
            {props.children}
            <Form titleText={props.titleText} titleStyle={props.titleStyle}/>
        </div>
    );
}