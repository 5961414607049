import React, { Component } from "react"
import "./statement.css"
import Table from "react-bootstrap/Table"
import axios from "axios"
import Graph from "../graph"
import measurementsTop from "../img/measurements-top.png"
import measurementsBot from "../img/measurements-bot.png"
import Pareto from "../pareto"
import Formula from "../formula"
import Button from "@material-ui/core/Button"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import { PagesWrapper } from "../utils/pages-wrapper"

export default class Statement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      operations: [],
      parameters: [],
      loading: {},
      frv: [],
      measurements: [],
      allMeasurements: [],
      rep_param: {},
      tables: {},
      breaks: {},
      slices: [],
    }
  }

  componentDidMount() {
    const structureId = this.props.match.params.structureId
    const employeeId = this.props.match.params.employeeId
    const from = this.props.match.params.fromTime
    const to = this.props.match.params.toTime
    axios.get(`/api/v1/report?structure_id=${structureId}&employee_id=${employeeId}&from_time=${from}&to_time=${to}`).then(res => {
      const operations = res.data.operations
      const parameters = res.data.parameters
      const loading = res.data.loading
      const frv = res.data.frv
      const measurements = res.data.measurements
      const allMeasurements = res.data.all_measurements
      const rep_param = res.data.rep_param
      const slices = res.data.slices
      this.setState({
        operations: operations,
        parameters: parameters,
        loading: loading,
        frv: frv,
        measurements: measurements,
        allMeasurements: allMeasurements,
        rep_param: rep_param,
        breaks: res.data.breaks,
        slices: slices,
      })
      console.log(this.state.allMeasurements)
    })
  }

  formatTime = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length)
  }

  getTableSupervise = () => {
    const rows = []

    for (let index = 0; index < this.state.operations.length; index++) {
      let color = "black"
      const operation = this.state.operations[index]
      if (operation.highlight === true) {
        color = "red"
      }
      const row = <tr style={{ color: color }} key={index}>
        <td style={{ verticalAlign: "middle" }}>{operation.id}</td>
        <td style={{ verticalAlign: "middle" }}>{operation.name}</td>
        <td style={{
          verticalAlign: "middle",
          textAlign: "center",
        }}>{this.formatTime(Math.floor(operation.len / 60), "0", 2)}:{this.formatTime(operation.len % 60, "0", 2)}</td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{operation.standard_len}</td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{operation.coef}</td>
      </tr>
      rows.push(row)
    }

    return (
      <div className="statement-operation-table page">
        <h2>Таблица 5. Обзор операций</h2>
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>№</th>
            <th>Название</th>
            <th>Длительность</th>
            <th>Cреднее / Второе после min</th>
            <th>Коэффициент</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </div>
    )
  }

  getBreakTable = () => {
    const rows = []
    for (const s of this.state.breaks) {
      const row = <tr>
        <td style={{ verticalAlign: "middle" }}>{s.type}</td>
        <td style={{ verticalAlign: "middle" }}>{s.start_datetime}</td>
        <td style={{ verticalAlign: "middle" }}>{s.end_datetime}</td>
      </tr>
      rows.push(row)
    }
    if (rows.length === 0) {
      return null
    }
    return (
      <div className="statement-operation-table page">
        <h2>Таблица 6. Регламентные перерывы и гэпы</h2>
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>Тип перерыва</th>
            <th>Начало</th>
            <th>Конец</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </div>
    )
  }

  getTableAnalysis = () => {
    const indexWithTime = {}

    this.state.parameters.forEach((parameter) => {
      if (parameter.index in indexWithTime) {
        indexWithTime[parameter.index].percent += parameter.percent_video
        indexWithTime[parameter.index].len += parameter.len
      } else {
        indexWithTime[parameter.index] = { percent: parameter.percent_video, len: parameter.len }
      }
    })

    const formatLen = (len) => {
      return `${this.formatTime(Math.floor(len / 60), "0", len / 60 >= 100 ? 3 : 2)}'${this.formatTime(len % 60, "0", 2)}`
    }

    return this.state.parameters.map((parameter, index) => {
      return (
        <tr key={index}>
          {this.getType(this.state.parameters.map((parameter) => parameter.index), index, `${parameter.index} - ${formatLen(indexWithTime[parameter.index].len)} - ${indexWithTime[parameter.index].percent.toFixed(2)}%`)}
          <td style={{ verticalAlign: "middle" }}>{parameter.name}</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{parameter.percent_video}%</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatTime(Math.floor(parameter.len / 60), "0", parameter.len / 60 >= 100 ? 3 : 2)}'{this.formatTime(parameter.len % 60, "0", 2)}</td>
          {/*<td style={{verticalAlign: "middle", textAlign: "center"}}>{parameter.percent_day}%</td>*/}
          {/*<td style={{verticalAlign: "middle", textAlign: "center"}}>{parameter.percent_w_day}%</td>*/}
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{parameter.standard_len}</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{parameter.coef}</td>
          {/*<td>{`Сравнение с ранее накопленными данными.`}</td>*/}
        </tr>
      )
    })
  }

  getTableSum = () => {
    const output = {
      percent_video: 0,
      len: 0,
      percent_day: 0,
      percent_w_day: 0,
    }

    this.state.parameters.forEach((parameter) => {
      output["percent_video"] += parameter.percent_video
      output["len"] += parameter.len
      output["percent_day"] += parameter.percent_day
      output["percent_w_day"] += parameter.percent_w_day
    })

    let counter = 2

    if (output.len / 60 >= 100) {
      counter = 3
    }
    if (output.len / 60 >= 1000) {
      counter = 4
    }

    return (
      <tr>
        <td>Сумма:</td>
        <td/>
        <td style={{
          verticalAlign: "middle",
          textAlign: "center",
        }}>{Math.round(output.percent_video)}%
        </td>
        <td style={{
          verticalAlign: "middle",
          textAlign: "center",
        }}>{this.formatTime(Math.floor(output.len / 60), "0", counter)}'{this.formatTime(output.len % 60, "0", 2)}</td>
        {/*<td style={{verticalAlign: "middle", textAlign: "center"}}>{output.percent_day.toFixed(2)}%</td>*/}
        {/*<td style={{verticalAlign: "middle", textAlign: "center"}}>{Math.round(output.percent_w_day)}%</td>*/}
        <td/>
        <td/>
      </tr>
    )
  }

  getTableFrv = () => {
    const rows = []

    for (let index = 0; index < this.state.frv.length; index++) {
      const row =
        <tr key={index}>
          <td style={{ verticalAlign: "middle" }}>{this.state.frv[index].id}</td>
          <td style={{ verticalAlign: "middle" }}>{this.state.frv[index].name}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.state.frv[index].start_datetime}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.state.frv[index].finish_datetime}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.state.frv[index].len_interval}</td>
          <td style={{ verticalAlign: "middle" }}>{this.state.frv[index].index}</td>
        </tr>
      rows.push(row)
    }


    return (
      <div className="statement-frv page">
        <h2>Таблица 4. Фотография рабочего дня/смены</h2>
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>№</th>
            <th>Наименование</th>
            <th>Время начала</th>
            <th>Время окончания</th>
            <th>Длительность</th>
            <th>Индекс</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
        <b>Коэффициенты устойчивости хронометражного ряда соответствуют необходимым требованиям, и их
          значение ниже табличного, поэтому нормативные показатели могут считаться
          устойчивыми.</b>
      </div>
    )
  }

  getOverload = () => {
    let type = "Сотрудник перерабатывает на"
    if (this.state.loading.overload > 0) {
      type = "Необходимо в первую очередь рассмотреть возможность оптимизации использования"
    }
    return (
      <b>{type} {(100 - this.state.loading.percentage).toFixed(2)}% рабочего времени, что позволит произвести
        на
        10 изделий больше за одну рабочую смену.<br/>
        {!this.state.rep_param.structure.partial &&
        <p>
          Была выявлена переработка от длительности
          смены {this.formatQuote(this.state.loading.overworking, 2)} ({this.state.loading.overworking_per}%).
        </p>}
      </b>
    )
  }

  getType = (types, index, type) => {
    const result = []
    for (let i = 0; i < types.length; i++) {
      if (i === 0 || types[i - 1] !== types[i]) {
        let cnt = 1
        for (let j = i + 1; j < types.length && types[j - 1] === types[j]; j++) {
          cnt += 1
        }
        result.push(cnt)
      } else {
        result.push(null)
      }
    }
    if (result[index]) {
      return <td rowSpan={result[index]} style={{ verticalAlign: "middle" }}>{type}</td>
    }
  }

  getTableMeasurements = (measurements, range) => {
    return measurements.rows.map((row, index) => {
      const operation = row.operation
      return (
        <tr key={index}>
          <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
          {this.getType(measurements.rows.map((row) => row.operation.type), index, operation.type)}
          <td style={{ verticalAlign: "middle" }}>{operation.name}</td>
          {
            range.map((i) => {
              const mesaurment = row.measurements[i]
              return <td style={{
                verticalAlign: "middle",
                textAlign: "center",
              }}>{mesaurment && this.formatQuote(mesaurment.length)}</td>
            }).slice(0, 10)
          }
          <td>...</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{this.formatQuote(row.t_min)}</td>
          {/*<td>{data.num_reg}</td>*/}
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{this.formatQuote(row.t_reg)}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatQuote(row.t_freq_pred)}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatQuote(row.t_freq_fact)}</td>
        </tr>
      )
    })
  }

  getBottomTableMeasurements = (measurements) => {
    return measurements.rows.map((row, index) => {
      return (
        <tr key={index}>
          <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
          {this.getType(measurements.rows.map((row) => row.operation.type), index, row.operation.type)}
          <td style={{ verticalAlign: "middle" }}>{row.operation.name}</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{this.formatQuote(row.t_max)}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatQuote(row.t_average)}</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{this.formatQuote(row.t_min)}</td>
          <td style={{ verticalAlign: "middle", textAlign: "center" }}>{this.formatQuote(row.par2)}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatQuote(row.fluct_max)}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatQuote(row.fluct_average)}</td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "center",
          }}>{this.formatQuote(row.fluct_min)}</td>
        </tr>)
    })
  }

  formatQuote = (param, symb) => {
    if (typeof (symb) === "undefined") symb = 2
    return this.formatTime(Math.floor(param / 60), "0", symb) + "'" + this.formatTime(param % 60, "0", 2)
  }

  render() {
    const normComparation = this.state.operations.map((operation) => {
      return (
        {
          id: operation.id,
          len: operation.len,
          ave_len: operation.ave_len,
        }
      )
    })

    const indexComparation = this.state.parameters.map((parameter) => {
      return (
        {
          id: parameter.name,
          len: parameter.len,
        }
      )
    })

    const aveComparation = this.state.parameters.map((parameter) => {
      return (
        {
          id: parameter.name,
          ave_len: parameter.ave_len,
          time_2min: parameter.time_2min,
        }
      )
    })

    const measurementsComparation = this.state.parameters.map((parameter) => {
      return (
        {
          id: parameter.name,
          fluct_ave: parameter.fluct_ave,
          t_min: parameter.t_min,
        }
      )
    })

    if (!this.state.operations.length)
      return null

    let range = []
    for (let i = 0; i < this.state.measurements.max_operation_count; i++) {
      range.push(i)
    }

    let allRange = []
    for (let i = 0; i < this.state.allMeasurements.max_operation_count; i++) {
      allRange.push(i)
    }

    return (
      <PagesWrapper>
        <div className="statement" id="capture">
          <div className="page pdf-split" id="p1">
            <div className="pdf-button" align="right" style={{
              position: "absolute",
              float: "right",
              width: "97%",
              "z-index": "1",
            }}>
              <Button variant="contained"
                      color="primary"
                      href={`/#/statement/${this.props.match.params.structureId}/${this.props.match.params.employeeId}/${this.props.match.params.fromTime}/${this.props.match.params.toTime}/pdf`}
                      target="_blank"
                      style={{
                        backgroundColor: "#ff005c",
                        fontFamily: "Montserrat, sans-serif",
                        fontWeight: "bolder",
                      }}
                      startIcon={<PictureAsPdfIcon/>}>
                PDF
              </Button>
            </div>
            <div className="statement-header">
              <h1>Отчет о наблюдениях
                с {this.state.rep_param.from_time} по {this.state.rep_param.to_time}</h1>
            </div>
            <div className="statement-data">
              <h5>Наблюдения проводились на рабочем
                месте {this.state.rep_param.structure.name} с {this.state.rep_param.structure.shift_start_time} до {this.state.rep_param.structure.shift_end_time} по
                сотруднику "{this.state.rep_param.worker_name}".
                {/*<br/> За время рабочей*/}
                {/*смены сотрудниками было*/}
                {/*произведено {this.state.rep_param.product_num} единиц {this.state.rep_param.products_description}*/}
              </h5>
            </div>
            <div className="statement-percentage">
              <h2>Таблица 1. Анализ основных показателей (в минутах и в процентах)</h2>
              <p>Подготовительно-заключительное время и время вспомогательной работы относятся к
                периодической
                работе.</p>
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th style={{ verticalAlign: "middle" }}>Индекс</th>
                  <th style={{ verticalAlign: "middle" }}>Операция</th>
                  <th style={{ verticalAlign: "middle" }}>Доля в длительности замера</th>
                  <th style={{ verticalAlign: "middle" }}>Длительность</th>
                  {/*<th style={{verticalAlign: "middle"}}>Доля в длительности рабочего дня/смены (с 7:00 до*/}
                  {/*    23:00)*/}
                  {/*</th>*/}
                  {/*<th style={{verticalAlign: "middle"}}>Доля в длительности<br/> рабочего*/}
                  {/*    дня/смены*/}
                  {/*</th>*/}
                  <th style={{ verticalAlign: "middle" }}>Cреднее / Второе после min</th>
                  <th style={{ verticalAlign: "middle" }}>Коэффициент устойчивости хронометражного
                    ряда
                  </th>
                  {/*<th>Отклонение</th>*/}
                </tr>
                </thead>
                <tbody>
                {this.getTableAnalysis()}
                {this.getTableSum()}
                </tbody>
              </Table>
            </div>
            <div className="statement-overloading">
              <h2>Наличие переработок/недоработок</h2>
              {/*{this.getOverload()}*/}
              {!this.state.rep_param.structure.partial &&
              <p>После каждого часа работы перерыв составляет 10 мин, по этой причине время
                регламентированных
                перерывов составило 70 мин.</p>
              }
              <hr/>
            </div>
            <div className="statement-report">
              <h5>Длительность замера в смену
                составила {this.formatQuote(this.state.loading.all_time, 4)}.<br/>
                Длительность оперативного времени (основной работы) за смену / рабочий день
                составила {this.formatQuote(this.state.loading.main_work_time.value, 3)} ({this.state.loading.main_work_time.percent}%),<br/>
                вспомогательной
                работы
                составила {this.formatQuote(this.state.loading.add_work_time.value, 3)} ({this.state.loading.add_work_time.percent}%),<br/>
                времени контроля
                составила {this.formatQuote(this.state.loading.controltime.value, 3)} ({this.state.loading.controltime.percent}%),<br/> подготовительно-заключительного
                времени
                составила {this.formatQuote(this.state.loading.pz_time.value, 3)} ({this.state.loading.pz_time.percent}%),<br/>
                времени
                на
                отдых и личные надобности
                составила {this.formatQuote(this.state.loading.rest_time.value, 3)} ({this.state.loading.rest_time.percent}%),<br/>
                {/*времени отсутствия на рабочем месте*/}
                {/*составила {this.formatQuote(this.state.loading.stoptime)} ({this.state.loading.stoptime_per}%)<br/>*/}
                времени ожидания на рабочем месте
                составила {this.formatQuote(this.state.loading.movetime.value, 3)} ({this.state.loading.movetime.percent}%),
                <br/>
                загруженность станка составила {this.state.loading.behind_machine.percent}%.<br/>
                <br/>в
                сравнении с нормативным показателем
                ОТЛ
                для данной должности*.
              </h5>
              <br/>
              <h6>* Норматив времени на отдых и личные надобности устанавливаетс в зависимости от
                длительности
                рабочего дня/смены, условий труда и вредности, которая зафиксирована на каждом
                конкретном
                рабочем месте</h6>
            </div>
          </div>
          <div className="statement-graph">
            <Graph param={this.state.parameters} norm={normComparation} index={indexComparation}
                   ave={aveComparation} measurements={measurementsComparation} slilces={this.state.slices}/>
          </div>
          <div className="statement-measurements page">
            <div className="pdf-split">
              <h2>Таблица 2. Лист наблюдений</h2>
              {/*<p>{this.state.rep_param.structure.name}<br/>*/}
              {/*    Дата замера: {this.state.rep_param.date} <br/>*/}
              {/*    Рабочее место:</p>*/}
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>№</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Тип</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Рабочий элемент</th>
                  <th colSpan={`${Math.min(this.state.measurements.max_operation_count, 11)}`}
                      style={{ verticalAlign: "middle" }}>Замеры
                  </th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>t мин</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>t отр.</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Колеб. (предв.)</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Колеб. (факт.)</th>
                </tr>
                <tr>
                  {
                    range.map((i) => {
                      return (
                        <td>{i + 1}</td>
                      )
                    }).slice(0, 10)
                  }
                  <td>
                    ...
                  </td>
                </tr>
                </thead>
                <tbody>
                {this.getTableMeasurements(this.state.measurements, range)}
                </tbody>
              </Table>
            </div>
            <div className="pdf-split">
              <h4>Продолжение таблицы 2 (дополнительные расчеты)</h4>
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th style={{ verticalAlign: "middle" }}>№</th>
                  <th style={{ verticalAlign: "middle" }}>Тип</th>
                  <th style={{ verticalAlign: "middle" }}>Рабочий элемент</th>
                  <th style={{ verticalAlign: "middle" }}>Максимальное значение</th>
                  <th style={{ verticalAlign: "middle" }}>Среднее значение</th>
                  <th style={{ verticalAlign: "middle" }}>Минимальное значение</th>
                  <th style={{ verticalAlign: "middle" }}>Показатель 2</th>
                  <th style={{ verticalAlign: "middle" }}>Колебание (максимальное):</th>
                  <th style={{ verticalAlign: "middle" }}>Колебание (среднее):</th>
                  <th style={{ verticalAlign: "middle" }}>Колебание (минимальное):</th>
                </tr>
                </thead>
                <tbody>
                {this.getBottomTableMeasurements(this.state.measurements)}
                </tbody>
              </Table>
              {!this.state.rep_param.structure.partial &&
              <b>Оптимальная длительность операций «Правка круга» и «Замена диска» были рассчитаны, как сумма
                значений длительностей каждой из операций и делением их на количество произведенных изделий,
                а
                именно на 20. Таким образом были получены следующие значения: 00’’12 и 00’’34, которые
                закладываются в оптимальную длительность каждой операции.
                <br/>
                Для расчета времени такта была использована фактическая длительность рабочего дня, а именно
                461
                минута, так как сотрудник работал на рабочем месте с 7:00 до 14:41, что на 11 минут дольше
                плановой длительности рабочего дня.
                <br/>
              </b>
              }
            </div>
          </div>
          <div className="statement-all-measurements page">
            <div className="pdf-split">

              <h2>Таблица 3. Лист наблюдений (по всем)</h2>
              {/*<p>{this.state.rep_param.structure.name}<br/>*/}
              {/*    Дата замера: {this.state.rep_param.date} <br/>*/}
              {/*</p>*/}
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>№</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Тип</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Рабочий элемент</th>
                  <th colSpan={`${Math.min(this.state.allMeasurements.max_operation_count, 11)}`}
                      style={{ verticalAlign: "middle" }}>Замеры
                  </th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>t мин</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>t отр.</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Колеб. (предв.)</th>
                  <th rowSpan="2" style={{ verticalAlign: "middle" }}>Колеб. (факт.)</th>
                </tr>
                <tr>
                  {
                    allRange.map((i) => {
                      return (
                        <td>{i + 1}</td>
                      )
                    }).slice(0, 10)
                  }
                  <td>...</td>
                </tr>
                </thead>
                <tbody>
                {this.getTableMeasurements(this.state.allMeasurements, allRange)}
                </tbody>
              </Table>
            </div>
            <div className="pdf-split">

              <h4>Продолжение таблицы 3 (дополнительные расчеты)</h4>
              <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th style={{ verticalAlign: "middle" }}>№</th>
                  <th style={{ verticalAlign: "middle" }}>Тип</th>
                  <th style={{ verticalAlign: "middle" }}>Рабочий элемент</th>
                  <th style={{ verticalAlign: "middle" }}>Максимальное значение</th>
                  <th style={{ verticalAlign: "middle" }}>Среднее значение</th>
                  <th style={{ verticalAlign: "middle" }}>Минимальное значение</th>
                  <th style={{ verticalAlign: "middle" }}>Показатель 2</th>
                  <th style={{ verticalAlign: "middle" }}>Колебание (максимальное):</th>
                  <th style={{ verticalAlign: "middle" }}>Колебание (среднее):</th>
                  <th style={{ verticalAlign: "middle" }}>Колебание (минимальное):</th>
                </tr>
                </thead>
                <tbody>
                {this.getBottomTableMeasurements(this.state.allMeasurements)}
                </tbody>
              </Table>
              {!this.state.rep_param.structure.partial &&
              <b>Оптимальная длительность операций «Правка круга» и «Замена диска» были рассчитаны, как сумма
                значений длительностей каждой из операций и делением их на количество произведенных изделий,
                а
                именно на 20. Таким образом были получены следующие значения: 00’’12 и 00’’34, которые
                закладываются в оптимальную длительность каждой операции.
                <br/>
                Для расчета времени такта была использована фактическая длительность рабочего дня, а именно
                461
                минута, так как сотрудник работал на рабочем месте с 7:00 до 14:41, что на 11 минут дольше
                плановой длительности рабочего дня.
                <br/>
              </b>
              }
              {this.state.rep_param.product_num ?
                [<Formula rep_param={this.state.rep_param} loading={this.state.loading}/>,
                  <b>
                    <br/>
                    Всего за {this.state.rep_param.date} сотрудниками было
                    выполнено {this.state.rep_param.product_num} единиц {this.state.rep_param.products_description}.
                    Ниже представлена фотография рабочего
                    дня.
                  </b>]
                : null}
            </div>
          </div>

          <div id="mes-img" className="page">
            {!this.state.rep_param.structure.partial &&
            <div>
              <h2>Диаграмма 2.1. Таблица сбалансированной работы (мин.)</h2>
              <img src={measurementsTop} alt="measurements"/>
              <h2>Диаграмма 2.2. Таблица сбалансированной работы (%)</h2>
              <img src={measurementsBot} alt="measurements"/>
            </div>
            }
            <Pareto measurements={this.state.measurements}/>
          </div>
          {this.getTableFrv()}
          {/*{this.getTableSupervise()}*/}
          {/*{this.getBreakTable()}*/}
        </div>
      </PagesWrapper>
    )
  };
}