import React, {Component} from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import './graph.css';

Highcharts.setOptions({
    chart: {
        style: {
            fontFamily: 'Montserrat'
        }
    }
});

export default class Graph extends Component {
    getPieOptions = (name, title, subtitle, data) => {
        return ({
            chart: {
                marginLeft: 50,
                marginRight: 50,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: 800,
                height: 800
            },
            title: {
                text: title,
                style: {
                    fontSize: "20px",
                    fontWeight: "bold"
                },
            },
            subtitle: {
                text: subtitle
            },
            legend: {
                itemStyle: {
                    width: 280
                },
                width: 600,
                itemWidth: 300,
            },
            tooltip: {
                shared: true,
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            width: '80px'
                        },
                        padding: 0
                    },
                    size: "400px"
                }
            },
            series: [{
                name: name,
                colorByPoint: true,
                data: data,
                showInLegend: true,
            }]
        })
    };

    render() {
        const dummyColors = {
            "Простой": "#cf271b",
            "Оперативное время (основное)": "#2B8032",
            "Контроль работы оборудования": "#3CE8DA",
            "Время вспомогательной работы": "#EBDA94",
            "Подготовительно-заключительное время": "#557FEB"
        };
        const categoriesPieData = [];
        this.props.param.forEach((parameter) => {
            if (parameter.index !== "Время на отдых и личные надобности") {
                if (categoriesPieData.findIndex(x => x['name'] === parameter.index) === -1) {
                    categoriesPieData.push({
                        name: parameter.index,
                        y: parameter.percent_video,
                        color: dummyColors[parameter.index]
                    })
                } else {
                    categoriesPieData[categoriesPieData.findIndex(x => x['name'] === parameter.index)]['y'] += parameter.percent_video
                }
            }
        });
        const categoriesOptions = this.getPieOptions('Категории', 'Диаграмма № 1. Доступное время', "Доступное время рассчитывается как длительность смены за вычетом времени на отдых и личные надобности (регламентированные перерывы) и обед", categoriesPieData);

        const pieData = {};

        const colors = ["#268051", "#99FFCA", "#4DFFA3", "#4D8065", "#3DCC82", "#1B6962", "#3CE8DA", "#2CA89E", "#496ECC", "#A8C1FF", "#5C8AFF", "#546080", "#fff3ad", "#CF271B", "#F52E20", "#69140E", "#EBDA94", "#EBDA94"];

        this.props.param.forEach((parameter, index) => {
            if (!(parameter.operation_type in pieData)) {
                pieData[parameter.operation_type] = []
            }
            pieData[parameter.operation_type].push({
                index: parameter.index,
                name: parameter.name,
                y: parameter.percent_video,
                color: colors[index]
            });
        });

        const operationsOptions = Object.values(pieData).filter(data => data.length > 1).map((data, index) => {
            return this.getPieOptions('Операции', `Диаграмма № 1.${index + 1}.<br/> Распределение операций в категории <br/>«${data[0].index}» в смену`, '', data)
        });

        const categories = [];
        this.props.param.forEach((parameter) => {
            if (categories.indexOf(parameter.index) === -1) {
                categories.push(parameter.index)
            }
        });
        let legendIndex = 100;
        const dataColors = ['#899ba5', '#0098DB', '#31c0c2', '#002244']; //todo FIX, possible out of range exception in the future
        const dataCat = [];
        categories.forEach((category) => {
            const subCategories = [];
            let y = 0;
            const subData = [];

            this.props.param.forEach((parameter) => {
                if (parameter.index === category) {
                    y += parameter.percent_video;
                    subData.push(parameter.percent_video);
                    subCategories.push(parameter.name)
                }
            });

            dataCat.push({
                y: y,
                legendIndex: legendIndex,
                drilldown: {
                    name: category,
                    categories: subCategories,
                    data: subData
                }
            });
            legendIndex += 100;
        });
        const operationTypeData = [];
        const operationData = [];
        dataCat.forEach((category, index) => {
            operationTypeData.push({
                name: categories[index],
                y: category.y,
                legendIndex: category.legendIndex,
                color: dataColors[index]
            });

            for (let i = 0; i < category.drilldown.data.length; i++) {
                const brightness = 0.2 - (i / category.drilldown.data.length) / 5;
                operationData.push({
                    name: category.drilldown.categories[i],
                    y: category.drilldown.data[i],
                    legendIndex: category.legendIndex + i + 1,
                    color: Highcharts.Color(dataColors[index]).brighten(brightness).get()
                })
            }
        });

        const machinePieData = [];
        const slices = this.props.slilces[0].values;
        Object.keys(slices).forEach(function (key, _) {
            machinePieData.push({
                name: key,
                y: slices[key]
            })
        });

        const machineOptions = this.getPieOptions('Категории', 'Диаграмма № 2.2. Загрузка станка', null, machinePieData);

        const donutOptions = {
            chart: {
                marginLeft: 50,
                marginRight: 50,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: 800,
                height: 800
            },
            title: {
                text: 'Диаграмма № 2.1. <br/>Графическое отображение операций',
                style: {
                    fontSize: "20px",
                    fontWeight: "bold"
                }
            },
            legend: {
                itemStyle: {
                    width: 280
                },
                width: 600,
                itemWidth: 300,
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%']
                }
            },
            tooltip: {
                shared: true,
                valueSuffix: '%',
                style: {
                    width: 'auto'
                }
            },
            series: [{
                name: 'Виды операций',
                data: operationTypeData,
                size: '60%',
                innerSize: '40%',
                showInLegend: true,
                dataLabels: {
                    formatter: function () {
                        return this.y > 4 ? this.point.name : null;
                    },
                    color: '#ffffff',
                    distance: -20,
                    style: {
                        width: '50px'
                    }
                }
            }, {
                name: 'Операции',
                data: operationData,
                size: '80%',
                innerSize: '60%',
                showInLegend: true,
                dataLabels: {
                    formatter: function () {
                        // display only if larger than 1
                        return this.y > 0.01 ? '<b>' + this.point.name + ':</b> ' + this.y + '%' : null;
                    },
                    style: {
                        width: '50px'
                    }
                }
            }],
        };

        const measurments_index = []; //todo get from xls
        const fluct_ave = [];
        const t_min = [];

        this.props.measurements.forEach((data) => {
            measurments_index.push(data.id);
            fluct_ave.push(data.fluct_ave);
            t_min.push(data.t_min);
        });

        return (
            <div className="graph">
                <div className="pdf-split page">
                    <div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={categoriesOptions}
                        />
                    </div>
                </div>
                {
                    operationsOptions.map((option) => {
                        return <div className="pdf-split page">
                            <div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={option}
                                />
                            </div>
                        </div>
                    })
                }
                <div className="pdf-split page">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={donutOptions}
                    />
                </div>
                <div className="pdf-split page">
                    <div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={machineOptions}
                        />
                    </div>
                </div>
            </div>
        );
    }
    ;
}