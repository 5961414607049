import React, {Component} from "react"
import "./new-word.css"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"

export default class NewWord extends Component {
    render() {
        return (
            <div id="new-word" className="landing-section">
                <div id="new-word-content">
                    <div className="new-word-content">
                        <h1>ПОВЫШЕНИЕ ПРОИЗВОДИТЕЛЬНОСТИ ТРУДА С AI-ИНСТРУМЕНТОМ</h1>
                    </div>
                    <div className="new-word-content">
                        <h2>Мы поможем вам приспособиться к новым условиям рынка.
                            <b id="description-content-text-colored"> LABRA</b> берет лучшее от проверенных годами
                            методик и зарекомендовавших себя современных HR-технологий
                        </h2>
                    </div>
                    <div id="new-word-buttons">
                        <Button style={{
                            backgroundColor: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                            marginTop: "20px",
                            color: "white",
                        }} href={`https://www.labrahub.ru/files/LABRA_RU.pdf`}>Скачать буклет</Button>
                        <Button style={{
                            backgroundColor: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                            marginTop: "20px",
                            color: "white",
                        }} href={`https://www.labrahub.ru/files/LABRA_ENG.pdf`}>Get booklet</Button>
                    </div>
                </div>
            </div>
        )
    };
}