import React, { useEffect } from "react"
import {
  Card,
  CardContent, Fab, FormControlLabel, FormGroup,
  FormHelperText,
  FormLabel, Switch, TextField,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useDispatch, useSelector } from "react-redux"
import { StructuresState } from "../../store/projects/structures/types"
import { StructureDataState } from "../../store/projects/structure-data/types"
import { parseSecondsFromMinutes, toMinutesString } from "../utils/utils"
import { ProjectStepsParametersShift } from "./project-steps-parameters-shift"
import { ShiftState } from "../../store/projects/project-shifts/types"
import { AddShiftAction } from "../../store/projects/project-shifts/actions"
import axios from "axios"
import { ParametersState } from "../../store/projects/project-parameters/types"
import { EditParametersAction } from "../../store/projects/project-parameters/actions"

interface RootState {
  structures: StructuresState
  structureData: StructureDataState
  projectShifts: ShiftState
  projectParameters: ParametersState
}

export const ProjectStepsParameters: React.FC = () => {
  const dispatch = useDispatch()
  const activeId = useSelector((state: RootState) => state.structures.active)
  const shifts = useSelector((state: RootState) => state.projectShifts.shifts)
  const restInIdle = useSelector((state: RootState) => state.projectParameters.restInIdle)
  const timeSeriesStabilityThreshold = useSelector((state: RootState) => state.projectParameters.timeSeriesStabilityThreshold)
  const regularBreakDuration = useSelector((state: RootState) => state.projectParameters.regularBreakDuration)
  const employeeSalary = useSelector((state: RootState) => state.projectParameters.employeeSalary)
  const employeeCount = useSelector((state: RootState) => state.projectParameters.employeeCount)
  const fluctReduction = useSelector((state: RootState) => state.projectParameters.fluctReduction)

  // const [employeeCount, setEmployeeCount] = React.useState("")
  // const [employeeCountPlan, setEmployeeCountPlan] = React.useState("")
  // const [fluct, setFluct] = React.useState("")
  // const [searchCosts, setSearchCosts] = React.useState("")
  // const [considerExpencesSearch, setConsiderExpencesSearch] = React.useState(false)
  // const [considerExpencesExtra, setConsiderExpencesExtra] = React.useState(false)

  return (
    <div className="projects-steps-parameters">
      <div className="projects-steps-parameters-content">
        <div className="projects-steps-parameters-general">
          <Card className="projects-steps-parameters-card">
            <CardContent>
              <FormLabel>Параметры отчета</FormLabel>
              <FormHelperText>ID структуры — {activeId}</FormHelperText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={restInIdle}
                      onChange={e => dispatch(EditParametersAction(e.target.checked))}
                    />}
                  label={`Включать время на ОТЛ в категорию "Простои"`}
                />
                <TextField
                  id="standard-basic"
                  label="Коэффициент устойчивости хронометражного ряда"
                  value={timeSeriesStabilityThreshold}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => dispatch(EditParametersAction(undefined, parseInt(e.target.value)))}
                />
                <TextField
                  id="standard-basic"
                  label="Заработная плата 1 сотрудника (без учета расходов на поиск, подбор и дополнительных отчислений за персонал)"
                  onChange={e => dispatch(EditParametersAction(undefined, undefined, undefined, parseInt(e.target.value)))}
                  value={employeeSalary}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="standard-basic"
                  label="Количество сотрудников (текущее)"
                  value={employeeCount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => dispatch(EditParametersAction(undefined, undefined, undefined, undefined, parseInt(e.target.value)))}
                />
                <TextField
                  id="standard-basic"
                  label="Сверхнормативные колебания могут быть сокращены на %"
                  value={fluctReduction}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => dispatch(EditParametersAction(undefined, undefined, undefined, undefined, undefined, parseInt(e.target.value)))}
                />
                {/*<TextField*/}
                {/*    id="standard-basic"*/}
                {/*    label="Количество сотрудников (плановое)"*/}
                {/*    value={employeeCountPlan}*/}
                {/*    InputLabelProps={{*/}
                {/*        shrink: true,*/}
                {/*    }}*/}
                {/*    onChange={e => setEmployeeCountPlan(e.target.value)}*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    id="standard-basic"*/}
                {/*    label="Сверхнормативные колебания могут быть сокращены на %"*/}
                {/*    value={fluct}*/}
                {/*    InputLabelProps={{*/}
                {/*        shrink: true,*/}
                {/*    }}*/}
                {/*    onChange={e => setFluct(e.target.value)}*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    id="standard-basic"*/}
                {/*    label="Кадровые расходы на поиск и подбор одного нового сотрудника должной квалификации"*/}
                {/*    value={searchCosts}*/}
                {/*    InputLabelProps={{*/}
                {/*        shrink: true,*/}
                {/*    }}*/}
                {/*    onChange={e => setSearchCosts(e.target.value)}*/}
                {/*/>*/}
                <TextField
                  id="standard-basic"
                  label="Регламентные перерывы на одну смену (мин.)"
                  onChange={e => {
                    let parse = parseSecondsFromMinutes(e.target.value != null ? e.target.value : "")
                    dispatch(EditParametersAction(undefined, undefined, parse))
                  }
                  }
                  value={toMinutesString(regularBreakDuration)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/*<FormControlLabel*/}
                {/*    control={<Switch checked={considerExpencesSearch}*/}
                {/*                     onChange={e => setConsiderExpencesSearch(e.target.checked)}/>}*/}
                {/*    label={`Учитывать расходы на поиск и подбор`}*/}
                {/*/>*/}
                {/*<FormControlLabel*/}
                {/*    control={<Switch checked={considerExpencesExtra}*/}
                {/*                     onChange={e => setConsiderExpencesExtra(e.target.checked)}/>}*/}
                {/*    label={`Учитывать расходы на дополнительные отчисления на персонал`}*/}
                {/*/>*/}
              </FormGroup>
            </CardContent>
          </Card>
        </div>
        <div className="projects-steps-shift-container">
          <div>
            {shifts.map((shift, index) => {
              return <ProjectStepsParametersShift shift={shift}
                                                  shiftIndex={index}
                                                  key={index}/>
            })}
          </div>
          <div className="projects-steps-shift-add">
            <Fab color="primary" aria-label="add"
                 onClick={() => dispatch(AddShiftAction())}>
              <AddIcon/>
            </Fab>
          </div>
        </div>
      </div>
      <div className="projects-steps-parameters-save">
        <Fab color="primary" aria-label="add" variant="extended"
             onClick={() => {
               type SettingsShift = {
                 start_time: string
                 end_time: string
                 name: string
                 regular_break_duration_min: number
               }

               interface SettingsInterface {
                 rest_in_idle?: boolean,
                 time_series_stability_threshold?: number,
                 regular_break_duration?: number,
                 employee_salary?: number,
                 employee_count?: number,
                 fluct_reduction?: number,
                 shifts: SettingsShift[]
               }

               const settings: SettingsInterface = {
                 employee_count: employeeCount,
                 employee_salary: employeeSalary,
                 fluct_reduction: fluctReduction,
                 regular_break_duration: regularBreakDuration,
                 shifts: [],
                 time_series_stability_threshold: timeSeriesStabilityThreshold,
                 rest_in_idle: restInIdle,
               }

               if (shifts) {
                 settings.shifts = shifts.map(shift => {
                   return {
                     name: shift.name,
                     start_time: shift.timeStart,
                     end_time: shift.timeFinish,
                     regular_break_duration_min: shift.gaps,
                   }
                 })
               }

               axios.put(`/api/v1/structure/${activeId}`, settings).then(() => alert("Успешно сохранено"))
             }}>
          Сохранить
        </Fab>
      </div>
    </div>
  )
}