import React from "react"
import Paper from "@material-ui/core/Paper"

export const CalculatorPaperWrapper = ({ children }) => {
  return (
    <div className="calculator-paper-container">
      <Paper className="calculator-paper">
        {children}
      </Paper>
    </div>
  )
}