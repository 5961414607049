import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {LandingContainer} from "../landing-container/landing-container";
import NewWord from "../landing-content/new-word";
import Button from "@material-ui/core/Button";
import pasta from '../img/LABRA_Ai.png'


export class Ai extends Component {
    render() {
        return (
            <LandingContainer titleText={"Проведем демонстрацию на Вашем видео по вашим процессам"} titleStyle={{'fontSize': 'xxx-large'}}>
                <div className="landing-section">
                    <div className="landing-section-content" align={'center'}>
                        <img src={pasta} style={{width: 400}}/>
                        <h3>Платформа, которая поможет вашим сотрудникам работать эффективнее</h3>
                        <p>Наш умный алгоритм соберет необходимые данные с ваших камер наблюдения
                            и будет отслеживать эффективность работы ваших сотрудников</p>
                    </div>
                </div>
            </LandingContainer>
        )
    };
}

export default withRouter(Ai)