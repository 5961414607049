import React, {Component} from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import {NavLink} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {removeButton} from "../utils/buttons";
import { Multiselect } from 'multiselect-react-dropdown';
import { PagesWrapper } from "../utils/pages-wrapper"

export default class Models extends Component {
    constructor(props) {
        super(props)
        this.state = {
            videoId: this.props.match.params.videoId,
            videoMeta: null,
            models: [],
            workplaces: [],
            operations: [],
            selectedOperations: [],
            videos: [],
            camera: null,
            comment: '',
            selectedWorkplaceIndex: 0,
            startSecond: null,
            endSecond: null,
            videoAreas: [],
            selectedVideoAreas: [],
            mouseDown: false,
            videoAreaName: '',
            x1: null,
            y1: null,
            x2: null,
            y2: null
        }
    }

    componentDidMount() {
        axios.get(`/api/v1/video_meta/${this.state.videoId}`).then(res => {
            this.setState({ videoMeta: res.data })
        })
        this.updateModels()
        axios.get(`/api/v1/workplaces?video_id=${this.state.videoId}`).then(res => {
            this.setState({ workplaces: res.data })
        })
        axios.get(`/api/v1/available_operations?video_id=${this.state.videoId}`).then(res => {
            this.setState({ operations: res.data.operations })
        })
        this.downloadAreas()
        const canvas = this.refs.canvas
        const ctx = canvas.getContext('2d');
        const getLastMouse = (e) => {
            const boundingRect = canvas.getBoundingClientRect();
            return {
                x: parseInt(e.clientX - boundingRect.left),
                y: parseInt(e.clientY - boundingRect.top),
            }
        }
        canvas.addEventListener('mousedown', (e) => {
            const {x, y} = getLastMouse(e);
            this.setState({ x1: x, y1: y, mouseDown: true })
        });
        canvas.addEventListener('mouseup', (e) => {
            const {x, y} = getLastMouse(e);
            this.setState({ x2: x, y2: y, mouseDown: false })
        })
        canvas.addEventListener('mousemove', (e) => {
            var {x, y} = getLastMouse(e);
            x = Math.max(this.state.x1 || x, x)
            y = Math.max(this.state.y1 || y, y)
            if (this.state.mouseDown) {
                this.drawSnapshot()
                ctx.beginPath();
                ctx.rect(this.state.x1, this.state.y1, x - this.state.x1, y - this.state.y1);
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 1;
                ctx.stroke();
            }
        });
    }

    updateModels = () => {
        axios.get(`/api/v1/models?video_id=${this.state.videoId}`).then(res => {
            this.setState({ models: res.data })
        })
    }

    startTraining = () => {
        const workplaceId = this.state.selectedWorkplaceIndex !== null ? this.state.workplaces[this.state.selectedWorkplaceIndex].id : null
        if (!workplaceId) {
            alert('Выберите рабочее место для разметки')
            return
        }
        axios.post(`/api/v1/start_marker_training`, {
            workplace_id: workplaceId,
            operation_ids: this.state.selectedOperations.map(op => op.id),
            marked_video_id: this.state.videoId,
            video_area_ids: this.state.selectedVideoAreas.map(a => a.id),
            start_second: this.state.startSecond,
            end_second: this.state.endSecond,
            comment: this.state.comment
        }).then(res => {
            alert(`Задача по обучению модели успешно поставлена в очередь, id модели: ${res.data.model_id}`)
            this.updateModels();
        })
    }

    startApplying = (modelId) => {
        axios.post(`/api/v1/start_marker_applying`, {
            model_id: modelId,
            unmarked_video_id: this.state.videoId,
            start_second: this.state.startSecond,
            end_second: this.state.endSecond
        }).then(res => {
            const work = res.data;
            alert(`Начинаем размечать видео ${work.unmarked_video_id} при помощи модели ${work.model_id}`)
        })
    }

    downloadAreas = () => {
        return axios.get(`/api/v1/video-areas?video_id=${this.state.videoId}`).then(res => {
            this.setState({ videoAreas: res.data })
        })
    }

    drawSnapshot = () => {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext('2d');
        const video = this.refs.videoPlayer;
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        ctx.drawImage(video, 0, 0)
        for (const area of this.state.videoAreas) {
            ctx.rect(area.x1, area.y1, area.x2 - area.x1, area.y2 - area.y1);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.font = "30px Arial";
            ctx.fillText(area.name, area.x1, area.y1);
        }
    }

    addArea = () => {
        if (!this.state.videoAreaName) {
            alert('Надо заполнить имя области')
            return
        }
        if (!this.state.x1 || !this.state.y1 || !this.state.x2 || !this.state.y2) {
            alert('Надо получить кадр и обвести хоть один прямоугольник')
            return
        }
        axios.post(`/api/v1/video-area`, {
            camera_id: this.state.videoMeta.camera_id,
            name: this.state.videoAreaName,
            x1: this.state.x1,
            y1: this.state.y1,
            x2: this.state.x2,
            y2: this.state.y2
        }).then(this.downloadAreas).then(this.drawSnapshot)
    }

    removeArea(i) {
        const videoAreaId = this.state.videoAreas[i]?.id
        if (!videoAreaId) {
            alert('Этой области нет в списке!')
            return
        }
        axios.delete(`/api/v1/video-area/${videoAreaId}`).then(this.downloadAreas).then(this.drawSnapshot)
    }

    removeModel(i) {
        const modelId = this.state.models[i]?.meta.id
        if (!modelId) {
            alert('Этой модели нет в списке!')
            return
        }
        axios.delete(`/api/v1/model/${modelId}`).then(this.updateModels)
    }

    render() {
        return (
            <PagesWrapper>
                <h2>Модели, которые размечают операции по видео {this.state.videoMeta?.filename || '?'}</h2>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID модели</th>
                        <th>Имя модели</th>
                        <th>Комментарий</th>
                        <th>Прогресс обучения/приминения модели</th>
                        <th>Обученная модель в хранилище</th>
                        <th>Назначаемое рабочее место</th>
                        <th>Выбирает из операций</th>
                        <th>На какие прямоугольники смотрит</th>
                        <th>Последнее обучение/применение</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.models.map((model, i) => {
                        return (
                            <tr key={i}>
                                <td>{model.meta.id}</td>
                                <td><NavLink to={`/model/${model.meta.id}`}>{model.meta.name}</NavLink></td>
                                <td>{model.meta.comment}</td>
                                <td><ProgressBar now={model.meta.completion_percentage}
                                                 label={`${model.meta.completion_percentage.toFixed(1)}%`}
                                                 variant={model.meta.object_storage_key ? 'success' : 'info'}/></td>
                                <td>{model.meta.object_storage_key || ''}</td>
                                <td>{model.workplace_name}</td>
                                <td>{this.state.operations.filter(op => model.meta.operation_ids.includes(op.id)).map(op => op.name).join(', ')}</td>
                                <td>{this.state.videoAreas.filter(a => model.meta.video_area_ids.includes(a.id)).map(a => a.name).join(', ')}</td>
                                <td>{model.meta.trained_at}</td>
                                <td>
                                    {model.meta.object_storage_key ? <Button
                                        variant="success"
                                        onClick={() => this.startApplying(model.meta.id)}>
                                        {"<<"}
                                    </Button> : ''}
                                    {removeButton(() => {
                                        this.removeModel(i)
                                    })}
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td>
                            <div>
                                <TextField label="Секунда начала видео"
                                           value={this.state.startSecond || ''}
                                           onChange={e => this.setState({startSecond: e.target.value})}
                                           type="number"
                                />
                            </div>
                            <div>
                                <TextField label="Секунда конца видео"
                                           value={this.state.endSecond || ''}
                                           onChange={e => this.setState({endSecond: e.target.value})}
                                           type="number"
                                />
                            </div>
                        </td>
                        <td><TextField label="Комментарий"
                                       value={this.state.comment}
                                       onChange={e => this.setState({ comment: e.target.value })}
                                       type="text"
                        /></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><Dropdown block="true">
                            <Dropdown.Toggle variant="primary" id="dropdown-basic"
                                             block="true">
                                {this.state.workplaces[this.state.selectedWorkplaceIndex]?.name || ''}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.state.workplaces.map((camera, i) => {
                                    return <Dropdown.Item onClick={() => {
                                        this.setState({selectedWorkplaceIndex: i})
                                    }}>
                                        {camera.name}
                                    </Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown></td>
                        <td><Multiselect
                            options={this.state.operations} // Options to display in the dropdown
                            onSelect={ selectedOperations => this.setState({ selectedOperations }) }
                            onRemove={ selectedOperations => this.setState({ selectedOperations }) }
                            displayValue="name" // Property name to display in the dropdown options
                        /></td>
                        <td><Multiselect
                            options={this.state.videoAreas} // Options to display in the dropdown
                            onSelect={ selectedVideoAreas => this.setState({ selectedVideoAreas }) }
                            onRemove={ selectedVideoAreas => this.setState({ selectedVideoAreas }) }
                            displayValue="name" // Property name to display in the dropdown options
                        /></td>
                        <td>
                        </td>
                        <td><Button
                            variant="info"
                            onClick={this.startTraining}>
                            Запустить обучение
                        </Button></td>
                    </tr>
                    </tbody>
                </Table>

                <h2>Области на видео</h2>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Имя области</th>
                        <th>x1</th>
                        <th>y1</th>
                        <th>x2</th>
                        <th>y2</th>
                        <th>удолить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.videoAreas.map((videoArea, i) => {
                        return (
                            <tr key={i}>
                                <td>{videoArea.name}</td>
                                <td>{videoArea.x1}</td>
                                <td>{videoArea.y1}</td>
                                <td>{videoArea.x2}</td>
                                <td>{videoArea.y2}</td>
                                <td>{removeButton(() => {
                                    this.removeArea(i)
                                })}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <div>
                    <TextField label="Имя, святой отец"
                               value={this.state.videoAreaName || ''}
                               onChange={e => this.setState({ videoAreaName: e.target.value })}
                               type="text"
                    />
                    <TextField label="x1"
                               value={this.state.x1 || ''}
                               disabled="true"
                               type="number"
                    />
                    <TextField label="y1"
                               value={this.state.y1 || ''}
                               disabled="true"
                               type="number"
                    />
                    <TextField label="x2"
                               value={this.state.x2 || ''}
                               disabled="true"
                               type="number"
                    />
                    <TextField label="y2"
                               value={this.state.y2 || ''}
                               disabled="true"
                               type="number"
                    />
                </div>
                <Button
                    variant="primary"
                    onClick={this.addArea}>
                    Добавить область
                </Button>
                <video
                    ref="videoPlayer"
                    controls
                    height="200"
                >
                    <source
                        src={`${axios.defaults.baseURL}/api/v1/video?video_id=${this.state.videoId}`}
                        type="video/mp4"/>
                </video>

                <Button
                    variant="primary"
                    onClick={this.drawSnapshot}>
                    Текущий кадр
                </Button>
                <div id="canvas-container">
                    <canvas ref="canvas"/>
                </div>
            </PagesWrapper>
        )
    }
}