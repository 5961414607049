import React from "react"
import { ProjectsNavigation } from "./projects-navigation"
import "./projects.css"
import { PagesWrapper } from "../utils/pages-wrapper"
import { match } from "react-router"
import { useDispatch } from "react-redux"
import { changeActiveStructureAction } from "../../store/projects/structures/actions"
import { ProjectsUnselected } from "./projects-unselected"
import { ProjectsData } from "./projects-data"

interface Id {
  structureId: string;
}

interface Props {
  match: match<Id>;
}


export const Projects: React.FC<Props> = ({ match }) => {
  const id = match.params.structureId

  const dispatch = useDispatch()
  if (id) {
    dispatch(changeActiveStructureAction(+id))
  } else {
    dispatch(changeActiveStructureAction(undefined))
  }

  return (
    <PagesWrapper>
      <div className="projects">
        <ProjectsNavigation/>
        <div className="project-controls">
          <ProjectsUnselected/>
          {id ? <ProjectsData/> : null}
        </div>
      </div>
    </PagesWrapper>
  )
}