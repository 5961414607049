export interface Operation {
  id?: number
  name: string
  type?: string
  referencePoint: string
}

export type OperationTypesCollection = { [key: string]: string };

export interface OperationState {
  operationTypes: OperationTypesCollection
  operations: Operation[]
  toDelete: number[]
}

export const LOAD_OPERATIONS = "LOAD_OPERATIONS"
export const ADD_OPERATION = "ADD_OPERATION"
export const REMOVE_OPERATION = "REMOVE_OPERATION"
export const EDIT_OPERATION = "EDIT_OPERATION"

interface LoadOperationsAction {
  type: typeof LOAD_OPERATIONS
  operations: Operation[]
  operationTypes: OperationTypesCollection
}

interface AddOperationAction {
  type: typeof ADD_OPERATION
}

interface RemoveOperationAction {
  type: typeof REMOVE_OPERATION
  index: number
}

interface EditOperationAction {
  type: typeof EDIT_OPERATION
  index: number
  newType?: string
  newName?: string
  newReferencePoint?: string
}

export type OperationsActionTypes = LoadOperationsAction | AddOperationAction | RemoveOperationAction | EditOperationAction