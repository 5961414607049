import React, {Component} from 'react';
import './yandex-disc-input.css';

export default class YandexDiscInput extends Component {

    render() {
        return (
            <h6>Здесь будет инпут из Яндекса</h6>
        );
    };
}