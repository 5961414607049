import React, {Component, forwardRef} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./edit-operation.css"
import DatePicker from "react-datepicker";
import FormControl from "react-bootstrap/FormControl";
import {removeButton} from "../utils/buttons";

const Input = forwardRef(({onChange, placeholder, value, isSecure, id, onClick}, ref) => (
    <FormControl
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={id}
        onClick={onClick}
        ref={ref}
    />
));

const Aux = props => props.children;

export default class EditOperation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            index: this.props.index,
            types: this.props.types,
            currentOperationKey: this.props.currentOperationKey,
            workplaces: this.props.workplaces,
            selectedWorkplaceId: this.props.selectedWorkplaceId,
            employees: this.props.employees,
            selectedEmployeeId: this.props.selectedEmployeeId,
            timeFrom: this.props.timeFrom,
            timeTo: this.props.timeTo,
            comment: this.props.comment,
        };
    }

    dropItems = () => {
        const drops = [];
        Object.keys(this.state.types)
            .sort((k1, k2) => this.state.types[k1].localeCompare(this.state.types[k2]))
            .forEach((operationKey, i) => {
                drops.push(
                    <Dropdown.Item key={i} id={operationKey} onClick={() => {
                        this.onOperationSelect(operationKey)
                    }}>{this.state.types[operationKey]}</Dropdown.Item>
                )
            });
        return (drops)
    };

    dropWorkplaces = () => {
        const drops = [];
        Object.keys(this.state.workplaces)
            .forEach((workplaceId, i) => {
                drops.push(
                    <Dropdown.Item key={i} id={workplaceId} onClick={() => {
                        this.onWorkplaceSelect(workplaceId)
                    }}>{this.state.workplaces[workplaceId]}</Dropdown.Item>
                )
            });
        return (drops)
    };

    dropEmployees = () => {
        const drops = [];
        Object.keys(this.state.employees)
            .forEach((employeeId, i) => {
                drops.push(
                    <Dropdown.Item key={i} id={employeeId} onClick={() => {
                        this.onEmployeeSelect(employeeId)
                    }}>{this.state.employees[employeeId]}</Dropdown.Item>
                )
            });
        return (drops)
    };

    onOperationSelect = (operationKey) => {
        this.setState({currentOperationKey: operationKey}, () => {
            this.update();
        });
    };

    onWorkplaceSelect = (workplaceId) => {
        this.setState({selectedWorkplaceId: workplaceId}, () => {
            this.update();
        });
    };

    onEmployeeSelect = (employeeId) => {
        this.setState({selectedEmployeeId: employeeId}, () => {
            this.update();
        });
    };

    update = () => {
        console.log(this.state);
        this.props.updateOperation(
            this.state.id,
            this.state.index,
            this.state.currentOperationKey,
            this.state.selectedWorkplaceId,
            this.state.selectedEmployeeId,
            this.state.timeFrom,
            this.state.timeTo,
            this.state.comment);
    };

    updateData = () => {
        const stateUpdate = {
            comment: document.getElementById(`comment-${this.props.index}`).value,
        };
        this.setState(stateUpdate, () => {
            this.update();
        });
    };

    removeOperation = () => {
        this.props.remove(this.state.id, this.state.index);
    };


    render() {
        return (
            <Aux className="edit-operations">
                <div className="edit-operation-name" id={`edit-operation-${this.state.index}`}>
                    <Dropdown block="true">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" block="true">
                            {this.state.types[this.state.currentOperationKey]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.dropItems()}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="edit-operation-workplace">
                    <Dropdown block="true">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" block="true">
                            {(this.state.workplaces[this.state.selectedWorkplaceId] || '-')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.dropWorkplaces()}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="edit-operation-employee">
                    <Dropdown block="true">
                        <Dropdown.Toggle variant="success" id="dropdown-basic" block="true">
                            {(this.state.employees[this.state.selectedEmployeeId] || '-')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.dropEmployees()}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="edit-operation-from">
                    <DatePicker
                        selected={this.state.timeFrom}
                        dateFormat="dd-MM-yyyy HH:mm"
                        onChange={date => {
                            this.setState({timeFrom: Date.parse(date)}, () => {
                                this.update();
                            });
                        }}
                        customInput={<Input/>}
                        locale="ru"
                        showTimeSelect
                        timeIntervals={60}
                        timeCaption="Время"
                        timeFormat="HH:mm"
                    />
                </div>
                <div className="edit-operation-to">
                    <DatePicker
                        selected={this.state.timeTo}
                        dateFormat="dd-MM-yyyy HH:mm"
                        onChange={date => {
                            this.setState({timeTo: Date.parse(date)}, () => {
                                this.update();
                            });
                        }}
                        customInput={<Input/>}
                        locale="ru"
                        showTimeSelect
                        timeIntervals={60}
                        timeCaption="Время"
                        timeFormat="HH:mm"
                    />
                </div>
                <div className="edit-operation-comment">
                    <FormControl
                        id={`comment-${this.props.index}`}
                        aria-label="Small"
                        onChange={this.updateData}
                        disabled={false}
                        value={this.state.comment}
                        placeholder="Комментарий..."/>
                </div>
                <div className="edit-operation-remove">
                    {removeButton(this.removeOperation)}
                </div>
            </Aux>
        )
    }
}