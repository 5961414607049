import {
    ADD_SHIFT_DATA,
    EDIT_SHIFTS_DATA,
    LOAD_SHIFTS_DATA,
    ProjectShiftsActionTypes,
    REMOVE_SHIFT_DATA,
    Shift
} from "./types";

export function LoadShiftsAction(shifts: Shift[]): ProjectShiftsActionTypes {
    return {
        type: LOAD_SHIFTS_DATA,
        shifts: shifts
    }
}

export function AddShiftAction(): ProjectShiftsActionTypes {
    return {
        type: ADD_SHIFT_DATA
    }
}

export function RemoveShiftAction(index: number): ProjectShiftsActionTypes {
    return {
        type: REMOVE_SHIFT_DATA,
        index: index
    }
}

export function EditShiftAction(id: number, newTimeStart?: string, newTimeFinish?: string, newName?: string, newGaps?: number): ProjectShiftsActionTypes {
    return {
        type: EDIT_SHIFTS_DATA,
        id: id,
        newTimeStart: newTimeStart,
        newTimeFinish: newTimeFinish,
        newName: newName,
        newGaps: newGaps,
    }
}

