import React, {Component} from 'react';
import './formula.css';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

export default class Formula extends Component {
    // this.props.measurements

    render() {
        return (
            <div>
                <BlockMath>{`{T}_\\text{такта} = \\frac{\\text{Факт.дл.раб.дн. - Дл.регл.перерывов}}{\\text{Кол.ед. выполненных изделий}} = \\frac{${this.props.loading.all_time}-0}{${this.props.rep_param.product_num}} = ${Math.round(this.props.loading.all_time / this.props.rep_param.product_num)}\\text{ сек}`}</BlockMath>
            </div>)
    };
}