export interface ParametersState {
  restInIdle?: boolean,
  timeSeriesStabilityThreshold?: number
  regularBreakDuration?: number
  employeeSalary?: number,
  employeeCount?: number
  fluctReduction?: number
}

export const LOAD_PARAMETERS = "LOAD_PARAMETERS"
export const EDIT_PARAMETERS = "EDIT_PARAMETERS"

interface LoadParametersAction {
  type: typeof LOAD_PARAMETERS
  restInIdle?: boolean,
  timeSeriesStabilityThreshold?: number
  regularBreakDuration?: number
  employeeSalary?: number
  employeeCount?: number
  fluctReduction?: number
}

interface EditParametersAction {
  type: typeof EDIT_PARAMETERS
  newRestInIdle?: boolean,
  newTimeSeriesStabilityThreshold?: number
  newRegularBreakDuration?: number
  newEmployeeSalary?: number
  newEmployeeCount?: number
  newFluctReduction?: number
}

export type ProjectParametersActionTypes = LoadParametersAction | EditParametersAction