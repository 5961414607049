import React from 'react';
import './existing-frames.css';
import ListGroup from "react-bootstrap/ListGroup";

const ExistingFrames = ({frames}) => {
    const elements = frames.map((frame) => {
        return (
            <ListGroup.Item
                variant="danger">{frame.label} X1: {frame.x1} Y1: {frame.y1} X2: {frame.x2} Y2: {frame.y2}</ListGroup.Item>
        )
    });

    return (
        <ListGroup className="existing-frames-group">{elements}
        </ListGroup>
    );
};

export default ExistingFrames;