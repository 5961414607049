import React, {Component} from 'react';
import './video-operation.css';
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "@material-ui/core/Button";
import VideoOperationSuggestion from "../video-operation-suggestion";
import {removeButton} from "../utils/buttons";

const Aux = props => props.children;


export default class VideoOperation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            index: this.props.index,
            currentOperationKey: this.props.currentOperationKey,
            types: this.props.types,
            workplaces: this.props.workplaces,
            selectedWorkplaceId: this.props.selectedWorkplaceId,
            employees: this.props.employees,
            selectedEmployeeId: this.props.selectedEmployeeId,
            comment: this.props.comment,
            times: this.props.times,
            names: this.props.names,
            type: this.props.type,
            alert: false,
            dropdownColor: this.props.dropdownColor,
            current: this.props.current
        };
    }

    formatedTimeToSeconds = (time) => {
        return parseInt(time.substring(0, 2), 10) * 60 + parseInt(time.substring(3, 5), 10)
    };

    update = () => {
        this.setState({alert: this.state.times.length >= 2 && this.state.times[0] >= this.state.times[1]}); // todo fix async
        this.props.updateOperation(this.state.id, this.state.index, this.state.currentOperationKey, this.state.selectedWorkplaceId, this.state.selectedEmployeeId, this.state.times, this.state.comment);
    };

    updateData = () => {
        const stateUpdate = {
            comment: document.getElementById(`comment-${this.props.index}`).value,
            times: []
        };
        this.props.times.forEach((time, i) => {
            const id_hrs = `${i}-input-${this.props.index}-hrs`;
            const id_min = `${i}-input-${this.props.index}-min`;
            const id_sec = `${i}-input-${this.props.index}-sec`;
            const hrsElement = document.getElementById(id_hrs);
            const minElement = document.getElementById(id_min);
            const secElement = document.getElementById(id_sec);
            const hrs = hrsElement.value ? parseInt(hrsElement.value, 10) : 0;
            const min = minElement.value ? parseInt(minElement.value, 10) : 0;
            const sec = secElement.value ? parseInt(secElement.value, 10) : 0;
            stateUpdate.times.push(hrs * 3600 + min * 60 + sec);
        });
        this.setState(stateUpdate, () => {
            this.update();
        });
    };

    onOperationSelect = (operationKey) => {
        this.setState({currentOperationKey: operationKey}, () => {
            this.update();
        });
    };

    dropWorkplaces = () => {
        const drops = [];
        Object.keys(this.state.workplaces)
            // .sort((e1, e2) => this.state.types[k1].localeCompare(this.state.types[k2]))
            .forEach((workplaceId, i) => {
                drops.push(
                    <Dropdown.Item key={i} id={workplaceId} onClick={() => {
                        this.onWorkplaceSelect(workplaceId)
                    }}>{this.state.workplaces[workplaceId].name}</Dropdown.Item>
                )
            });
        return (drops)
    };

    onWorkplaceSelect = (workplaceId) => {
        this.setState({selectedWorkplaceId: workplaceId}, () => {
            this.update();
        });
    };

    dropEmployees = () => {
        const drops = [];
        Object.keys(this.state.employees)
            // .sort((e1, e2) => this.state.types[k1].localeCompare(this.state.types[k2]))
            .forEach((employeeId, i) => {
                drops.push(
                    <Dropdown.Item key={i} id={employeeId} onClick={() => {
                        this.onEmployeeSelect(employeeId)
                    }}>{this.state.employees[employeeId].name}</Dropdown.Item>
                )
            });
        return (drops)
    };

    onEmployeeSelect = (employeeId) => {
        this.setState({selectedEmployeeId: employeeId}, () => {
            this.update();
        });
    };

    setTime = (index) => {
        this.props.times[index] = this.props.getTime();
        this.setState({times: this.props.times}, () => {
            this.update()
        });
    };

    removeOperation = () => {
        this.props.remove(this.state.id, this.state.index);
    };

    dropdownColor = () => {
        if (this.state.currentOperationKey === -1) {
            return "secondary"
        }
        return this.state.dropdownColor
    };

    workplaceDropdownColor = () => {
        if (!this.state.selectedWorkplaceId) {
            return "secondary"
        }
        return "success"
    };

    employeeDropdownColor = () => {
        if (!this.state.selectedEmployeeId) {
            return "secondary"
        }
        return "success"
    };

    formatTimes = () => {
        const timeArr = this.props.times.map((time, i) => {
            return (
                <div className="video-operation-input" key={`${i}`}>
                    <div>
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="inputGroup-sizing-sm">{this.props.names[i]}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                id={`${i}-input-${this.props.index}-hrs`}
                                aria-label="Small"
                                onChange={this.updateData}
                                value={
                                    Math.floor(this.state.times[i] / 3600)
                                }
                                aria-describedby="inputGroup-sizing-sm"
                            />
                            :
                            <FormControl
                                id={`${i}-input-${this.props.index}-min`}
                                aria-label="Small"
                                onChange={this.updateData}
                                value={
                                    Math.floor((this.state.times[i] % 3600) / 60)
                                }
                                aria-describedby="inputGroup-sizing-sm"
                                maxLength="2"
                            />
                            :
                            <FormControl
                                id={`${i}-input-${this.props.index}-sec`}
                                aria-label="Small"
                                onChange={this.updateData}
                                value={
                                    this.state.times[i] % 60
                                }
                                aria-describedby="inputGroup-sizing-sm"
                                maxLength="2"
                            />
                        </InputGroup>
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => this.setTime(i)}>
                            <i className="material-icons">timelapse</i>
                        </Button>
                    </div>
                </div>
            )
        });

        if (timeArr.length < 2) {
            timeArr.push(
                <div className="video-operation-input">

                </div>)
        }

        return timeArr;
    };

    render() {
        return (
            <Aux className="video-operation">
                <div style={{background: this.state.current ? "red" : "transparent"}}>{this.state.id ? '✅' : ''}</div>
                <div className="video-operation-name" id={`operation-${this.state.index}`}>
                    <VideoOperationSuggestion
                        id = {this.state.index}
                        current={this.state.currentOperationKey}
                        types={this.state.types}
                        onSelect={this.onOperationSelect}
                    />
                </div>
                <div className="video-operation-workplace">
                    <Dropdown block="true">
                        <Dropdown.Toggle variant={this.workplaceDropdownColor()} id="dropdown-basic" block="true">
                            {(this.state.workplaces[this.state.selectedWorkplaceId] || {name: '-'}).name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.dropWorkplaces()}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="video-operation-employee">
                    <Dropdown block="true">
                        <Dropdown.Toggle variant={this.employeeDropdownColor()} id="dropdown-basic" block="true">
                            {(this.state.employees[this.state.selectedEmployeeId] || {name: '-'}).name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.dropEmployees()}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {this.formatTimes()}
                <div className="video-operation-comment">
                    <FormControl
                        id={`comment-${this.props.index}`}
                        aria-label="Small"
                        onChange={this.updateData}
                        disabled={false}
                        value={this.state.comment}
                        placeholder="Комментарий..."/>
                </div>
                <div className="video-operation-remove">
                    {removeButton(this.removeOperation)}
                </div>
            </Aux>
        );
    }
    ;
}