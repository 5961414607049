import React from "react";
import {LandingContainer} from "../landing-container/landing-container";
import {NewsCard} from "./news-card";
import pic1 from "../img/news/3.png";
import pic2 from "../img/news/2.jpg";
import pic3 from "../img/news/1.png";
import pic4 from "../img/news/photo.jpg";
import pic5 from "../img/news/photo.png";
import pic6 from "../img/news/elibrary.png";
import pic7 from "../img/news/4.png";
import pic8 from "../img/news/noroot.png";
import pic9 from "../img/news/photo-l.jpg";
import pic10 from "../img/news/photo-h.png";
import pic11 from "../img/news/7.png";
import pic12 from "../img/news/noroot-2.png";
import {PagesWrapper} from "../utils/pages-wrapper";
import "./landing-news.css"

export const LandingNews = () => {
    return <LandingContainer>
        <div id="news-container" className="landing-section">
            <div id="news-content" className="landing-section-content">
                {/*<h1>Новости</h1>*/}
                <div className="news-content-cards" id="news-cards-top">
                    <NewsCard
                        picture={pic1}
                        header={<h3>GDTM 2020</h3>}
                        text={
                            <p>
                                Команда <b>LABRA</b> приняла участие в конференции <b>Global Challenges of Digital
                                Transformation of
                                Markets GDTM 2020</b>, которая проходила <b>25-27</b> сентября. В скором времени
                                будут
                                опубликованы две
                                статьи в соавторстве с преподавателями университетов СПбГЭУ, ПОЛИТЕХ и других ВУЗов
                                о
                                совместных
                                исследованиях в <b>HR</b>.
                            </p>
                        }
                        hrefText='Страница конференции'
                        href='http://gdtm.spbstu.ru/'
                        date='25.09.2020'
                    />
                    <NewsCard
                        picture={pic2}
                        header={<h3>Онлайн-трансляция совместно с "Есть контакт"</h3>}
                        text={
                            <p>
                                Шишкина Елена выступила в качестве спикера на вводном вебинаре “Есть контакт” о
                                предпринимательстве, потребностях инициативных людей, изменениях в социальной среде
                                и применении искусственного интеллекта и нейронных сетей. </p>
                        }
                        hrefText='Смотреть трансляцию'
                        href='https://youtu.be/_Jbjm4sO5XU?t=2492'
                        date='11.09.2020'

                    />
                    <NewsCard
                        picture={pic3}
                        header={<h3>Новая деловая среда с социально-ориентированными ценностями</h3>}
                        text={
                            <p>
                                <b>LABRA</b> выступила партнером организации “Есть контакт”. Наши коллеги стремятся
                                создать
                                в данном проекте новый тип деловой среды с социально-ориентированными ценностями.
                            </p>
                        }
                        hrefText='Перейти на сайт'
                        href='http://xn----8sbnvbqgvjec6i.xn--p1ai/#rec227007819'
                        date='09.09.2020'
                    />
                    <NewsCard
                        picture={pic4}
                        header={<h3>VC.RU опубликовал материал о LABRA</h3>}
                        text={
                            <p>
                                На сайте <b>VC.RU</b> вышел материал от Яндекс.Облака и <b>Yandex Cloud Boost</b> о том,
                                как не
                                техническому специалисту сделать свой бизнес в ИТ.
                            </p>
                        }
                        hrefText='Ссылка на vc.ru'
                        href='https://vc.ru/life/140306-kak-sozdat-it-startap-esli-ty-gumanitariy'
                        date='08.07.2020'
                    />
                </div>
            </div>
        </div>
        <div id="news-container" className="landing-section">
            <div id="news-content" className="landing-section-content">
                <div className="news-content-cards" id="news-cards-mid">
                    <NewsCard
                        picture={pic5}
                        header={<h3>Конференция ТГТУ</h3>}
                        text={
                            <p>
                                <b>20 мая</b>прошла конференция "Исследование социально-экономического развития
                                территорий
                                в условиях санкций и угроз глобальных вызовов", на которой Шишкина Елена выступила с
                                докладом о внедрении инноваций в использование человеческого капитала в условиях
                                различных эпидемий, кризисов и экономической нестабильности, таких как <b>COVID-19</b>.
                            </p>
                        }
                        hrefText='Ссылка на конференцию'
                        href='http://press.tstu.ru/index.php/item/3209-v-tgtu-proveli-vserossijskuyu-konferentsiyu-po-sotsialno-ekonomicheskomu-razvitiyu-territorij-v-usloviyakh-sanktsij-i-ugroz-globalnykh-vyzovov'
                        date='20.05.2020'
                    />
                    <NewsCard
                        picture={pic6}
                        header={<h3>Возможности 4-х дневной рабочей недели</h3>}
                        text={
                            <p>
                                Вышла новая статья ВАК, в которой были исследованы возможности внедрения
                                четырехдневной рабочей недели в России, их последствия, положительные и
                                отрицательные аспекты.
                            </p>
                        }
                        hrefText='Публикация'
                        href='https://www.elibrary.ru/item.asp?id=42548850&'
                        date='15.04.2020'
                    />
                    <NewsCard
                        picture={pic7}
                        header={<h3>Монография</h3>}
                        text={
                            <p>
                                Шишкина Елена выступила соавтором монографии “Социальная модернизация: Российская
                                специфика и некоторые уроки”, в которой в главе, посвященной взаимодействию
                                Государства, корпораций и институтов гражданского общества в процессе
                                социально-экономической модернизации, обратила внимание на человеческий капитал
                                сотрудников и его корреляцию с нормированием труда.
                            </p>
                        }
                        hrefText='Монография'
                        href='https://www.elibrary.ru/download/elibrary_42423145_28280923.pdf'
                        date='03.02.2020'
                    />
                    <NewsCard
                        picture={pic8}
                        header={<h3>Лекция о нейросетях на Art.Transaction</h3>}
                        text={
                            <p>
                                Специалисты <b>LABRA</b> поделились своим мнением о будущем взаимоотношений человека и
                                нейросетей, а также выступили с докладом о том, что такое нейронные сети и как они
                                используются в <b>HR</b>.
                            </p>
                        }
                        hrefText='Мероприятия'
                        href='https://art-transaction.com/'
                        date='14.12.2019'
                    />
                </div>
            </div>
        </div>
        <div id="news-container" className="landing-section">
            <div id="news-content" className="landing-section-content">
                <div className="news-content-cards" id="news-cards-bot">
                    <NewsCard
                        picture={pic9}
                        header={<h3>Популяризация компьютерного зрения</h3>}
                        text={
                            <p>
                                Шишкина Елена выступила в качестве соавтора в статье об используемых <b>LABRA</b>
                                технологиях для повышения качества трудовой жизни сотрудников через популяризацию
                                использования видеокамер для выявления ресурсов оптимизации работы компании.
                            </p>
                        }
                        hrefText='Публикация'
                        href='https://www.elibrary.ru/item.asp?id=41707426'
                        date='25.11.2019'
                    />
                    <NewsCard
                        picture={pic10}
                        header={<h3>О том, как все начиналось</h3>}
                        text={
                            <p>
                                О компании <b>LABRA</b>и ее команде вышел материал на <b>Habr</b>, в котором мы
                                поделились, каким
                                образом технологии компьютерного зрения могут быть использованы в <b>HR</b> процессах, а
                                также каким образом мы оптимизируем работу <b>HR</b>-систем в компаниях.
                            </p>
                        }
                        hrefText='Ссылка на habr.com'
                        href='https://habr.com/ru/company/spbifmo/blog/471254/'
                        date='12.10.2019'
                    />
                    <NewsCard
                        picture={pic11}
                        header={<h3>Конференция РГУП</h3>}
                        text={
                            <p>
                                Члены команды <b>LABRA</b> опубликовали исследование, посвященное повышению
                                производительности труда и использованию математических методов для создания и
                                оптимизации метрик.
                            </p>
                        }
                        hrefText='Публикация'
                        href='https://www.elibrary.ru/download/elibrary_42815135_11264474.pdf'
                        date='22.11.2019'
                    />
                    <NewsCard
                        picture={pic12}
                        header={<h3>Участие во II Петербургском цифровом форуме</h3>}
                        text={
                            <p>
                                Команда <b>LABRA</b> представила на <b>I</b> Петербургском цифровом форуме посетителям
                                возможность
                                воочию ознакомиться с работой нейросети, которая идентифицирует операции сотрудников
                                и предлагает простые пути оптимизации.
                            </p>
                        }
                        hrefText='О нашем стенде'
                        href='https://news.itmo.ru/ru/startups_and_business/partnership/news/8761/'
                        date='4.09.2019'
                    />
                </div>
            </div>
        </div>
    </LandingContainer>
}