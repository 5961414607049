import React from "react"
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TextField} from "@material-ui/core"
import {removeButton} from "../utils/buttons"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {Shift} from "../../store/projects/project-shifts/types";
import {useDispatch} from "react-redux";
import {EditShiftAction, RemoveShiftAction} from "../../store/projects/project-shifts/actions";


const timeInput = (label: string, value: string, onChange: (e: any) => void, index: number) =>
    <TextField
        key={index}
        label={label}
        type="time"
        InputLabelProps={{shrink: true}}
        inputProps={{step: 60}}
        value={value}
        onChange={onChange}
    />


interface ShiftData {
    shiftIndex: number
    shift: Shift
}

export const ProjectStepsParametersShift: React.FC<ShiftData> = ({shiftIndex, shift}) => {
    const dispatch = useDispatch()
    const onTimeChange = (newTime: string, index: number) => {
        const time = [shift.timeStart, shift.timeFinish]
        time[index] = newTime
        dispatch(EditShiftAction(shiftIndex, time[0], time[1]))
    }

    return <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon/>}
        >
            <TextField id="standard-basic"
                       value={shift.name}
                       onChange={e => {
                           dispatch(EditShiftAction(shiftIndex, undefined, undefined, e.target.value))
                       }
                       }
            />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <div className="project-steps-parameters-shifts-start-end">
                {
                    [
                        timeInput("Начало", shift.timeStart, (e) => onTimeChange(e.target.value, 0), 0),
                        timeInput("Конец", shift.timeFinish, (e) => onTimeChange(e.target.value, 1), 1),
                    ]
                }
                <TextField id="standard-basic"
                           label="Регламентные перерывы на одну смену (мин.)"
                           value={shift.gaps}
                           onChange={e => {
                               dispatch(EditShiftAction(shiftIndex, undefined, undefined, undefined, parseInt(e.target.value)))
                           }}
                />
                {removeButton(() => dispatch(RemoveShiftAction(shiftIndex)))}
            </div>
        </ExpansionPanelDetails>
    </ExpansionPanel>
}
