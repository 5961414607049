import React from "react";

export const Cases = () => {
    return <>
        <div id="cases" className="landing-section">
            <div id="cases-content" className="landing-section-content">
                <h3>Кейсы</h3>
                <div id="cases-cards">
                    <div>
                        <span className='circle'>
                            1
                        </span>
                        <h4>Производство с большой долей ручного труда</h4>
                        <ul>
                            <li>Производство деталей, штат <b>{`>260`}</b> человек. Были решены задачи: реорганизация рабочих мест,
                                выявить
                                максимально возможную границу производительности, установлены границы норм, а
                                производительность
                                труда
                                повышена на <b>18%</b>.
                            </li>
                            <li>
                                Компания-ритейл с небольшим производством штатом до <b>60</b> человек. Основной задачей было
                                составление
                                плана
                                и
                                удовлетворение потребности в персонале. Результатом стало увеличение производства на <b>40% </b>
                                с
                                увеличением
                                прибыли на <b>37%</b> за <b>2</b> года.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className='circle'>
                            2
                        </span>
                        <h4>Государственные учреждения</h4>
                        <ul>
                            <li>Учреждение штатом до <b>60</b> человек: разработка нормативов на труд и обоснование численности
                                сотрудников.
                            </li>
                            <li>Учреждение штатом около <b>300</b> человек: разработка норм труда и отдыха, составление
                                положения
                                по
                                нормированию
                                труда и сопровождающей документации, обоснование и расчет необходимой численности
                                сотрудников.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className='circle'>
                            3
                        </span>
                        <h4>Компании, направленные на оказание услуг</h4>
                        <ul>
                            <li>
                                Сфера услуг, компания штатом <b>{`>150`}</b> человек. Задачи: разработка системы оплаты труда
                                взамен
                                менее
                                эффективной, анализ <b>HR</b>-бизнес - процессов.
                            </li>
                            <li>
                                Компания штатом до <b>100</b> человек. Выполненные задачи: разработан план восполнения
                                потребностей
                                в
                                персонале,
                                оценка персонала, разработка и внедрение мероприятий на повышение мотивации
                                персонала.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="cases" className="landing-section">
            <div id="cases-content" className="landing-section-content">
                <h3>Кейсы</h3>
                <div id="cases-cards">
                    <div>
                        <span className='circle'>
                            4
                        </span>
                        <h4>Пищевая промышленность</h4>
                        <ul>
                            <li>
                                Несколько торговых точек штатом до <b>10</b>-и человек, была реорганизована система мотивации
                                персонала,
                                благодаря
                                чему выручка была повышена на <b>31%</b>.
                            </li>
                            <li>
                                Производство штатом до <b>30</b>-и человек, повышена производительность труда и сокращено
                                непродуктивно
                                используемое время до <b>10%</b>.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className='circle'>
                            5
                        </span>
                        <h4>Строительство и проектирование сетей</h4>
                        Компания штатом <b>{`>30`}</b> человек. Задачей было реорганизовать штат и помочь с помощью персонала
                        увеличить
                        долю
                        на рынке Санкт-Петербурга. Задача была выполнена с помощью организации обучения, изменения
                        системы
                        мотивации
                        и правил внутреннего трудового распорядка. За отсутствием других методик чистая прибыль возросла
                        на
                        <b> 6% </b>
                        (маркетинговые кампании не проводились) за счет повышения скорости работы и
                        конкурентоспособности
                        компании.
                    </div>
                    <div>
                        <span className='circle'>
                            6
                        </span>
                        <h4> Заводы</h4>
                        Производство численностью более <b>400</b> сотрудников. Выполненная задача: снижение количества брака
                        до
                        <b> 10%</b>,
                        снижение количества ошибок до <b>5%</b>. Снижены расходы на персонал на <b>8%</b>. Предложена долгосрочная
                        стратегия
                        внутреннего наставничества для более экономичного подхода в удовлетворении потребности в
                        персонале.
                    </div>
                </div>
            </div>
        </div>
        <div id="cases" className="landing-section">
            <div id="cases-content" className="landing-section-content">
                <h3>Кейсы</h3>
                <div id="cases-cards">
                    <div>
                        <span className='circle'>
                            7
                        </span>
                        <h4>Специалисты узких направлений</h4>
                        <ul>
                            <li>Повышение эффективности работы</li>
                            <li> Ускорение и повышение эффективности принятия управленческих решений
                            </li>
                            <li> Улучшение производственного процесса
                            </li>
                            <li> Снижение простоев в работе
                            </li>
                            <li> Наставничество и обучение специалистов
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className='circle'>
                            8
                        </span>
                        <h4> Рабочие
                        </h4>
                        <ul>
                            <li>Сокращение времени на выполнение операций</li>
                            <li>Снижение травматизма и повышение безопасности работы
                            </li>
                            <li>Снижение брака и количества ошибок

                            </li>
                            <li>Повышение производительности и содержательности труда
                            </li>
                            <li>Регламентация труда, соответствие законодательным и локальным нормам
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className='circle'>
                            9
                        </span>
                        <h4>Административный персонал</h4>
                        <ul>
                            <li>Оптимизация бизнес-планирования</li>
                            <li>Улучшение внутренней инфраструктуры</li>
                            <li>Упрощение и ускорение внутренних бизнес-процессов</li>
                            <li>Мотивация кадров</li>
                            <li>Снижение затрат на поиск и подбор, уменьшение сроков поиска</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
}