import React from 'react';
import './operations.css';
import Form from "react-bootstrap/Form";

const Operations = ({items}) => {
    const elements = items.map((item) => <option>{item.label}</option>);

    return (<div className="operations">
        <Form>
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label className="list-operations">Перечень операций</Form.Label>
                <Form.Control as="select">
                    {elements}
                </Form.Control>
            </Form.Group>
        </Form>
    </div>)
};

export default Operations;