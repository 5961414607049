import React from "react"
import { Button, FormGroup } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorPrivateInput = ({
                                         manualLaborCoef,
                                         hourAnalyticsPrice,
                                         hourMarkupPrice,
                                         hourSupportPrice,
                                         hourTechPrice,
                                         specialistNum,
                                         rewindSpeed,
                                         markupWorkHours,
                                         optTesource,
                                         insurance,
                                         handleManualLaborCoefChange,
                                         handleHourAnalyticsPriceChange,
                                         handleHourMarkupPriceChange,
                                         handleHourSupportPriceChange,
                                         handleHourTechPriceChange,
                                         handleSpecialistNumChange,
                                         handleRewindSpeedChange,
                                         handleMarkupWorkHoursChange,
                                         handleOptTesourceChange,
                                         handleInsuranceChange,
                                         handleUpdateDefaults,
                                       }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-public-output calculator-form-group">
        <FormGroup>
          <TextField label="Коэффициент ручного труда" value={manualLaborCoef} onChange={handleManualLaborCoefChange}/>
          <TextField label="Стоимость 1 часа аналитики" value={hourAnalyticsPrice}
                     onChange={handleHourAnalyticsPriceChange}/>
          <TextField label="Цена разметки 1 часа видео" value={hourMarkupPrice} onChange={handleHourMarkupPriceChange}/>
          <TextField label="Расходы на поддержку" value={hourSupportPrice} onChange={handleHourSupportPriceChange}/>
          <TextField label="Техническое обслуживание на 1 час видео" value={hourTechPrice}
                     onChange={handleHourTechPriceChange}/>
          <TextField label="Текущее количество специалистов по разметке" value={specialistNum}
                     onChange={handleSpecialistNumChange}/>
          <TextField label="Текущее ускорение, с которым просматривается видео" value={rewindSpeed}
                     onChange={handleRewindSpeedChange}/>
          <TextField label="Количество часов, которые разметчики готовы провести в день за работой"
                     value={markupWorkHours} onChange={handleMarkupWorkHoursChange}/>
          <TextField label="Предполагаемый ресурс для оптимизации" value={optTesource}
                     onChange={handleOptTesourceChange}/>
          <TextField label="Уровень страховых взносов = 1 +" value={insurance} onChange={handleInsuranceChange}/>
          <Button variant="contained" color="primary" onClick={handleUpdateDefaults}>Обновить параметры</Button>
        </FormGroup>
      </div>
    </CalculatorPaperWrapper>
  )
}