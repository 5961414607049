import React, {Component} from 'react';
import './footer.css';
import scholar from "../../img/scholar.png";
import elibrary from "../../img/elibrary.png";
import scholar_webp from "../../img/scholar.webp";
import elibrary_webp from "../../img/elibrary.webp";
import Image from "react-image-webp";

export default class Footer extends Component {
    render() {
        return (
            <div className="offer-part" id="footer">
                <div className="footer-links">
                    <div className="footer-text">
                        <h4 style={{color: "white"}}>
                            <hr/>
                            Наши научные исследования и публикации можно посмотреть здесь:
                        </h4>
                    </div>
                    <div className="footer-links-img" id="footer-elibrary">
                        <a href="https://elibrary.ru/author_items.asp?authorid=897203&show_refs=1&pubrole=100&show_option=0">
                            <Image
                                className="what-we-can-card-picture"
                                src={elibrary}
                                webp={elibrary_webp}
                                alt="LABRA"
                                border="0"
                            />
                        </a>
                    </div>
                    <div className="footer-links-img" id="footer-scholar">
                        <a href="https://scholar.google.ru/citations?hl=ru&user=66hFygYAAAAJ&sortby=pubdate&view_op=list_works&gmla=AJsN-F5zWcZd5fJCZV95-vF1cCzxbzaWDI5ZZKu82-vDTPEM5o7rMNvWRRmp16SrRaNDlOkSNvEjWTExe1EmpujgPs82DaxztOOdUftg75VoJYmf9CUntS_M56Uz_S-w5jcWwcaJ7DT47Ax6WKhiV_jKY2mZYJDynGZ36-po8zzduMafaI04agd51qA8kXGRWnFcz5wJ9YxBThL7Qw1tPxNHnSO0RgphZA">
                            <Image
                                className="what-we-can-card-picture"
                                src={scholar}
                                webp={scholar_webp}
                                alt="LABRA"
                                border="0"
                            />
                        </a>
                    </div>
                    <div id="footer-copyright">
                        <h5>2016-2023 © LABRA</h5>
                    </div>
                </div>
            </div>
        );
    };
}