import React from "react";
import {LandingContainer} from "../landing-container/landing-container";
import {WhoWeAre} from "./who-we-are";
import "./landing-services.css"
import {EffectivePlatform} from "./effective-platform";
import {Metrics} from "./metrics";
import {Cases} from "./cases";

export const LandingServices = () => {
    return <LandingContainer>
        <WhoWeAre/>
        <EffectivePlatform/>
        <Cases/>
        <Metrics/>
    </LandingContainer>
}