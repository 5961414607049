import React from "react";
import "./services.css"

export const Services = () => {
    return <div id="services" className="landing-section">
        <div id="services-content" className="landing-section-content">
            <div id="services-text">
                <p>Более шести лет исследовали и работали с методологиями сбора и анализа данных для стратегического планирования, стаффинга (Staffing) и HR-процессов. Мы занимаемся расчетами численности и оптимизацией трудовых процессов, определяя эффективные нормативы. Наш Ai-подход обогащен опытом в области искусственного интеллекта, производительности и управления человеческими ресурсами (HR), что делает управление проектом эффективным. У нас также внушительный опыт в управлении производством и организацией.
                    Этот аналитический и инновационный подход позволяет автоматизировать HR аналитику, выявлять сильные стороны бизнес-процессов и реализовывать стратегии по повышению производительности, что отражается на общей эффективности работы.</p>
            </div>
            <div id="services-container">
                <div id="services-left">
                    <hr/>
                    <h5>Мы предлагаем следующие услуги:</h5>
                    <ul>
                        <li><b>Аналитика эффективности публичных мероприятий (лекции, конференции, выставки) <span
                            className="text-red">EVAN</span></b>
                        </li>
                        <li><b>Таск трекер для оптимизации планирования <span
                            className="text-red">Labra Tracker</span></b>
                        </li>
                        <li><b>Калькулятор оценки ресурса для повышения производительности труда <span
                            className="text-red">НОВИНКА</span></b>
                        </li>
                        <li><b>Калькулятор смен <span
                            className="text-red">НОВИНКА</span></b>
                        </li>
                    </ul>
                </div>
                <div id="services-right">
                    <ul>
                        <li><b>Автоматизированная платформа анализа трудовой производительности</b>: инструмент для детального анализа и оптимизации трудовых процессов. Эта платформа способствует повышению производительности труда и эффективному управлению проектом, управления производством и менеджменте организации.</li>
                        <li><b>Оценка персонала</b>: качественная оценка и развитие потенциала сотрудников. В ходе оценки учитывается характеристика с места работы и система управления персоналом.</li>
                        <li><b>Нормирование труда</b>: разработка индивидуальных норм для оптимизации процессов. Нормирование труда направлено на повышение производительности труда и обеспечение безопасности персонала.</li>
                        <li><b>Организация HR бизнес-процессов</b>: внедрение эффективных бизнес-процессов в управлении человеческими ресурсами. Это включает в себя работу с КЭДО и аттестацией сотрудников.</li>
                        <li><b>Разработка и внедрение системы оплаты труда</b>: создание справедливой системы оплаты, учитывая специфику компании. Это также включает в себя планирование на производстве и использование научных достижений для улучшения работы предприятия.</li>
                        <li><b>Мотивации персонала</b>: стратегии для улучшения удовлетворенности и продуктивности сотрудников. Мотивация персонала напрямую влияет на качификацию сотрудника и оптимизацию производства.</li>
                        <li><b>Мероприятия по повышению эффективности работы</b>: разработка и проведение мероприятий для развития профессиональных навыков. Это также включает в себя HR аналитику и управление производительностью.</li>
                        <li><b>Повышение качества и результативности работы</b>: оптимизация процессов для достижения высоких результатов и качества продукции/услуг. Для достижения этой цели применяется оптимизация кадров и работа с HR метриками.</li>
                        <li><b>Обучение персонала </b>: разработка и внедрение обучающих программ, адаптированных под потребности компании. Обучение персонала способствует повышению эффективности труда и производительности.</li>
                        <li><b>Создание корпоративной культуры </b>: поддержка в формировании и развитии уникальной корпоративной культуры. Включает в себя обеспечение персоналом и управление проектом.</li>
                        <li><b>HR консультирование</b>: экспертная поддержка в управлении человеческими ресурсами и повышении эффективности. Осуществляется с использованием системы управления персоналом и повышения производительности труда.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
}