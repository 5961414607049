import React, {Component} from 'react';
import './form.css';
import Feedback from "../feedback";
import Footer from "../footer";

export default class Form extends Component {
    render() {
        return (
            <div id="form" className="landing-section">
                <div id="form-content" className="landing-section-content">
                    <div id="form-begin">
                        <h1 style={this.props.titleStyle}>{this.props.titleText ?? "Давайте начнем!"}</h1>
                    </div>
                    <div className="form-container">
                        <Feedback/>
                    </div>
                </div>
                <div id="footer">
                    <Footer/>
                </div>
            </div>
        );
    };
}