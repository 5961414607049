import React from "react"
import "./utils.css"

interface pagesWrapperProps {
}

export const PagesWrapper: React.FC<pagesWrapperProps> = props => {
  return (
    <>
      <div className="header-foundation"/>
      {props.children}
    </>
  )
}