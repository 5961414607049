import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import React from "react"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { useHistory } from "react-router"
import { Structure, StructuresState } from "../../store/projects/structures/types"
import { useDispatch, useSelector } from "react-redux"
import { changeActiveStructureAction } from "../../store/projects/structures/actions"

interface ProjectsNavigationItemsProps {
  current_node?: Structure
}

interface RootState {
  structures: StructuresState
}

export const ProjectsNavigationItems: React.FC<ProjectsNavigationItemsProps> = ({ current_node }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const history = useHistory()
  const structures = useSelector((state: RootState) => state.structures.structures)
  const active = useSelector((state: RootState) => state.structures.active)

  const handleClick = () => {
    if (!current_node) {
      console.error("There is a button with root node!")
      return
    }
    history.push(`/projects/${current_node.id}`)
    dispatch(changeActiveStructureAction(current_node.id))
    setOpen(!open)
  }
  let children: Structure[] = []

  if (current_node) {
    structures.forEach(structure => {
      if (structure.parentId === current_node.id) {
        children.push(structure)
      }
    })

    if (!open && active) {
      let id: number | undefined = active

      const find = (id: number) => structures.find(x => x.id === id)

      while (id) {
        const structure = find(id)
        if (!structure) {
          break
        }
        if (structure.id === current_node.id) {
          setOpen(true)
        }
        id = structure.parentId
      }
    }

    if (children.length === 0) {
      return (
        <ListItem button key={`structure-${current_node.id}`} onClick={handleClick}
                  selected={active === current_node.id}>
          <ListItemText primary={current_node.name}/>
        </ListItem>
      )
    }

    return (
      <>
        <ListItem button onClick={handleClick} selected={active === current_node.id}>
          <ListItemText primary={current_node.name}/>
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit
                  style={{ paddingLeft: "16px" }}>
          <List component="div" disablePadding>
            {children.map((child, index) => {
              return (<ProjectsNavigationItems current_node={child}
                                               key={`structure-${child.id}`}
              />)
            })}
          </List>
        </Collapse>
      </>
    )
  } else {
    const availableIds = new Set(structures.map(s => s.id))
    children = structures.filter(structure => !(structure.parentId && availableIds.has(structure.parentId)))
    return (
      <>
        {children.map(child =>
          <ProjectsNavigationItems current_node={child}
                                   key={`structure-${child.id}`}
          />)}
      </>
    )
  }
}