import React, {Component} from 'react';
import './reviews.css';
import logo1 from "../../img/reviews/company-logo-1.png";
import logo2 from "../../img/reviews/company-logo-2.png";
import logo3 from "../../img/reviews/company-logo-3.png";
import logo4 from "../../img/reviews/company-logo-4.png";
import logo5 from "../../img/reviews/company-logo-5.png";
import logo6 from "../../img/reviews/company-logo-6.png";
import logo7 from "../../img/reviews/company-logo-7.png";
import logo8 from "../../img/reviews/company-logo-8.png";
import logo9 from "../../img/reviews/company-logo-9.png";
import logo10 from "../../img/reviews/company-logo-10.png";
import logoBiocad from "../../img/reviews/biocad.png";
import logoHeineken from "../../img/reviews/heineken.jpeg";
import logoSolarisBank from "../../img/reviews/solarisbank.png";
import logoX5 from "../../img/reviews/X5new.jpg";
import logoBL from "../../img/reviews/БЛ.jpeg"

export default class Reviews extends Component {
    render() {
        return (
            <div id="reviews" className="landing-section">
                <div id="reviews-content" className="landing-section-content">
                    <h1>Нас уже оценили</h1>
                    <hr/>
                    <div className="reviews-card-container">
                        <div className="reviews-card">
                            <img src={logo1}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logo2}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logo3}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logo4}/></div>
                        <div className="reviews-card">
                            <img src={logo5}/></div>
                        <div className="reviews-card">
                            <img src={logo6}/></div>
                        <div className="reviews-card">
                            <img src={logo7}/></div>
                        <div className="reviews-card">
                            <img src={logo8}/></div>
                        <div className="reviews-card">
                            <img src={logo9}/></div>
                        <div className="reviews-card">
                            <img src={logo10}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logoBiocad}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logoHeineken}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logoSolarisBank}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logoX5}/>
                        </div>
                        <div className="reviews-card">
                            <img src={logoBL}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}