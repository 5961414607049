import React, { forwardRef } from "react"
import { TextField } from "@material-ui/core"

export const InputWrapper = forwardRef(({ onChange, placeholder, value, isSecure, id, onClick }, ref) => (
  <TextField
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
    ref={ref}
  />
))
