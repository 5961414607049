import React, {Component} from 'react';
import './what-we-got.css';

export default class WhatWeGot extends Component {
    render() {
        return (
            <div id="what-we-got" className="landing-section">
                <div id="what-we-got-content" className="landing-section-content">
                    <h1>Почему мы?</h1>
                    <div className="what-we-got-card-container">
                        <div className="what-we-got-card">
                            <h2>Кейсы более чем</h2>
                            <h2>в 12 отраслях</h2>
                            <br/>
                            <p>В общей сумме работа с более чем <strong
                            >21 000</strong> сотрудников в
                                разных
                                компаниях</p>
                        </div>
                        <div className="what-we-got-card">
                            <h2>Команда</h2>
                            <h2>компетентных специалистов</h2>
                            <br/>
                            <p>Эксперты в области HR и разработки ПО для решения HR задач и оптимизации процессов, сотрудничество
                                Ai и IT специалистами.</p>
                        </div>
                        <div className="what-we-got-card">
                            <h2>Автоматизация</h2>
                            <h2>&nbsp;</h2>
                            <br/>
                            <p>Вы можете уже сегодня своими силами без лишних трат начать оптимизировать работу Ваших
                                сотрудников</p>
                        </div>
                        <div className="what-we-got-card">
                            <h2>Первые результаты</h2>
                            <h2>&nbsp;</h2>
                            <br/>
                            <p>Вы увидите первый результат не позднее, чем за квартал после начала работ</p>
                        </div>
                        <div className="what-we-got-card">
                            <h2>Точность</h2>
                            <h2>&nbsp;</h2>
                            <br/>
                            <p>Точность наших алгоритмов, призванных вам в помощь, составляет более 95%</p>
                        </div>
                        <div className="what-we-got-card">
                            <h2>{`> 50`}</h2>
                            <h2>Научных публикаций</h2>
                            <br/>
                            <p>Научные статьи были написаны по результатам сделанных разработок. На данный момент
                                имеется пособие совместно с СПбГЭУ</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}