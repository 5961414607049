import React from "react"
import Button from "@material-ui/core/Button"
import axios from "axios"
import {useHistory} from "react-router"
import {useDispatch} from "react-redux"
import {UserExitAction} from "../../store/login/actions"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import {Menu, MenuItem} from "@material-ui/core"

export const ProjectsUnselected: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const logout = () => {
        axios.get(`/api/v1/leave`).then(res => {
            alert(`${res.data}`)
            history.push("/")
            dispatch(UserExitAction())
        })
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const getClient = (os: System) => {
        switch (os) {
            case System.windows:
                window.open(`http://storage.yandexcloud.net/labra/distr/LabraClient%201.0.0.msi`)
                break;
            case System.macos:
                window.open(`http://storage.yandexcloud.net/labra/distr/LabraClient-1.0.0.dmg`)
                break;
            case System.linux:
                window.open(`http://storage.yandexcloud.net/labra/distr/labra-client_1.0.0_amd64.deb`)
                break;
        }
        handleClose()
    }

    enum System {
        windows,
        macos,
        linux
    }

    return (
        <div className="projects-unselected">
            <div className="projects-unselected-buttons">
                <div className="projects-data-buttons-button">
                    <Button variant="contained"
                            color="primary"
                            href={`/#/calculator`}
                    >
                        Калькулятор
                    </Button>
                </div>
                <div className="projects-data-buttons-button">
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<CloudUploadIcon/>}
                        onClick={handleClick}
                    >
                        Загрузчик
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => getClient(System.windows)}>Windows</MenuItem>
                        <MenuItem onClick={() => getClient(System.macos)}>MacOs</MenuItem>
                        <MenuItem onClick={() => getClient(System.linux)}>Linux</MenuItem>
                    </Menu>
                </div>
                <div className="projects-data-buttons-button">
                    <Button variant="contained"
                            color="primary"
                            href={`/#/ciblock/map`}
                    >
                        CiBlock
                    </Button>
                </div>
                <div className="projects-data-buttons-button">
                    <Button variant="contained"
                            color="secondary"
                            onClick={logout}>
                        Выйти
                    </Button>
                </div>
            </div>
        </div>
    )
}