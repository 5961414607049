import { CHANGE_TIME, Employee, SELECT_EMPLOYEE, StructureDataActionTypes, TimeSelect, UPDATE_STRUCTURE } from "./types"

export function UpdateStructureAction(dates: Date[], employees: Employee[]): StructureDataActionTypes {
  return {
    type: UPDATE_STRUCTURE,
    reportDates: dates,
    employees: employees
  }
}

export function ChangeTimeAction(timeSelect: TimeSelect, newTime: Date): StructureDataActionTypes {
  return {
    type: CHANGE_TIME,
    timeSelect: timeSelect,
    newTime: newTime
  }
}

export function SelectEmployeeAction(id: number): StructureDataActionTypes {
  return {
    type: SELECT_EMPLOYEE,
    employeeId: id
  }
}