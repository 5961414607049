import React, {Component} from "react"
import {ReportTable} from "./report-table"
import axios from "axios"
import "./report.css"
import {ReportDiagram} from "./report-diagram"
import {PieChart} from "./piechart/piechart"
import {Pareto} from "./piechart/pareto"
import {ReportParagraph} from "./report-paragraph"
import {DoubleDiagram} from "./piechart/double-diagram"
import {ReportHuman} from "./report-human"
import {Button} from "@material-ui/core"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import {PagesWrapper} from "../utils/pages-wrapper"
import {BarChart} from "./piechart/bar-chart"
import {BlackPie} from "./piechart/black-pie"
import {BlackHumans} from "./piechart/black-humans";


export default class Report extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blocks: [],
        }
    }

    componentDidMount() {
        const structureId = this.props.match.params.structureId
        const from = this.props.match.params.fromTime
        const to = this.props.match.params.toTime
        const path = this.inIframe() ? '/api/v3/compute-report' : '/api/v2/report'
        axios.get(`${path}?structure_id=${structureId}&from_time=${from}&to_time=${to}`).then(res => {
            console.log(res.data.blocks)
            this.setState({
                blocks: res.data.blocks,
            })
        }).catch(alert)
    }

    inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    renderBlock(block) {
        switch (block.type) {
            case "header":
                return <div className="report-header"
                            dangerouslySetInnerHTML={{__html: `${block.data.reportHeader}${block.data.observationHeader}`}}/>
            case "table":
                return <ReportTable table={block.data}/>
            case "diagram":
                return <ReportDiagram chartGetter={(data) => <PieChart data={data}/>} data={block.data}/>
            case "pareto":
                return <ReportDiagram chartGetter={(data) => <Pareto data={data}/>} data={block.data}/>
            case "paragraph":
                return <ReportParagraph data={block.data}/>
            case "double_diagram":
                return <ReportDiagram chartGetter={(data) => <DoubleDiagram data={data}/>} data={block.data}/>
            case "human":
                return <ReportDiagram chartGetter={(data) => <ReportHuman data={data}/>} data={block.data}/>
            case "human_proportion":
                return <ReportDiagram chartGetter={(data) => <BlackHumans data={data}/>} data={block.data}/>
            case "grouped_bar":
                return <ReportDiagram chartGetter={(data) => <BarChart data={data}/>} data={block.data}/>
            case "black_diagram":
                return <ReportDiagram chartGetter={(data) => <BlackPie data={data}/>} data={block.data}/>
            default:
                throw new Error(`Неизвестный тип блока ${block.type}`)
        }
    }

    render() {
        return (
            <PagesWrapper>
                <div className="report">
                    <div className="report-pdf-button">
                        <Button variant="contained"
                                color="primary"
                                href={`/pdf/report/${this.props.match.params.structureId}/${this.props.match.params.fromTime}/${this.props.match.params.toTime}`}
                                target="_blank"
                                style={{
                                    backgroundColor: "#ff005c",
                                    fontFamily: "Montserrat, sans-serif",
                                    fontWeight: "bolder",
                                }}
                                startIcon={<PictureAsPdfIcon/>}>
                            PDF
                        </Button>
                    </div>
                    <div className="report-content">
                        {this.state.blocks.map(block => {
                            return <div className="page">
                                {this.renderBlock(block)}
                            </div>
                        })}
                    </div>
                </div>
            </PagesWrapper>
        )
    }
}