import React from "react"
import { Button, FormGroup } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorCameras = ({
                                    handleUpdateGear,
                                    ownedCameras,
                                    ownedCables,
                                    ownedExt,
                                    ownedFastener,
                                    ownedHdd,
                                    ownedPc,
                                    ownedBox,
                                    cameraPrice,
                                    cablePrice,
                                    extPrice,
                                    fastenerPrice,
                                    hddPrice,
                                    pcPrice,
                                    boxPrice,
                                    ownedSwitch,
                                    switchPrice,
                                    handleOwnedCamerasChange,
                                    handleOwnedCablesChange,
                                    handleOwnedExtChange,
                                    handleOwnedFastenerChange,
                                    handleOwnedHddChange,
                                    handleOwnedPcChange,
                                    handleOwnedBoxChange,
                                    handleCameraPriceChange,
                                    handleCablePriceChange,
                                    handleExtPriceChange,
                                    handleFastenerPriceChange,
                                    handleHddPriceChange,
                                    handlePcPriceChange,
                                    handleBoxPriceChange,
                                    handleOwnedSwitchChange,
                                    handleSwitchPriceChange,
                                  }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-cameras calculator-form-group">
        <FormGroup>
          <TextField label="Количество имеющихся камер наблюдения" value={ownedCameras}
                     onChange={handleOwnedCamerasChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 камеры" value={cameraPrice} onChange={handleCameraPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество имеющихся кабелей, необходимых для одной камеры" value={ownedCables}
                     onChange={handleOwnedCablesChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 кабеля" value={cablePrice} onChange={handleCablePriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество имеющихся удлинителей" value={ownedExt} onChange={handleOwnedExtChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 удлинителя" value={extPrice} onChange={handleExtPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество имеющихся крепежей для камер" value={ownedFastener}
                     onChange={handleOwnedFastenerChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 крепежа" value={fastenerPrice} onChange={handleFastenerPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество имеющихся жестких дисков" value={ownedHdd}
                     onChange={handleOwnedHddChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 диска" value={hddPrice} onChange={handleHddPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество имеющихся переносных ПК" value={ownedPc}
                     onChange={handleOwnedPcChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 ПК" value={pcPrice} onChange={handlePcPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество имеющихся антивандальных коробов" value={ownedBox}
                     onChange={handleOwnedBoxChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 короба" value={boxPrice} onChange={handleBoxPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <TextField label="Количество свитчей" value={ownedSwitch}
                     onChange={handleOwnedSwitchChange} InputLabelProps={{ shrink: true }}/>
          <TextField label="Стоимость 1 свитча" value={switchPrice} onChange={handleSwitchPriceChange}
                     InputLabelProps={{ shrink: true }}/>
          <Button variant="contained" color="primary" onClick={handleUpdateGear}>Обновить параметры</Button>
        </FormGroup>
      </div>
    </CalculatorPaperWrapper>
  )
}