import React, {Component} from "react";
import {LandingContainer} from "../landing-container/landing-container";
import Button from "@material-ui/core/Button";
import {NewsCard} from "../landing-news/news-card";
import labraHr from "../img/LABRA_HR.png";
import labraAi from "../img/LABRA_Ai.png"
import labraTracker from "../img/labra_tracker.png";
import ciblock from "../img/CiBlock_logo.png";
import steelunicorn from "../img/SteelUnicorn_logo.png";
import evan from "../img/EVAN_logo_square.png";
import calculator from "../img/Calc_1.png";
import "./../landing-news/landing-news.css";

export class Products extends Component {
    render() {
        return (
            <LandingContainer>
                <div id="news-container" className="landing-section">
                    <div id="news-content" className="landing-section-content">
                        <div className="news-content-cards" id="products-cards-top">
                            <NewsCard
                                picture={labraHr}
                                header={<h3>Labra HR</h3>}
                                text={
                                    <p>
                                        Оптимизация HR процессов и производительности труда с помощью искусственного
                                        интеллекта и автоматизации аналитики (AI). Экономьте 15% на оптимизации трудовых
                                        ресурсов и увеличивайте эффективность работы кадровой службы на 75%!
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='/#/hr'
                            />
                            <NewsCard
                                picture={labraAi}
                                header={<h3>Labra AI</h3>}
                                text={
                                    <p>
                                        Революционная автоматическая аналитика ручного труда в live online режиме с
                                        мгновенными срезами производительности! Сервис работает по стримингу видео и с
                                        возможностью мгновенного получения отчета о текущей ситуации.
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='/#/ai'
                            />
                            <NewsCard
                                picture={calculator}
                                header={<h3>Калькулятор повышения производительности труда</h3>}
                                text={
                                    <p>
                                        Калькулятор позволяет рассчитать потенциальное повышение производительности
                                        труда и необходимое количество замеров для нормирования труда и повышения
                                        эффективности работы
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='/#/calculator'
                            />
                            <NewsCard
                                picture={evan}
                                header={<h3>EVAN</h3>}
                                text={
                                    <p>
                                        EVAN - ваш надежный партнер для анализа и улучшения мероприятий, выставок и
                                        музеев! С его помощью вы получите доступ к уникальным метрикам, включая тепловую
                                        карту посещений помещений, сможете точно определить, сколько посетителей пришло
                                        и выявить наиболее популярные маршруты посетителей.
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='/#/evan'
                            />
                        </div>
                    </div>
                </div>
                <div id="news-container" className="landing-section">
                    <div id="news-content" className="landing-section-content">
                        <div className="news-content-cards" id="products-cards-top">
                            <NewsCard
                                picture={labraTracker}
                                header={<h3>Labra Tracker</h3>}
                                text={
                                    <p>
                                        Labra Tracker - интуитивный инструмент для успешного управления проектами.
                                        Удобен в использовании, экономя до 80% вашего времени. Все задачи и бэклог на
                                        одном экране для максимальной эффективности. Попробуйте прямо сейчас и увидите
                                        разницу!
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='https://tracker.labrahub.ru'
                            />
                            <NewsCard
                                picture={steelunicorn}
                                header={<h3>SteelUnicorn</h3>}
                                text={
                                    <p>
                                        SteelUnicorn - ультрасовременный инструмент для автоматизации проектирования
                                        опалубки в AutoCAD. Плагин работает на 30% быстрее даже на крупных чертежах с
                                        множеством слоев и объектов. Возможность быстро адаптировать разрезы и
                                        маркировку при изменении чертежа.
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='https://www.steelunicorn.ru'
                            />
                            <NewsCard
                                picture={ciblock}
                                header={<h3>CiBlock</h3>}
                                text={
                                    <p>
                                        CiBlock - инструмент для максимальной эффективности в проектировании
                                        микрорайонов. Включает добавление границ участка, планирование дорожной сети,
                                        расчет плотности, выгрузку чертежей в AutoCAD и искусственную оптимизацию для
                                        сокращения затрат. Начните упрощать проектирование сейчас с CiBlock!
                                    </p>
                                }
                                hrefText={'Перейти'}
                                href='https://www.labrahub.ru/#/ciblock/map'
                            />
                        </div>
                    </div>
                </div>
            </LandingContainer>
        )
    };
}