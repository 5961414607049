import { StructuresActionTypes, StructuresState } from "./types"
import { Reducer } from "redux"

const initialState: StructuresState = {
  structures: [],
  active: undefined,
}

export const structuresReducer: Reducer<StructuresState, StructuresActionTypes> = (
  state = initialState,
  action: StructuresActionTypes,
) => {
  switch (action.type) {
    case "SET_STRUCTURES":
      return {
        ...state,
        structures: action.structures,
      }
    case "CHANGE_ACTIVE_STRUCTURE":
      return {
        ...state,
        active: action.active,
      }
    default:
      return state
  }
}