import React, {useEffect, useState} from "react";
import {PagesWrapper} from "../utils/pages-wrapper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./registration.css"
import axios from "axios";
import {useDispatch} from "react-redux";
import {UserLoginAction} from "../../store/login/actions";
import {useHistory} from "react-router";

export const Registration: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [login, setLogin] = React.useState('')
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordRepeat, setPasswordRepeat] = React.useState('')

    useEffect(() => {
        document.getElementById("standard-password-input-repeat")!
            .addEventListener("keyup", function (event) {
                event.preventDefault()
                if (event.key === "Enter") {
                    document.getElementById("enter-button")!.click()
                }
            })
    }, [])

    const onRegister = () => {
        if (login == '' || password == '' || firstName == '' || lastName == '') {
            alert('Заполните все поля')
            return
        }
        if (passwordRepeat != password) {
            alert('Пароли не совпадают')
            return
        }

        axios.get(`/api/v2/register?login=${login}&password=${password}&first_name=${firstName}&last_name=${lastName}`).then(res => {
            alert(res.data)
        }).then(() => {
            axios.get(`/api/v1/whoami`).then(res => {
                if (!res.data.error) {
                    dispatch(UserLoginAction(res.data.first_name, res.data.last_name))
                    history.push(`/projects`)
                }
            })
        })
    }

    return (
        <PagesWrapper>
            <div className="registration">
                <div className="registration-form">
                    <div className="registration-input-group">
                        <TextField
                            id="standard-dense"
                            label="Логин"
                            fullWidth
                            onChange={(e) => {
                                setLogin(e.target.value)
                            }}
                        />
                        <TextField
                            id="standard-dense"
                            label="Имя"
                            fullWidth
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                        />
                        <TextField
                            id="standard-dense"
                            label="Фамилия"
                            fullWidth
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                        />
                        <TextField
                            id="standard-password-input"
                            label="Пароль"
                            type="password"
                            fullWidth
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                        <TextField
                            id="standard-password-input-repeat"
                            label="Повторите пароль"
                            type="password"
                            fullWidth
                            onChange={(e) => {
                                setPasswordRepeat(e.target.value)
                            }}
                        />
                    </div>
                    <Button
                        id="enter-button"
                        style={{
                            backgroundColor: "#ff005c",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "bolder",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={onRegister}
                    >
                        Зарегистрироваться
                    </Button>
                </div>
            </div>
        </PagesWrapper>
    )
}