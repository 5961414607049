import React, {Component} from 'react';
import './markup-content.css';
import Table from "react-bootstrap/Table";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import Pagination from "react-bootstrap/Pagination";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {changeMarkupPage, changeMarkupPattern} from "../../store/etc/actions/markup-actions";
import XlsReport from "../xls-report";

class MarkupContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pages: 1
        };
    }

    pageSize = 30;

    getTasks = (offset, current_page, filename_pattern) => {
        const structureId = this.props.queryParams.structureId;
        axios.get(`/api/v1/get_tasks?structure_id=${structureId}&option=${this.props.show}&offset=${offset}&limit=${this.pageSize}&filename_pattern=${filename_pattern}`).then(res => {
            const data = res.data.elements;
            const pages = Math.ceil(res.data.total / this.pageSize);
            this.setState(
                {
                    data: data,
                    pages: pages
                });
            this.props.changeMarkupsPageAction(current_page);
        })
    };

    changePage = (page) => {
        this.getTasks((page - 1) * this.pageSize, page, this.props.markup.searchPattern);
        this.props.changeMarkupsPageAction(page);
    };

    componentDidMount() {
        this.getTasks((this.props.markup.currentPage - 1) * this.pageSize, this.props.markup.currentPage, this.props.markup.searchPattern);
    }

    deleteTask = (task) => {
        axios.delete(`/api/v1/task/${task}`).then(() => {
            this.getTasks((this.props.markup.currentPage - 1) * this.pageSize, this.props.markup.currentPage, this.props.markup.searchPattern)
        })
    };

    getTable() {
        return (this.state.data.map((task, index) => {
            const icon = task.status.finished ?
                <i className="material-icons">done</i> : null;
            return (
                <tr key={index}>
                    <td><NavLink to={`/video/${task.video.id}`}>{task.video.filename}</NavLink></td>
                    <td>{icon}</td>
                    <td><ProgressBar now={task.status.completion_percentage}
                                    label={`${task.status.completion_percentage}%`}/></td>
                    <td>{new Date(task.status.happened_at).toLocaleString()} by {task.author ? `${task.author.first_name} ${task.author.last_name}` : '?'}</td>
                    <td>{ task.video.comment }</td>
                    <td><Button variant="danger" size="sm"><i
                        className="material-icons" onClick={() => {
                        this.deleteTask(task.video.id)
                    }}>clear</i></Button></td>
                </tr>)
        }))
    }

    searchInput = (input) => {
        if (typeof input === "string") {
            this.props.changeMarkupPatternAction(input);
        } else {
            this.props.changeMarkupPatternAction(input.target.value);
        }
    };

    search = () => {
        this.getTasks(0, 1, this.props.markup.searchPattern);
    };

    render() {
        let active = this.props.markup.currentPage;
        let items = [];
        for (let number = 1; number <= this.state.pages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={() => {
                    this.changePage(number)
                }}>
                    {number}
                </Pagination.Item>,
            );
        }

        return (
            <div className="markup-content">
                <div className="markup-search">
                    <div>
                        <Form inline>
                            <FormControl type="text" placeholder="Поиск" className="mr-sm-2"
                                         onChange={this.searchInput}/>
                            <Button variant="outline-success" onClick={this.search}>Поиск</Button>
                        </Form>
                    </div>
                    <div>
                        <XlsReport/>
                    </div>
                </div>
                <div className="markup-table">

                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Имя файла</th>
                            <th>Обработано</th>
                            <th>Текущий прогресс</th>
                            <th>Последнее изменение разметки</th>
                            <th>Комментарий</th>
                            <th>Удалить</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.getTable()}
                        </tbody>
                    </Table>

                </div>
                <div className="pagination">
                    <Pagination>{items}</Pagination>
                </div>
            </div>);
    };
}

const mapStateToProps = store => { //todo obsolete => remove
    return {
        markup: store.markup
    }
};

const mapDispatchToProps = dispatch => ({
    changeMarkupsPageAction: currentPage => dispatch(changeMarkupPage(currentPage)),
    changeMarkupPatternAction: searchPattern => dispatch(changeMarkupPattern(searchPattern))
});

export default connect(mapStateToProps, mapDispatchToProps)(MarkupContent);