import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import DatePicker from "react-datepicker"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import React from "react"
import { formatDate } from "../utils/utils"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { StructuresState } from "../../store/projects/structures/types"
import { StructureDataState, TimeSelect } from "../../store/projects/structure-data/types"
import { ChangeTimeAction, SelectEmployeeAction } from "../../store/projects/structure-data/actions"
import { InputWrapper } from "../utils/datetime-wrapper"

interface syncData {
  filename: string
  video_storage_key: string
}

interface RootState {
  structures: StructuresState
  structureData: StructureDataState
}


export const ProjectDataButtons: React.FC = () => {
  const activeId = useSelector((state: RootState) => state.structures.active)
  const currentEmployee = useSelector((state: RootState) => state.structureData.selectedEmployee)
  const employees = useSelector((state: RootState) => state.structureData.employees)
  const from = useSelector((state: RootState) => state.structureData.fromTime)
  const to = useSelector((state: RootState) => state.structureData.toTime)
  const reportDates = useSelector((state: RootState) => state.structureData.reportDates)
  const dispatch = useDispatch()

  console.log(currentEmployee)

  const syncVideos = () => {
    const structureId = activeId
    if (!structureId) {
      alert("Надо выбрать какую-нибудь структурную единицу!")
      return
    }
    axios.get(`/api/v1/sync_videos?structure_id=${structureId}`).then(res => {
      const newVideos = res.data.new_videos.map((v: syncData) => v.filename)
      const queuedVideos = res.data.queued_processing_videos.map((v: syncData) => v.video_storage_key)
      alert(`Новые видео: ${newVideos} 
            Поставили на обработку, ждите появления в списке видосов: ${queuedVideos}`)
    })
  }

  return (
    <div className="projects-data-buttons">
      <div className="projects-data-buttons-top">
        <div className="projects-data-buttons-button">
          <Button variant="contained"
                  color="primary"
                  href={`/#/markup/${activeId}`}
          >
            Разметка
          </Button>
        </div>
        <div className="projects-data-buttons-button">
          <Button variant="contained"
                  color="primary"
                  onClick={syncVideos}
          >
            Синхронизировать
          </Button>
        </div>
      </div>
      <div className="projects-data-buttons-mid">
        <div className="projects-data-buttons-button">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentEmployee}
            onChange={(event) => {
              const id = event.target.value as number
              console.log(event.target.value)
              dispatch(SelectEmployeeAction(id))
            }}
            style={{ minWidth: 290 }}
          >
            {
              employees.map(employee => <MenuItem value={employee.id}>{employee.name}</MenuItem>,
              )
            }
          </Select>
        </div>
        <div className="projects-data-buttons-button">
          <DatePicker
            selected={from}
            dateFormat="dd-MM-yyyy HH:mm"
            onChange={from => {
              if (from) {
                dispatch(ChangeTimeAction(TimeSelect.from, from))
              }
            }}
            customInput={<InputWrapper/>}
            locale="ru"
            showTimeSelect
            timeIntervals={60}
            timeCaption="Время"
            timeFormat="HH:mm"
            includeDates={reportDates}
          />
        </div>
        <div className="projects-data-buttons-button">
          <DatePicker
            selected={to}
            dateFormat="dd-MM-yyyy HH:mm"
            onChange={to => {
              if (to) {
                dispatch(ChangeTimeAction(TimeSelect.to, to))
              }
            }} customInput={<InputWrapper/>}
            locale="ru"
            showTimeSelect
            timeIntervals={60}
            timeCaption="Время"
            timeFormat="HH:mm"
            includeDates={reportDates}
          />
        </div>
      </div>
      <div className="projects-data-buttons-bot">
        <div className="projects-data-buttons-button">
          <Button variant="contained"
                  color="primary"
                  href={`/#/edit/${activeId}/${formatDate(from)}/${formatDate(to)}`}
          >
            Редактировать
          </Button>
        </div>
        <div className="projects-data-buttons-button">
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
              href={`/#/statement/${activeId}/${currentEmployee}/${formatDate(from)}/${formatDate(to)}`}
            >
              Старый
            </Button>
            <Button
              href={`/#/report/${activeId}/${formatDate(from)}/${formatDate(to)}`}
            >
              Новый
            </Button>
            <Button
              href={`/#/interactive/${activeId}/${formatDate(from)}/${formatDate(to)}`}
            >
              Интерактивный
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  )
}