import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {LandingContainer} from "../landing-container/landing-container";
import NewWord from "../landing-content/new-word";
import Button from "@material-ui/core/Button";
import pizza from '../img/HR_Labra..png'
import pasta from '../img/LABRA_HR.png'


export class Hr extends Component {
    render() {
        return (
            <LandingContainer titleText={"Бесплатный демо-доступ к автоматизации HR процессов"} titleStyle={{'fontSize': 'xxx-large'}}>
                <div className="landing-section">
                    <div className="landing-section-content">
                        <div>
                            <p>Это сервис, предоставляющий срочную лицензию, который осуществляет автоматизированную
                                аналитику и оценку производительности труда сотрудников при помощи инструментов
                                компьютерного зрения, который позволяет снизить стоимость оптимизации трудовых ресурсов
                                на <b>15%</b>, а эффективность работы кадров компании увеличить на <b>75%</b>.</p>
                        </div>
                        <h4>ПРИНЦИП РАБОТЫ <img src={pasta} style={{width: 130, marginBottom: 13}}/></h4>
                        <div style={{textAlign: 'center', marginBottom: 30}}>
                            <img className="news-card-picture"
                                 src={pizza}
                                 alt="LABRA"
                            />
                        </div>
                        <ol>
                            <li>Захват изображений с камер наблюдения и первичная разметка операций</li>
                            <li>Работа машинного обучения по анализу поступившего видео</li>
                            <li>Создание фотографии рабочего дня по видео, автоматический расчет аналитики.</li>
                            <li>Формирование итогового отчета с выявленными ресурсами для повышения безопасности,
                                содержательности и качества использования трудовых ресурсов.
                            </li>
                            <li>Улучшение использования и планирования трудовых ресурсов компании, увеличение отдачи от
                                сотрудников
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="landing-section">
                    <div className="landing-section-content">
                        <h4>КАКИЕ ПРОБЛЕМЫ РЕШАЕТ <img src={pasta} style={{width: 130, marginBottom: 13}}/></h4>
                        <ul>
                            <li>Недостаточная производительность труда;</li>
                            <li>Использование трудовых ресурсов (персонала и времени) не на максимум эффективности;</li>
                            <li>Недостаточная эффективность работы персонала;</li>
                            <li>Невозможность расчета точной необходимой численности персонала для выполнения конкретных
                                задач;
                            </li>
                            <li>Проблемы мотивации персонала;</li>
                            <li>Негибкость и устаревание системы оплаты труда;</li>
                            <li>Проблемы организации трудовых процессов;</li>
                            <li>Повышенные издержки производственного процесса;</li>
                            <li>Повышенные издержки на персонал;</li>
                            <li> Низкая «отдача» от персонала, отсутствие инновационных предложений;</li>
                            <li>Проблемы инфраструктуры взаимодействия внутри компании</li>
                            <li>Проблемы лояльности сотрудников.</li>
                        </ul>
                    </div>
                </div>
            </LandingContainer>
        )
    };
}

export default withRouter(Hr)