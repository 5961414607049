import React, {Component} from "react"
import {withRouter} from "react-router-dom"
import {LandingContainer} from "../landing-container/landing-container";
import Feedback from "../landing-content/feedback";
import evanLogo from "../img/EVAN_logo_square.png";
import independentCameras1 from "../img/evan/Independent cameras_1.png";
import independentCameras2 from "../img/evan/Independent cameras_2.png";
import dependentCameras1 from "../img/evan/Dependent cameras_1.png";
import dependentCameras2 from "../img/evan/Dependent cameras_2.png";
import './evan.css';


export class Evan extends Component {
    render() {
        return (
            <LandingContainer titleText={"Проведем демонстрацию на вашей локации и предоставим пример данных для анализа"} titleStyle={{'fontSize': 'xx-large'}}>
                <div className="landing-section">
                    <div id="form-content" className="landing-section-content">
                        <div>
                            <img src={evanLogo} style={{width: 400}}/>
                            <p style={{ fontSize: 'xxx-large', fontWeight: 'bold' }}>EVAN.</p>
                            <p style={{ fontSize: 'x-large', fontWeight: 'bold' }}>Event analysis</p>
                            <p style={{ fontWeight: 'bold' }}>Event-аналитика (аналитика мероприятий)</p>
                        </div>
                        <div>
                            <h4 style={{ fontWeight: 'bold', marginTop: 100, marginBottom: 30 }}>
                                Какие метрики может помочь снять и проанализировать EVAN на Вашем мероприятии, выставке,
                                музее?
                            </h4>
                            <ul>
                                <li>Тепловая карта посещений помещений</li>
                                <li>Путь каждого уникального посетителя</li>
                                <li>Метрики по стендам</li>
                                <li>Метрики по времени у стендов и времени в целом</li>
                                <li>Сколько посетителей прошло</li>
                                <li>Общая статистика (сколько посетителей в минуту)</li>
                                <li>Наиболее/наименее популярный стенд</li>
                                <li>Наиболее популярный маршрут</li>
                                <li>Безопасные зоны вокруг объектов – охранительная функция и т.д.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="landing-section">
                    <div className="landing-section-content">
                        <div className="cameras-section">
                            <p>Независимые камеры</p>
                            <img style={{ maxWidth: '70%' }} src={independentCameras2}/>
                            <img style={{ maxWidth: '70%' }} src={independentCameras1}/>
                        </div>
                    </div>
                </div>
                <div className="landing-section">
                    <div className="landing-section-content">
                        <div className="cameras-section">
                            <p>Зависимые камеры</p>
                            <img src={dependentCameras2}/>
                            <img src={dependentCameras1}/>
                        </div>
                    </div>
                </div>
            </LandingContainer>
        )
    };
}

export default withRouter(Evan)