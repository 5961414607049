export interface UserState {
    loggedIn: boolean
    firstName?: string
    lastName?: string
}

export const USER_LOGIN = "USER_LOGIN"
export const USER_EXIT = "USER_EXIT"

interface UserLoginAction {
    type: typeof USER_LOGIN
    firstName: string
    lastName: string
}

interface UserExitAction {
    type: typeof USER_EXIT
}

export type LoginActionTypes = UserLoginAction | UserExitAction