import React, {Component} from 'react';
import './new-frame.css';
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

export default class NewFrame extends Component {

    state = {
        action: false,
        label: null,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
    };

    onClick = () => {
        function getRect() {
            return canvas.getBoundingClientRect();
        }

        const canvas = this.props.canvas;
        const ctx = canvas.getContext("2d");
        const video = this.props.video;
        let last_mousex = 0;
        let last_mousey = 0;
        let mousex = 0;
        let mousey = 0;
        let mousedown = false;
        let rectX = 0;
        let rectY = 0;
        let rectWidth = 0;
        let rectHeight = 0;


        const canvasMouseDown = (event) => {
            last_mousex = parseInt(event.clientX - getRect().left);
            last_mousey = parseInt(event.clientY - getRect().top);
            mousedown = true;
        };

        canvas.addEventListener('mousedown', canvasMouseDown);

        const canvasMouseUp = (e) => {

            this.setState({x1: rectX});
            this.setState({y1: rectY});
            this.setState({x2: rectX + rectWidth});
            this.setState({y2: rectY + rectHeight});
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            mousedown = false;
            canvas.removeEventListener('mousedown', canvasMouseDown);
            canvas.removeEventListener('mouseup', canvasMouseUp);
            canvas.removeEventListener('mousemove', canvasMouseMove);
            this.props.onNewFrame(this.state.label, this.state.action, this.state.x1, this.state.y1, this.state.x2, this.state.y2);
            setTimeout(() => {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            }, 100);
        };

        canvas.addEventListener('mouseup', canvasMouseUp);

        const canvasMouseMove = (e) => {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            mousex = parseInt(e.clientX - getRect().left);
            mousey = parseInt(e.clientY - getRect().top);
            if (mousedown) {
                ctx.beginPath();
                rectX = last_mousex;
                rectY = last_mousey;
                rectWidth = mousex - last_mousex;
                rectHeight = mousey - last_mousey;
                ctx.rect(rectX, rectY, rectWidth, rectHeight);
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 1;
                ctx.stroke();
            }
        };

        canvas.addEventListener('mousemove', canvasMouseMove);
    };
    toggleCheckboxChange = () => {
        this.setState(({action}) => ({
            action: !action
        }));
    };
    handleInput = (event) => {
        this.setState({label: event.target.value});
    };

    render() {
        return (
            <div className="new-frame">
                <div className="selector">
                    <h6>Выберите области отслеживания</h6>
                </div>
                <div className="field-container">
                    <div className="input-field">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Наличие движения в
                                    области</InputGroup.Text>
                                <InputGroup.Checkbox onChange={this.toggleCheckboxChange}/>
                            </InputGroup.Prepend>
                            <FormControl aria-label="Text input with checkbox" placeholder="Название области"
                                         onChange={this.handleInput}/>
                        </InputGroup>
                    </div>
                    <div className="frames">
                        <Button variant="danger" onClick={this.onClick}>+</Button>
                    </div>
                </div>
            </div>
        );
    };
}