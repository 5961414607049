import React, {Component} from "react";
import axios from "axios";
import './model.css';
import { PagesWrapper } from "../utils/pages-wrapper"

export default class Model extends Component {
    constructor(props) {
        super(props)
        this.state = {
            model: null,
            videos: [],
            selectedVideoIndex: 0,
            startSecond: null,
            endSecond: null
        }
    }

    componentDidMount() {
        const modelId = this.props.match.params.modelId
        axios.get(`/api/v1/model/${modelId}`).then(res => {
            const model = res.data
            this.setState({ model }, this.updateVideos)
        })
    }

    updateVideos() {
        const cameraId = this.state.model?.meta.camera_id || null;
        if (cameraId == null) {
            return
        }
        axios.get(`/api/v1/videos?camera_id=${cameraId}`).then(res => {
            this.setState({ videos: res.data })
        })
    }

    render() {
        return (
            <PagesWrapper>
                <p>{this.state.model?.meta.name}</p>
                <textarea rows="12" value={this.state.model?.meta.report}/>
            </PagesWrapper>
        )
    }
}