import React from "react"
import { FormGroup } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorPublicInput = ({
                                        employeesNum,
                                        handleEmployeesNumChange,
                                        shiftsPerDay,
                                        handleShiftsPerDayChange,
                                        businessDayNum,
                                        handleBusinessDayNumChange,
                                        hoursPerShift,
                                        HandleHoursPerShiftChange,
                                        averageSalary,
                                        handleAverageSalaryChange,
                                        insuranceAndPension,
                                        handleInsuranceAndPensionChange,
                                        visible,
                                        handleVisibleChange,
                                        unseen,
                                        handleUnseenChange
                                      }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-public-input calculator-form-group">
        <FormGroup>
          <TextField label="Количество сотрудников"
                     value={employeesNum}
                     onChange={handleEmployeesNumChange}
                     type="number"
          />
          <TextField label="Количество смен в рабочем дне"
                     value={shiftsPerDay}
                     onChange={handleShiftsPerDayChange}
                     type="number"
          />
          <TextField label="Количество рабочих дней для замеров"
                     value={businessDayNum}
                     onChange={handleBusinessDayNumChange}
                     type="number"
          />
          <TextField label="Количество часов в рабочей смене"
                     value={hoursPerShift}
                     onChange={HandleHoursPerShiftChange}
                     type="number"
          />
          <TextField label="Средний уровень заработной платы сотрудников подразделения (без учета взносов) в ₽"
                     value={averageSalary}
                     onChange={handleAverageSalaryChange}
                     type="number"
          />
          <FormControlLabel
            control={<Checkbox checked={insuranceAndPension} onChange={handleInsuranceAndPensionChange}/>}
            label="Учитывать страховые и пенсионные взносы за сотрудника в расчетах"
          />
          <FormControlLabel
            control={<Checkbox checked={visible} onChange={handleVisibleChange}/>}
            label="Все рабочие места видны на камерах наблюдения, а операции, которые выполняют сотрудники хорошо различимы"
          />
          {visible ? null : <TextField label="Количество рабочих мест, которые не видны на камерах наблюдения"
                                       checked={unseen}
                                       onChange={handleUnseenChange}
          />}
        </FormGroup>
      </div>
    </CalculatorPaperWrapper>
  )
}