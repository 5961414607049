import React from "react"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Paper from "@material-ui/core/Paper"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorRequiredMeasurements = ({
                                                 currentProductionType,
                                                 productionTypes,
                                                 averageTime,
                                                 handleProductionTypeChange,
                                                 handleAverageTimeChange,
                                                 stabilityCoef,
                                                 requiredMeasurements,
                                               }) => {
  console.log(currentProductionType)
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-required-measurements">
        <FormGroup>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Тип производства</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentProductionType}
              onChange={handleProductionTypeChange}
            >
              {productionTypes.map((type, index) => {
                return (<MenuItem key={index} value={index}>{type}</MenuItem>)
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox checked={averageTime} onChange={handleAverageTimeChange}/>}
            label="Средняя длительность операций < 10 сек."
          />
        </FormGroup>
        <p>Коэффициент устойчивости хронометражного ряда - {stabilityCoef}</p>
        <p>Необходимое количество замеров по каждой операции - {requiredMeasurements}</p>
      </div>
    </CalculatorPaperWrapper>
  )
}