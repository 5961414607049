import { Operation, OperationsActionTypes, OperationState } from "./types"
import { Reducer } from "redux"

const initialState: OperationState = {
  operationTypes: {},
  operations: [],
  toDelete: [],
}

export const operationsReducer: Reducer<OperationState, OperationsActionTypes> = (state = initialState, action: OperationsActionTypes) => {
  switch (action.type) {
    case "LOAD_OPERATIONS":
      return {
        operations: action.operations,
        operationTypes: action.operationTypes,
        toDelete: [],
      }
    case "ADD_OPERATION":
      const newOperation: Operation = {
        id: undefined,
        name: "",
        referencePoint: "",
        type: Object.keys(state.operationTypes).length > 0 ? Object.keys(state.operationTypes)[0] : undefined,
      }
      const updatedOperations = JSON.parse(JSON.stringify(state.operations))
      updatedOperations.push(newOperation)
      return {
        ...state,
        operations: updatedOperations,
      }
    case "REMOVE_OPERATION":
      const updatedOperationsRemove = JSON.parse(JSON.stringify(state.operations))
      const toDeleteUpdated = state.toDelete
      const idToDelete = state.operations[action.index].id
      if (idToDelete !== undefined) {
        toDeleteUpdated.push(idToDelete)
      }
      updatedOperationsRemove.splice(action.index, 1)
      return {
        ...state,
        operations: updatedOperationsRemove,
        toDelete: toDeleteUpdated,
      }
    case "EDIT_OPERATION":
      const operation: Operation = {
        id: state.operations[action.index].id,
        name: action.newName !== undefined ? action.newName : state.operations[action.index].name,
        referencePoint: action.newReferencePoint !== undefined ? action.newReferencePoint : state.operations[action.index].referencePoint,
        type: action.newType ? action.newType : state.operations[action.index].type,
      }
      const updatedOperationsUpdate = JSON.parse(JSON.stringify(state.operations))
      updatedOperationsUpdate[action.index] = operation
      return {
        ...state,
        operations: updatedOperationsUpdate,
      }
    default:
      return state
  }
}