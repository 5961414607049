export type Shift = {
    timeStart: string
    timeFinish: string
    name: string
    gaps: number
    index: number
}

export interface ShiftState {
    shifts: Shift[]
}

export const LOAD_SHIFTS_DATA = "LOAD_SHIFTS_DATA"
export const REMOVE_SHIFT_DATA = "REMOVE_SHIFT_DATA"
export const EDIT_SHIFTS_DATA = "EDIT_SHIFTS_DATA"
export const ADD_SHIFT_DATA = "ADD_SHIFT_DATA"

interface LoadShiftsAction {
    type: typeof LOAD_SHIFTS_DATA
    shifts: Shift[]
}

interface RemoveShiftAction {
    type: typeof REMOVE_SHIFT_DATA
    index: number
}

interface EditShiftsAction {
    type: typeof EDIT_SHIFTS_DATA,
    id: number,
    newTimeStart?: string
    newTimeFinish?: string
    newName?: string
    newGaps?: number
}

interface AddShiftAction {
    type: typeof ADD_SHIFT_DATA
}

export type ProjectShiftsActionTypes = LoadShiftsAction | RemoveShiftAction | EditShiftsAction | AddShiftAction;