import { CHANGE_ACTIVE_STRUCTURE, SET_STRUCTURES, Structure, StructuresActionTypes } from "./types"

export function changeActiveStructureAction(active?: number): StructuresActionTypes  {
  return {
    type: CHANGE_ACTIVE_STRUCTURE,
    active: active
  }
}

export function setStructuresAction(structures: Structure[]):StructuresActionTypes {
  return {
    type: SET_STRUCTURES,
    structures: structures
  }
}