import React from "react";

export const Metrics = () => {
    return <>
        <div id="metrics" className="landing-section">
            <div id="metrics-content" className="landing-section-content">
                <h3>Метрики</h3>
                <div className="metrics-cards" id="metrics-cards-top">
                    <div>
                        <h4>Оценка персонала и обучение персонала
                        </h4>
                        <p>
                            Оценка соответствия сотрудников занимаемым должностям после работы с нами увеличилась с
                            менее
                            чем <b> 75% </b> до
                            более чем <b> 85%</b>. Оценка результатов обучения показала рост с <b> 60% </b> до <b>85%</b> в среднем, а
                            показатели
                            качества
                            продукции возросли.
                        </p>
                    </div>
                    <div>
                        <h4>Нормирование труда
                        </h4>
                        <p>
                            Время выполнения операций сократилось на <b>15%</b>. Доля простоев снизилась в среднем с <b> 25% </b> до
                            <b> 10%</b>.
                            Доля
                            непродуктивно используемого времени снизилась с <b> 40% </b> до <b> 15%</b>. Доход от одного сотрудника
                            возрос в
                            среднем
                            на
                            <b> 20%</b>. <b> ROI </b> от сотрудничества с нами использования наших разработок вырос в среднем на <b> 30% </b> в
                            данной
                            категории.
                        </p>
                    </div>
                    <div>
                        <h4>Организация HR бизнес-процессов
                        </h4>
                        <p>
                            Уровень текучести персонала снизился на <b> 8%</b>. Оценка безопасности рабочих мест по СОУТ,
                            составление
                            специальной оценки рабочих мест и разработка мероприятий для повышения безопасности на
                            рабочих
                            местах.
                        </p>
                    </div>
                    <div>
                        <h4>Разработка и внедрение системы оплаты труда
                        </h4>
                        <p>
                            Удовлетворенность текущей системой оплаты труда возросла с менее чем у <b> 60% </b> до более чем у
                            <b> 80% </b>
                            персонала.
                            Понимание потребностей своего персонала возросло с <b> 40% </b> до <b> 90%</b>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="metrics" className="landing-section">
            <div id="metrics-content" className="landing-section-content">
                <h3>Метрики</h3>
                <div className="metrics-cards" id="metrics-cards-bot">
                    <div>
                        <h4>Разработка и проведение мероприятий по повышению эффективности работы сотрудников
                        </h4>
                        <p>
                            Точность длительности и выполнения норм труда возросла до <b> 95% </b> с менее чем <b> 85%</b>. Нами были
                            разработаны
                            планы
                            ротации сотрудников, точность допустимой границы загруженности возросла до <b> 98% </b> вместо
                            предшествующих
                            <b> 80%</b>.
                            Точность планирования работ увеличилась в среднем до <b> 95%</b>. <b> ROI </b> показал результаты на <b> 20% </b>
                            выше,
                            чем
                            планировалось.
                        </p>
                    </div>
                    <div>
                        <h4>Мотивация персонала и создание корпоративной культуры
                        </h4>
                        <p>
                            Уровень лояльности персонала вырос с менее чем <b> 50% </b> до более чем <b> 80%</b>. Оценка
                            удовлетворенности
                            персонала
                            возросла со среднего значения <b> 55% </b> до более чем <b> 85%</b>. <b> ROI </b> в компаниях, в которых были внедрены
                            наши
                            разработки
                            по мотивации персонала вырос на <b> 27%</b>. Более <b> 80% </b> сотрудников разделяют корпоративные ценности
                            и
                            для более
                            чем
                            <b> 70% </b> сотрудников ясны приоритеты компании после разработанных и проведенных нами мероприятий.
                        </p>
                    </div>
                    <div>
                        <h4>Повышение качества и результативности работы
                        </h4>
                        <p>
                            Доля ошибок в работе снизилась с <b> 20% </b> до менее чем <b> 8%</b>. Доля бракованной продукции была
                            снижена благодаря
                            нашим разработкам с <b> 25% </b> до менее чем <b> 10%</b>.
                        </p>
                    </div>
                    <div>
                        <h4>HR консультирование
                        </h4>
                        <p>
                            Наши специалисты разработают решение любой сложности под Ваши нужды и внедрят в
                            использование!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
}