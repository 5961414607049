import App from "./components/app/app"
import * as ReactDOM from "react-dom"
import configureStore, { AppState } from "./store/store"
import { Store } from "redux"
import { Provider } from "react-redux"
import React from "react"
import {HashRouter} from "react-router-dom"

interface Props {
  store: Store<AppState>
}

const Root: React.FC<Props> = props => {
  return (
    <Provider store={props.store}>
      <HashRouter>
        <App/>
      </HashRouter>
    </Provider>
  )
}

const store = configureStore()

ReactDOM.render(<Root store={store}/>, document.getElementById(
  "root",
) as HTMLElement)