import React from "react"
import { CalculatorPaperWrapper } from "./calculator-paper-wrapper"

export const CalculatorPrivateOutput = ({
                                          manualMeasurementsHours,
                                          boxWorkPrice,
                                          accountantPrice,
                                          overhead,
                                          transportPrice,
                                          marketingPrice,
                                          futurePrice,
                                          taxes,
                                        }) => {
  return (
    <CalculatorPaperWrapper>
      <div className="calculator-public-output calculator-form-group">
        <p>Количество часов ручной разметки - {manualMeasurementsHours}</p>
        <p>Стоимость нашей “коробочной работы” - {boxWorkPrice}</p>
        <p>Бухгалтерские расходы - {accountantPrice}</p>
        <p>Накладные расходы - {overhead}</p>
        <p>Транспортные расходы - {transportPrice}</p>
        <p>Расходы на продажу и рекламу - {marketingPrice}</p>
        <p>На развитие - {futurePrice}</p>
        <p>Налоги, которые закладываются в сумму контракта - {taxes}</p>
      </div>
    </CalculatorPaperWrapper>
  )
}