import ReactEcharts from "echarts-for-react"
import React from "react"
import { colorize } from "./chart-util"

export const BlackPie = ({ data }) => {
  const segments = colorize(data.segments)
  console.log(segments)

  const getOption = () => {
    return {
      backgroundColor: "#2c343c",
      title: {
        text: data.result,
        textStyle: {
          fontSize: 18,
          color: "rgb(255, 255, 255)"
        },
        left: "10%",
        top: "80%",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      series: [
        {
          name: data.label,
          type: "pie",
          radius: "55%",
          center: ["50%", "50%"],
          data: segments,
          roseType: "radius",
          // label: {
          //   color: "rgba(255, 255, 255, 0.3)",
          // },
          // labelLine: {
          //   lineStyle: {
          //     color: "rgba(255, 255, 255, 0.3)",
          //   },
          //   smooth: 0.2,
          //   length: 10,
          //   length2: 20,
          // },
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: function(idx) {
            return Math.random() * 200
          },
        },
      ],
    }
  }

  return <div className="report-chart bar-chart">
    <ReactEcharts
      option={getOption()}
      style={{ height: "800px" }}
      // opts={{ renderer: "svg" }}
    />
  </div>
}