export function changeMarkupPage(page) {
    return {
        type: 'CHANGE_MARKUP_PAGE',
        payload: page
    }
}

export function changeMarkupPattern(pattern) {
    return {
        type: 'CHANGE_MARKUP_PATTERN',
        payload: pattern
    }
}