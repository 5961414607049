import React, {Component} from 'react';
import './login-button.css';
import Nav from "react-bootstrap/Nav";
import {withRouter} from 'react-router-dom';

class LoginButton extends Component {

    onClick = () => {
        this.props.history.push("/login");
    };

    render() {
        return (
            <Nav.Link style={{
                color: "#ff005c",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bolder",
            }} onClick={this.onClick}>
                Войти
            </Nav.Link>
        )
    };
}

export default withRouter(LoginButton);