import {applyMiddleware, combineReducers, createStore, Store} from "redux"
import {pageReducer} from "./etc/reducers/page"
import {markupReducer} from "./etc/reducers/markup"
import {reportGeneratorReducer} from "./etc/reducers/report-generator"
import {structuresReducer} from "./projects/structures/reducers"
import {StructuresState} from "./projects/structures/types"
import {operationsReducer} from "./projects/operations/reducers"
import {OperationState} from "./projects/operations/types"
import {StructureDataState} from "./projects/structure-data/types"
import {structureDataReducer} from "./projects/structure-data/reducers"
import {projectShiftsReducer} from "./projects/project-shifts/reducers";
import {ShiftState} from "./projects/project-shifts/types";
import { ParametersState } from "./projects/project-parameters/types"
import { projectParametersReducer } from "./projects/project-parameters/reducers"
import {UserState} from "./login/types";
import {loginReducer} from "./login/reducers";

export interface AppState {
    page: any,
    markup: any,
    reportGenerator: any,
    structures: StructuresState,
    operations: OperationState
    structureData: StructureDataState,
    projectShifts: ShiftState,
    projectParameters: ParametersState
    login: UserState
    // selector: SelectorState
}

const rootReducer = combineReducers<AppState>({
    page: pageReducer,
    markup: markupReducer,
    reportGenerator: reportGeneratorReducer,
    structures: structuresReducer,
    operations: operationsReducer,
    structureData: structureDataReducer,
    projectShifts: projectShiftsReducer,
    projectParameters: projectParametersReducer,
    login: loginReducer
    // selector: selectorReducer
})

export default function configureStore(): Store<AppState, any> {
    return createStore(rootReducer);
}