import React, {Component} from "react"
import "./login.css"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import axios from "axios"
import {PagesWrapper} from "../utils/pages-wrapper"
import {connect} from "react-redux";
import {UserExitAction, UserLoginAction} from "../../store/login/actions";

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: null,
            password: null,
        }
    }

    componentDidMount() {
        document.getElementById("standard-password-input")
            .addEventListener("keyup", function (event) {
                event.preventDefault()
                if (event.key === "Enter") {
                    document.getElementById("enter-button").click()
                }
            })
    }

    onLogin = () => {
        axios.get(`/api/v1/login?email=${this.state.email}&password=${this.state.password}`).then(res => {
            alert(res.data)
            window.location.href = '/#/projects'
        })
    }

    emailInput = (input) => {
        this.setState({email: input.target.value})
    }

    passwordInput = (input) => {
        this.setState({password: input.target.value})
    }

    render() {
        return (
            <PagesWrapper>
                <div className="login">
                    <div className="auth-form">
                        <div className="login-group">
                            <TextField
                                id="standard-dense"
                                label="Имя пользователя"
                                fullWidth
                                onChange={this.emailInput}
                            />
                            <TextField
                                id="standard-password-input"
                                label="Пароль"
                                type="password"
                                fullWidth
                                onChange={this.passwordInput}
                            />
                        </div>
                        <div className="login-buttons">
                            <Button
                            id="enter-button"
                            style={{
                                backgroundColor: "#ff005c",
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: "bolder",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={this.onLogin}
                        >
                            Войти
                        </Button>
                        <Button
                            id="enter-button"
                            style={{
                                backgroundColor: "#ff005c",
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: "bolder",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => this.props.history.push("/registration")}
                        >
                            Регистрация
                        </Button>
                        </div>
                    </div>
                </div>
            </PagesWrapper>
        )
    };
}

const mapStateToProps = store => {
    return {
        user: store.login
    }
};

const mapDispatchToProps = dispatch => ({
    userLogin: (firstName, lastName) => dispatch(UserLoginAction(firstName, lastName)),
    userExit: () => dispatch(UserExitAction())
});


export default connect(mapStateToProps, mapDispatchToProps)(Login)