import React, { useEffect } from "react"
import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import axios from "axios"
import { ProjectsNavigationItems } from "./projects-navigation-items"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { Structure, StructuresState } from "../../store/projects/structures/types"
import { setStructuresAction } from "../../store/projects/structures/actions"


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))

interface RootState {
  structures: StructuresState
}

export const ProjectsNavigation: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get(`/api/v2/get_all_structures`)
      const structures: Structure[] = result.data.map((basicStructure: any) => {
        return {
          id: basicStructure.id,
          name: basicStructure.name,
          parentId: basicStructure.parent_id ? basicStructure.parent_id : undefined,
        }
      })
      dispatch(setStructuresAction(structures))
    }

    fetchData()
  }, [dispatch])

  return (
    <div className="project-navigation">
      <List component="nav"
            subheader={
              <ListSubheader component="div">
                Проекты
              </ListSubheader>
            }
            className={classes.root}>
        <ProjectsNavigationItems/>
      </List>
    </div>
  )
}