import React, {Component} from 'react';
import './feedback.css';
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import personal from "../../../files/Политика_в_отношении_обработки_персональных.pdf";
import user from "../../../files/Пользовательское Соглашение.pdf";
import consent from "../../../files/Согласие_на_обработку_персональных.pdf";
import Spinner from 'react-bootstrap/Spinner';

const recaptchaRef = React.createRef();

export default class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recaptchaToken: this.props.recaptchaToken,
            name: this.props.name,
            email: this.props.email,
            phoneNumber: this.props.phoneNumber,
            result: this.props.result,
            loading: this.props.loading,
        }
    }

    onSubmit = () => {
        const name = document.getElementById('name').value.trim();
        const email = document.getElementById('email').value.trim();
        const company = document.getElementById('company').value.trim();
        const phoneNumber = document.getElementById('phone_number').value.trim();
        const comment = document.getElementById('comment').value.trim();
        const feedback = {};
        const {recaptchaToken} = this.state;
        if (!recaptchaToken) {
            alert('А Вы точно не робот?');
        }
        feedback.recaptcha_token = recaptchaToken
        if (!name) {
            alert('Как к Вам можно обращаться?');
            return
        }
        feedback.name = name;
        if (!email) {
            alert('Какая у Вас почта?');
            return
        }
        feedback.email = email;
        if (company) {
            feedback.company = company
        }
        if (phoneNumber) {
            feedback.phone_number = phoneNumber
        }
        if (comment) {
            feedback.comment = comment
        }
        this.setState({ loading: true })

        axios.post(`/api/v1/feedback`, feedback).then(res => {
            this.setState({result: 'Спасибо! Наш специалист свяжется с Вами в ближайшее время'});
        }).catch((error) => {
            this.setState({result: 'Сейчас на нашем сервере проблемы. Для связи с нами напишите, пожалуйста, на welcome@labrahub.com'});
        });
    };

    submitEnabled = () => {
        const {recaptchaToken, name, email} = this.state;
        return recaptchaToken && name && email
    };

    render() {
        const disabled = !this.submitEnabled();

        return (
            <div className="feedback-container">
                {this.state.result ? <h3 id="result" className="result" align="center">{this.state.result}</h3> :
                    <div className="feedback-fields">
                        {/*<h1>Отправьте заявку</h1>*/}
                        {/*<p>Как к вам обращаться*</p><input type="text" required id="name"/>*/}
                        {/*<p>email*</p><input type="email" required id="email"/>*/}
                        {/*<p>Компания</p><input type="text" id="company"/>*/}
                        {/*<p>Телефон</p><input type="text" id="phone_number"/>*/}
                        {/*<p>Комментарий/вопрос</p><textarea id="comment"/>*/}
                        {/*<button onClick={this.onSubmit}>Отправить!</button>*/}
                        <form noValidate autoComplete="off">
                            <TextField
                                required id="name"
                                type="text"
                                label="Как к Вам можно обращаться?"
                                fullWidth="true"
                                onChange={e => this.setState({name: e.target.value})}
                            />
                            <TextField
                                required id="email"
                                type="email"
                                label="Email"
                                fullWidth="true"
                                onChange={e => this.setState({email: e.target.value})}
                            />
                            <TextField
                                id="company"
                                type="text"
                                label="Компания"
                                fullWidth="true"
                            />
                            <div className="phone-number">
                                <PhoneInput
                                    id="phone_number"
                                    placeholder="Телефон (необязательно)"
                                    country="RU"
                                    showCountrySelect={false}
                                    value={this.state.phoneNumber}
                                    onChange={phoneNumber => this.setState({phoneNumber})}
                                    fullWidth="true"
                                />
                            </div>
                            <TextField
                                id="comment"
                                type="text"
                                label="Комментарий/вопрос"
                                fullWidth="true"
                                multiline
                                rows="2"
                            />
                            <div className="recaptcha">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LfjnsYUAAAAAPyyWJtrTLWx7yUErtXh1XuqlaLa"
                                    onChange={recaptchaToken => this.setState({recaptchaToken})}
                                />
                                <p>Нажимая Отправить вы соглашаетесь с <a href={user}>пользовательским
                                    соглашением</a> и <a href={personal}>политикой
                                    обработки персональных данных</a>, а также выражаете <a href={consent}>согласие на
                                    их обработку</a></p>
                            </div>
                            <div className="feedback-button">
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    {this.state.loading ? <Spinner animation={"border"} /> : <Button
                                        style={{
                                            backgroundColor: disabled ? "#85929e" : "#ff005c",
                                            fontFamily: "Montserrat, sans-serif",
                                            fontWeight: "bolder"
                                        }}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onSubmit}
                                        disabled={!this.submitEnabled()}
                                    >
                                        Отправить
                                    </Button>}
                                </div>
                            </div>
                        </form>
                    </div>}
            </div>
        );
    };
}