import Button from "@material-ui/core/Button";
import React from "react";

export const removeButton = (f) =>
    <Button
        variant="contained"
        color="secondary"
        onClick={f}
    >
        <i className="material-icons">
            clear
        </i>
    </Button>
;