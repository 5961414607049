import {LoginActionTypes, USER_EXIT, USER_LOGIN} from "./types";

export function UserLoginAction(firstName: string, lastName: string): LoginActionTypes {
    return {
        type: USER_LOGIN,
        firstName: firstName,
        lastName: lastName
    }
}

export function UserExitAction(): LoginActionTypes {
    return {
        type: USER_EXIT,
    }
}
